import { IPublicClientApplication } from '@azure/msal-browser'
import { AxiosInstance } from 'axios'

import { TFilterItem } from 'modules/filters-panel/filters-panel'

import { interceptRequest } from '../utils/authUtils'

export const sortABC = (a: string, b: string) => a.toLowerCase().localeCompare(b.toLowerCase())

export const sortFiltersABC = (filterA: TFilterItem, filterB: TFilterItem) =>
  filterA.name.toLowerCase().localeCompare(filterB.name.toLowerCase())

export const formatTime = (ms: string) => new Date(Number(ms)).toISOString().slice(11, -5)

export const registerInterceptors = (
  axiosInstance: AxiosInstance,
  msalInstance: IPublicClientApplication,
  defaultBearer?: string
) => {
  if (defaultBearer) {
    axiosInstance.interceptors.request.use((request) => {
      request.headers.Authorization = `Bearer ${defaultBearer}`
      return request
    })
  } else {
    axiosInstance.interceptors.request.use((request) => {
      return interceptRequest(msalInstance, request)
    })
  }

  axiosInstance.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      return Promise.reject(error)
    }
  )
}
