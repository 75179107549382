import * as React from 'react'

import IconAdd from '@mui/icons-material/Add'
import IconVersion from '@mui/icons-material/CallSplit'
import IconClose from '@mui/icons-material/Close'
import IconInfo from '@mui/icons-material/Info'
import IconKeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import IconKeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import IconKeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import IconMoreHoriz from '@mui/icons-material/MoreHoriz'
import IconMoreVert from '@mui/icons-material/MoreVert'
import IconRefresh from '@mui/icons-material/Refresh'
import Remove from '@mui/icons-material/Remove'
import IconVisibility from '@mui/icons-material/Visibility'
import IconZoomOutMap from '@mui/icons-material/ZoomOutMapSharp'
import Button from '@mui/material/Button'
import classNames from 'classnames'

import LoaderCUI from 'components/loader/Loader'

import burger from './assets/burger.svg'
import expandIcon from './assets/expand.svg'
import filters from './assets/filters.svg'
import shrinkIcon from './assets/shrink.svg'
import 'assets/css/variables.scss'
import { default as s } from './ButtonIcon.scss'

export enum EButtonIconVariant {
  TEXT = 'text',
  CONTAINED = 'contained',
}

export enum EButtonIcon {
  ADD = 'Add',
  REMOVE = 'Remove',
  REFRESH = 'Refresh',
  CLOSE = 'Close',
  KEYBOARD_BACK_SPACE = 'Keyboard back space',
  KEYBOARD_ARROW_RIGHT = 'Keyboard arrow right',
  KEYBOARD_ARROW_DOWN = 'Keyboard arrow down',
  KEYBOARD_ARROW_UP = 'Keyboard arrow up',
  ZOOM_OUT_MAP = 'Zoom out map',
  EXPAND = 'expand',
  SHRINK = 'shrink',
  BURGER = 'burger',
  FILTERS = 'filters',
  MORE_HORIZ = 'More horizontal',
  MORE_VERT = 'More vertical',
  VERSION = 'Version',
  INFO = 'Info',
  VISIBILITY = 'Visibility',
}

export interface IButtonIconProps {
  loading?: boolean
  /** Button success state */
  success?: boolean
  /** Button disabled state */
  disabled?: boolean
  /** Button variant */
  variant?: EButtonIconVariant
  /** Button onClick handler */
  onClick?: React.MouseEventHandler<HTMLElement>
  /** Icon that appears in the button */
  btnIcon?: EButtonIcon
  /** Label used mainly for accessibility */
  btnLabel: string
  'data-testid'?: string
  className?: string
}

const ButtonIcon = ({
  loading,
  success,
  disabled,
  variant,
  onClick,
  btnIcon,
  btnLabel,
  className,
  ...restProps
}: IButtonIconProps) => {
  const renderButton = () => {
    const displayIcon = (icon?: EButtonIcon) => {
      switch (icon) {
        case EButtonIcon.ADD:
          return <IconAdd />
        case EButtonIcon.REMOVE:
          return <Remove />
        case EButtonIcon.REFRESH:
          return <IconRefresh />
        case EButtonIcon.CLOSE:
          return <IconClose />
        case EButtonIcon.KEYBOARD_BACK_SPACE:
          return <KeyboardBackspaceIcon />
        case EButtonIcon.KEYBOARD_ARROW_RIGHT:
          return <IconKeyboardArrowRight />
        case EButtonIcon.KEYBOARD_ARROW_DOWN:
          return <IconKeyboardArrowDown />
        case EButtonIcon.KEYBOARD_ARROW_UP:
          return <IconKeyboardArrowUp />
        case EButtonIcon.ZOOM_OUT_MAP:
          return <IconZoomOutMap />
        case EButtonIcon.MORE_HORIZ:
          return <IconMoreHoriz />
        case EButtonIcon.MORE_VERT:
          return <IconMoreVert />
        case EButtonIcon.VERSION:
          return <IconVersion />
        case EButtonIcon.INFO:
          return <IconInfo />
        case EButtonIcon.VISIBILITY:
          return <IconVisibility />
        case EButtonIcon.EXPAND:
          return <img src={expandIcon} alt="" />
        case EButtonIcon.SHRINK:
          return <img src={shrinkIcon} alt="" />
        case EButtonIcon.BURGER:
          return <img src={burger} alt="" />
        case EButtonIcon.FILTERS:
          return <img src={filters} alt="" />
        default:
          return ''
      }
    }

    const buttonDisabled = () => disabled || loading
    const testId = restProps['data-testid'] || 'iconButton'

    return (
      <div className={s.buttonContainer}>
        <Button
          variant={variant}
          className={classNames(
            {
              [variant]: variant,
              [s.button]: true,
              [s.success]: success,
            },
            className
          )}
          data-qa="iconButton"
          disabled={buttonDisabled()}
          onClick={onClick}
          role="button"
          data-testid={testId}
          aria-label={btnLabel}
        >
          {displayIcon(btnIcon)}
        </Button>
        {loading && (
          <div className={s.loader}>
            <LoaderCUI size={20} thickness={6} />
          </div>
        )}
      </div>
    )
  }
  return renderButton()
}

export default ButtonIcon
