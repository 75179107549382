import { DetailPanelActionTypes } from './actionTypes'

export declare function FResetDetail(): IResetDetail
export declare function FToggleEditMode(toggle: boolean): IToggleEditMode
export declare function FSetCurrentTab(tab: number): ISetCurrentTab

export interface IResetDetail {
  type: DetailPanelActionTypes.RESET_DETAIL
}

export interface IToggleEditMode {
  type: DetailPanelActionTypes.TOGGLE_DETAIL_EDIT
  toggle: boolean
}

export interface ISetCurrentTab {
  type: DetailPanelActionTypes.SET_CURRENT_TAB
  tab: number
}

export interface IFetchDetailActions {
  resetDetail: typeof FResetDetail
  toggleEditMode: typeof FToggleEditMode
  setCurrentTab: typeof FSetCurrentTab
}

export type TDetailPanelAvailableActions = IResetDetail | IToggleEditMode | ISetCurrentTab

export const resetDetail = (): IResetDetail => ({
  type: DetailPanelActionTypes.RESET_DETAIL,
})

export const toggleEditMode = (toggle: boolean): IToggleEditMode => ({
  type: DetailPanelActionTypes.TOGGLE_DETAIL_EDIT,
  toggle,
})

export const setCurrentTab = (tab: number): ISetCurrentTab => ({
  type: DetailPanelActionTypes.SET_CURRENT_TAB,
  tab,
})

export const DetailPanelActions: IFetchDetailActions = {
  resetDetail,
  toggleEditMode,
  setCurrentTab,
}
