import * as React from 'react'
import { Provider as ReduxProvider } from 'react-redux'

import { InteractionStatus, IPublicClientApplication } from '@azure/msal-browser'
import { useMsal } from '@azure/msal-react'
import { CssBaseline } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { PersistGate } from 'redux-persist/integration/react'

import App from './App'
import LoaderLinear from './components/loaderlinear/LoaderLinear'
import { initialStoreState } from './redux/reducers'
import configureStore from './redux/store'
import { registerLogin, startLogin } from './utils/authUtils'

const AuthManager = () => {
  const { instance, inProgress } = useMsal()
  if (inProgress === InteractionStatus.None) {
    if (registerLogin(instance)) {
      return displayApp()
    } else {
      return displayLogin(instance)
    }
  } else {
    return displaySpinner()
  }
}

const displayApp = () => {
  const { store, persistor } = configureStore(initialStoreState)

  const theme = createTheme({
    typography: {
      fontFamily: 'hind, sans-serif;',
      body1: {
        fontSize: 14,
      },
    },
    palette: {
      primary: {
        main: '#000000',
      },
      secondary: {
        main: '#ffffff',
      },
    },
  })
  return (
    <ReduxProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </PersistGate>
    </ReduxProvider>
  )
}

const displaySpinner = () => {
  return (
    <>
      <CssBaseline />
      <LoaderLinear />
    </>
  )
}

const displayLogin = (msalInstance: IPublicClientApplication) => {
  startLogin(msalInstance)
  // startLogin makes a redirect, this will never be called, just add the return statement to avoid a code smell
  return <></>
}

export default AuthManager
