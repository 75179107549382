import { initReactI18next } from 'react-i18next'

import i18n from 'i18next'

import Combined from './combined.json'

// the translations
const resources = Combined
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'fr',
    interpolation: {
      escapeValue: false, // react already safes from xss
      format: (value: string | string[], format: string): string => {
        if (format === 'csv') {
          return (value as string[]).join(', ')
        }
        return value as string
      },
    },
  })
export default i18n
