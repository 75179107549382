export enum ContentActionTypes {
  FETCH_CONTENT = '@Content FETCH_CONTENT',
  FETCH_CONTENT_SUCCESS = '@Content FETCH_CONTENT_SUCCESS',
  FETCH_CONTENT_FAILURE = '@Content FETCH_CONTENT_FAILURE',
  FETCH_DROPPED_CONTENT_PREVIEW = '@Content FETCH_DROPPED_PREVIEW_CONTENT',
  FETCH_DROPPED_CONTENT_PREVIEW_SUCCESS = '@Content FETCH_DROPPED_CONTENT_PREVIEW_SUCCESS',
  FETCH_DROPPED_CONTENT_PREVIEW_FAILURE = '@Content FETCH_DROPPED_CONTENT_PREVIEW_FAILURE',
  FETCH_CONTENT_PREVIEW = '@Content FETCH_CONTENT_PREVIEW',
  FETCH_CONTENT_PREVIEW_SUCCESS = '@Content FETCH_CONTENT_PREVIEW_SUCCESS',
  FETCH_CONTENT_PREVIEW_FAILURE = '@Content FETCH_CONTENT_PREVIEW_FAILURE',
  FETCH_CONTENT_RELATES_TO = '@Content FETCH_CONTENT_RELATES_TO',
  FETCH_CONTENT_RELATES_TO_SUCCESS = '@Content FETCH_CONTENT_RELATES_TO_SUCCESS',
  FETCH_CONTENT_RELATES_TO_FAILURE = '@Content FETCH_CONTENT_RELATES_TO_FAILURE',
  FETCH_CONTENT_SHOW_HIERARCHY = '@Content FETCH_CONTENT_SHOW_HIERARCHY',
  FETCH_CONTENT_SHOW_HIERARCHY_SUCCESS = '@Content FETCH_CONTENT_SHOW_HIERARCHY_SUCCESS',
  FETCH_CONTENT_SHOW_HIERARCHY_FAILURE = '@Content FETCH_CONTENT_SHOW_HIERARCHY_FAILURE',
  FETCH_CONTENT_ASSOCIATIONS = '@Content FETCH_CONTENT_ASSOCIATIONS',
  FETCH_CONTENT_ASSOCIATIONS_SUCCESS = '@Content FETCH_CONTENT_ASSOCIATIONS_SUCCESS',
  FETCH_CONTENT_ASSOCIATIONS_FAILURE = '@Content FETCH_CONTENT_ASSOCIATIONS_FAILURE',
  UPDATE_CONTENT = '@Content UPDATE_CONTENT',
  UPDATE_CONTENT_SUCCESS = '@Content UPDATE_CONTENT_SUCCESS',
  UPDATE_CONTENT_FAILURE = '@Content UPDATE_CONTENT_FAILURE',
  UPDATE_CONTENT_WITH_IMAGE = '@Content UPDATE_CONTENT_WITH_IMAGE',
  UPDATE_CONTENT_WITH_IMAGES = '@Content UPDATE_CONTENT_WITH_IMAGES',
  UPDATE_CONTENT_ASSOCIATIONS = '@Content UPDATE_CONTENT_ASSOCIATIONS',
  UPDATE_CONTENT_ASSOCIATIONS_SUCCESS = '@Content UPDATE_CONTENT_ASSOCIATIONS_SUCCESS',
  UPDATE_CONTENT_ASSOCIATIONS_FAILURE = '@Content UPDATE_CONTENT_ASSOCIATIONS_FAILURE',
  CREATE_CONTENT = '@Content CREATE_CONTENT',
  CREATE_CONTENT_SUCCESS = '@Content CREATE_CONTENT_SUCCESS',
  CREATE_CONTENT_FAILURE = '@Content CREATE_CONTENT_FAILURE',
  DELETE_CONTENT = '@Content DELETE_CONTENT',
  DELETE_CONTENT_SUCCESS = '@Content DELETE_CONTENT_SUCCESS',
  DELETE_CONTENT_FAILURE = '@Content DELETE_CONTENT_FAILURE',
  DELETE_CONTENT_ASSOCIATIONS = '@Content DELETE_CONTENT_ASSOCIATIONS',
  DELETE_CONTENT_ASSOCIATIONS_SUCCESS = '@Content DELETE_CONTENT_ASSOCIATIONS_SUCCESS',
  DELETE_CONTENT_ASSOCIATIONS_FAILURE = '@Content DELETE_CONTENT_ASSOCIATIONS_FAILURE',
  CLEAR_ASSOCIATION = '@Content CLEAR_ASSOCIATION',
  CLEAR_CONTENT_PREVIEW = '@Content CLEAR_CONTENT_PREVIEW',
  CREATE_FEED_STANDARD = '@Content FEED_STANDARD_FEED',
  CREATE_FEED_STANDARD_SUCCESS = '@Content FEED_STANDARD_FEED_SUCCESS',
  CREATE_FEED_STANDARD_FAILURE = '@Content FEED_STANDARD_FEED_FAILURE',
  UPLOAD_IMAGE = '@Content UPLOAD_IMAGE',
  UPLOAD_IMAGE_SUCCESS = '@Content UPLOAD_IMAGE_SUCCESS',
}
