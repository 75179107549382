import { all, call, fork, put, takeLatest, select } from 'redux-saga/effects'

import { fetchTaxonomyApi } from 'api/Taxonomy'
import { ICards } from 'models/card/GenericCard'
import { ICategory, ITaxonomy } from 'models/taxonomy/Taxonomy'
import { TPfuFilterItem } from 'modules/filters-panel/filters-panel'
import { IShow, ITag } from 'modules/search/sub-modules/search-service/search-service.def'
import {
  searchServiceParamsGetter,
  searchServiceTenantGetter,
} from 'modules/search/sub-modules/search-service/search-service.selector'
import {
  searchOrchestrationTags,
  searchOrchestration,
  searchOrchestrationVideoParentShows,
  searchOrchestrationAudioShows,
} from 'modules/search/sub-modules/search-service/search-service.service'
import { apiCallErrorHandling } from 'services/apicallsagaservice/apiCallErrorHandling'

import {
  fetchTagsSuccess,
  fetchTagsFailure,
  searchPfuCatalogSuccess,
  searchPfuCatalogFailure,
  fetchShowsSuccess,
  fetchShowsFailure,
  fetchAudioShowsSuccess,
  fetchAudioShowsFailure,
  fetchInterestsSuccess,
  fetchInterestsFailure,
} from './search-pfu.action'
import { SearchPfuActionTypes } from './search-pfu.action.types'
import { TFetchShowsAction, TFetchAudioShowsAction } from './search-pfu.d'

export interface ISearchPfuSelect {
  query: string
  filters: TPfuFilterItem[]
}

export function* fetchTagsAsync() {
  try {
    const tags: ITag[] = yield call(searchOrchestrationTags)
    yield put(fetchTagsSuccess(tags))
  } catch (error) {
    yield put(fetchTagsFailure(apiCallErrorHandling(error)))
  }
}

export function* fetchInterestsAsync() {
  try {
    const data: ITaxonomy[] = yield call(fetchTaxonomyApi)
    const interests: ICategory[] = data[0].categories
    yield put(fetchInterestsSuccess(interests))
  } catch (error) {
    yield put(fetchInterestsFailure(apiCallErrorHandling(error)))
  }
}

export function* fetchShowAsync({ payload }: TFetchShowsAction) {
  try {
    const tenant: string = yield select(searchServiceTenantGetter)
    const shows: IShow[] = yield call(searchOrchestrationVideoParentShows, payload.search, payload.owner ?? tenant)
    yield put(fetchShowsSuccess(shows))
  } catch (error) {
    yield put(fetchShowsFailure(apiCallErrorHandling(error)))
  }
}

export function* fetchAudioShowAsync({ payload }: TFetchAudioShowsAction) {
  try {
    const tenant: string = yield select(searchServiceTenantGetter)
    const audioShows: IShow[] = yield call(searchOrchestrationAudioShows, payload, tenant)
    yield put(fetchAudioShowsSuccess(audioShows))
  } catch (error) {
    yield put(fetchAudioShowsFailure(apiCallErrorHandling(error)))
  }
}

export function* searchPfuCatalogAsync() {
  const searchServiceParams: URLSearchParams = yield select(searchServiceParamsGetter)

  const tenant: string = yield select(searchServiceTenantGetter)
  try {
    const catalog: ICards = yield call(searchOrchestration, searchServiceParams, tenant)
    yield put(searchPfuCatalogSuccess(catalog))
  } catch (error) {
    yield put(searchPfuCatalogFailure(apiCallErrorHandling(error)))
  }
}

function* watchFetchTagsAsync() {
  yield takeLatest(SearchPfuActionTypes.FETCH_TAGS, fetchTagsAsync)
}

function* watchFetchInterestsAsync() {
  yield takeLatest(SearchPfuActionTypes.FETCH_INTERESTS, fetchInterestsAsync)
}

function* watchFetchShowAsync() {
  yield takeLatest(SearchPfuActionTypes.FETCH_VIDEO_SHOWS, fetchShowAsync)
}

function* watchFetchAudioShowAsync() {
  yield takeLatest(SearchPfuActionTypes.FETCH_AUDIO_SHOWS, fetchAudioShowAsync)
}

function* watchSearchPfuCatalogAsync() {
  yield takeLatest(SearchPfuActionTypes.SEARCH_PFU_CATALOG, searchPfuCatalogAsync)
}

export function* rootSearchPfuSaga() {
  yield all([
    fork(watchFetchTagsAsync),
    fork(watchFetchInterestsAsync),
    fork(watchFetchShowAsync),
    fork(watchFetchAudioShowAsync),
    fork(watchSearchPfuCatalogAsync),
  ])
}
