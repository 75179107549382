import React from 'react'

import { RouterContext } from 'providers/RouterProvider'

export function useRouter() {
  const router = React.useContext(RouterContext)

  if (!router) {
    throw new Error('Missing RouterContext')
  }

  return router
}
