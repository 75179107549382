import ApiFlowManagerDetail from 'models/apiflowmanager/ApiFlowManagerDetail'
import IUser from 'models/user/user'

import { EUserActionTypes } from './user.action.types'
import { TFetchUserAction, TFetchUserSuccessAction, TFetchUserFailureAction } from './user.d'

export const fetchUser = (): TFetchUserAction => ({
  type: EUserActionTypes.FETCH_USER,
})

export const fetchUserSuccess = (user: IUser): TFetchUserSuccessAction => ({
  type: EUserActionTypes.FETCH_USER_SUCCESS,
  payload: user,
})

export const fetchUserFailure = (apiFlow: ApiFlowManagerDetail): TFetchUserFailureAction => ({
  type: EUserActionTypes.FETCH_USER_FAILURE,
  payload: apiFlow,
})
