export enum FiltersActionTypes {
  SET_QUERY = '@FiltersPanel SET_QUERY',
  SEARCH_CATALOG = '@FiltersPanel SEARCH_CATALOG',
  SELECT_PFU_FILTER = '@FiltersPanel SELECT_PFU_FILTER',
  UNSELECT_PFU_FILTER = '@FiltersPanel UNSELECT_PFU_FILTER',
  SELECT_MULTI_PFU_FILTERS = '@FiltersPanel SELECT_MULTI_PFU_FILTERS',
  UNSELECT_MULTI_PFU_FILTERS = '@FiltersPanel UNSELECT_MULTI_PFU_FILTERS',
  CLEAR_SELECTED_PFU_FILTERS = '@FiltersPanel CLEAR_SELECTED_PFU_FILTERS',
  ADD_PFU_TAG_FILTER = '@FiltersPanel ADD_PFU_TAG_FILTER',
  REMOVE_PFU_TAG_FILTER = '@FiltersPanel REMOVE_PFU_TAG_FILTER',
  CLEAR_PFU_TAG_FILTERS = '@FiltersPanel CLEAR_PFU_TAG_FILTERS',
  ADD_PFU_INTEREST_FILTER = '@FiltersPanel ADD_PFU_INTEREST_FILTER',
  REMOVE_PFU_INTEREST_FILTER = '@FiltersPanel REMOVE_PFU_INTEREST_FILTER',
  CLEAR_PFU_INTEREST_FILTERS = '@FiltersPanel CLEAR_PFU_INTEREST_FILTERS',
  CLEAR_QUERY_AND_PFU_FILTERS = '@FiltersPanel CLEAR_QUERY_AND_PFU_FILTERS',
  TOGGLE_EXPANDED = '@FiltersPanel TOGGLE_EXPANDED',
  SET_EXPANDED = '@FiltersPanel SET_EXPANDED',
  ADD_NEW_TAB = '@FiltersPanel ADD_NEW_TAB',
  ADD_NEW_TAB_WITH_CONTEXT = '@FiltersPanel ADD_NEW_TAB_WITH_CONTEXT',
  REMOVE_TAB = '@FiltersPanel REMOVE_TAB',
  SWITCH_TAB = '@FiltersPanel SWITCH_TAB',
}
