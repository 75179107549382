import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects'

import {
  createContentApi,
  deleteContentApi,
  deleteContentAssociationsApi,
  fetchContentApi,
  fetchContentAssociationsApi,
  fetchContentRelatesToApi,
  fetchContentShowHierarchyApi,
  updateContentApi,
  updateContentAssociationsApi,
  updateContentWithImagesApi,
  uploadImageContentApi,
} from 'api/Content'
import { createStandardFeedApi } from 'api/Feed'
import { IContentVideoPreview, IRelationsGroup, TContent } from 'models/content/content'
import { IContentImageUpdate } from 'models/detail/content/ContentUpdate'
import IShowHierarchy from 'models/detail/content/ShowHierarchy'
import { toggleCreationMode } from 'modules/create-panel-detail/createContainerActions'
import { toggleEditMode } from 'modules/detailpanel/commonDetailPanelActions'
import { clearCurrentSlot } from 'modules/request-form/request-form.actions'
import { apiCallErrorHandling } from 'services/apicallsagaservice/apiCallErrorHandling'

import { clearSelectedCards } from '../requests'

import {
  clearAssociations,
  clearUploadImageUpload,
  createContentFailure,
  createContentSuccess,
  createStandardFeedFailure,
  createStandardFeedSuccess,
  deleteContentAssociationsFailure,
  deleteContentAssociationsSuccess,
  deleteContentFailure,
  deleteContentSuccess,
  fetchContentAssociationsFailure,
  fetchContentAssociationsSuccess,
  fetchContentFailure,
  fetchContentPreviewFailure,
  fetchContentPreviewSuccess,
  fetchContentRelatesToFailure,
  fetchContentRelatesToSuccess,
  fetchContentShowHierarchyFailure,
  fetchContentShowHierarchySuccess,
  fetchContentSuccess,
  fetchDroppedContentPreviewFailure,
  fetchDroppedContentPreviewSuccess,
  updateContentAssociationsFailure,
  updateContentAssociationsSuccess,
  updateContentFailure,
  updateContentSuccess,
} from './content.action'
import { ContentActionTypes } from './content.action.types'
import {
  TCreateContentAction,
  TCreateStandardFeedAction,
  TDeleteContentAction,
  TDeleteContentAssociationsAction,
  TFetchContentAction,
  TFetchContentAssociationsAction,
  TFetchContentPreviewAction,
  TFetchContentRelatesToAction,
  TFetchContentShowHierarchyAction,
  TUpdateContentAction,
  TUpdateContentAssociationsAction,
  TUpdateContentWithImageAction,
  TUpdateContentWithImagesAction,
} from './content.d'

export function* fetchContentAsync({ payload }: TFetchContentAction) {
  try {
    const content: TContent = yield call(fetchContentApi, payload)
    yield put(fetchContentSuccess(content))
    yield put(clearAssociations())
    yield put(clearCurrentSlot())
  } catch (error) {
    yield put(fetchContentFailure(apiCallErrorHandling(error, { id: payload })))
  }
}

export function* fetchDroppedContentPreviewAsync({ payload }: TFetchContentAction) {
  try {
    const content: IContentVideoPreview = yield call(fetchContentApi, payload)
    yield put(fetchDroppedContentPreviewSuccess(content))
  } catch (error) {
    yield put(fetchDroppedContentPreviewFailure(apiCallErrorHandling(error, { id: payload })))
  }
}

export function* fetchContentPreviewAsync({ payload }: TFetchContentPreviewAction) {
  try {
    const content: TContent = yield call(fetchContentApi, payload)
    yield put(fetchContentPreviewSuccess(content))
  } catch (error) {
    yield put(fetchContentPreviewFailure(apiCallErrorHandling(error, { id: payload })))
  }
}

export function* fetchContentRelatesToAsync({ payload }: TFetchContentRelatesToAction) {
  try {
    const relationsGroups: IRelationsGroup[] = yield call(fetchContentRelatesToApi, payload.id, payload.relationTypes)
    yield put(fetchContentRelatesToSuccess(relationsGroups))
  } catch (error) {
    yield put(fetchContentRelatesToFailure(apiCallErrorHandling(error, { id: payload.id })))
  }
}

export function* fetchContentShowHierarchyAsync({ payload }: TFetchContentShowHierarchyAction) {
  try {
    const showHierarchy: IShowHierarchy = yield call(fetchContentShowHierarchyApi, payload)
    yield put(fetchContentShowHierarchySuccess(showHierarchy))
  } catch (error) {
    yield put(fetchContentShowHierarchyFailure(apiCallErrorHandling(error, { id: payload })))
  }
}

export function* fetchContentAssociationsAsync({ payload }: TFetchContentAssociationsAction) {
  try {
    const associationContents: TContent[] = yield call(fetchContentAssociationsApi, payload)
    yield put(fetchContentAssociationsSuccess(associationContents))
  } catch (error) {
    yield put(fetchContentAssociationsFailure(apiCallErrorHandling(error, { id: payload })))
  }
}

export function* updateContentAsync({ payload: { id, body } }: TUpdateContentAction) {
  try {
    const content: TContent = yield call(updateContentApi, id, body)
    yield put(updateContentSuccess(content))
    yield put(toggleEditMode(false))
    yield put(clearSelectedCards())
  } catch (error) {
    yield put(updateContentFailure(apiCallErrorHandling(error, { id })))
  }
}

export function* updateContentWithImageAsync({ payload }: TUpdateContentWithImageAction) {
  try {
    const image: IContentImageUpdate = {
      url: yield call(uploadImageContentApi, payload.image),
      fileName: payload.image?.name,
    }
    const content: TContent = yield call(updateContentApi, payload.id, payload.body, image)
    yield put(updateContentSuccess(content))
    yield put(toggleEditMode(false))
    yield put(clearSelectedCards())
  } catch (error) {
    yield put(updateContentFailure(apiCallErrorHandling(error, { id: payload.id })))
  }
}

export function* updateContentWithImagesAsync({ payload }: TUpdateContentWithImagesAction) {
  try {
    const image: IContentImageUpdate = yield call(updateContentImageAndReturnIContentImageUpdate, payload.image)

    const image16X9: IContentImageUpdate = yield call(updateContentImageAndReturnIContentImageUpdate, payload.image16X9)

    const image7X1: IContentImageUpdate = yield call(updateContentImageAndReturnIContentImageUpdate, payload.image7X1)

    const image3X1: IContentImageUpdate = yield call(updateContentImageAndReturnIContentImageUpdate, payload.image3X1)

    const image2X3: IContentImageUpdate = yield call(updateContentImageAndReturnIContentImageUpdate, payload.image2X3)

    const showCaseImage16X9: string = yield call(updateContentImageAndReturnString, payload.showCaseImage16X9)

    const showCaseImage2X3: string = yield call(updateContentImageAndReturnString, payload.showCaseImage2X3)

    const backgroundImage73By5: string = yield call(updateContentImageAndReturnString, payload.backgroundImage73By5)

    const backgroundImage16By6: string = yield call(updateContentImageAndReturnString, payload.backgroundImage16By6)

    const headerImage: string = yield call(updateContentImageAndReturnString, payload.headerImage)

    const content: TContent = yield call(
      updateContentWithImagesApi,
      payload.id,
      payload.body,
      image,
      image16X9,
      image7X1,
      image3X1,
      image2X3,
      showCaseImage16X9,
      showCaseImage2X3,
      backgroundImage73By5,
      backgroundImage16By6,
      headerImage
    )
    yield put(updateContentSuccess(content))
    yield put(toggleEditMode(false))
    yield put(clearSelectedCards())
  } catch (error) {
    yield put(updateContentFailure(apiCallErrorHandling(error, { id: payload.id })))
  }
}

export function* updateContentImageAndReturnIContentImageUpdate(fileImage?: File) {
  let image: IContentImageUpdate
  if (fileImage) {
    image = { url: yield call(uploadImageContentApi, fileImage), fileName: fileImage?.name }
  }
  return image
}

export function* updateContentImageAndReturnString(fileImage?: File) {
  let image: string
  if (fileImage) {
    image = yield call(uploadImageContentApi, fileImage)
  }
  return image
}

export function* updateContentAssociationsAsync({ payload }: TUpdateContentAssociationsAction) {
  try {
    yield call(updateContentAssociationsApi, payload)
    yield put(updateContentAssociationsSuccess())
    yield put(toggleEditMode(false))
    yield put(clearSelectedCards())
  } catch (error) {
    yield put(updateContentAssociationsFailure(apiCallErrorHandling(error)))
  }
}

export function* createContentAsync({ payload }: TCreateContentAction) {
  try {
    const content: TContent = yield call(createContentApi, { data: payload })
    yield put(createContentSuccess(content))
    yield put(toggleCreationMode(false))
    yield put(clearUploadImageUpload())
  } catch (error) {
    yield put(createContentFailure(apiCallErrorHandling(error)))
    yield put(clearUploadImageUpload())
  }
}

export function* deleteContentAsync({ payload }: TDeleteContentAction) {
  try {
    yield call(deleteContentApi, payload)
    yield put(deleteContentSuccess())
  } catch (error) {
    yield put(deleteContentFailure(apiCallErrorHandling(error, { id: payload })))
  }
}

export function* deleteContentAssociationsAsync({ payload }: TDeleteContentAssociationsAction) {
  try {
    yield call(deleteContentAssociationsApi, payload)
    yield put(deleteContentAssociationsSuccess())
    yield put(toggleEditMode(false))
    yield put(clearSelectedCards())
  } catch (error) {
    yield put(deleteContentAssociationsFailure(apiCallErrorHandling(error)))
  }
}

export function* createStandardFeedAsync({ payload }: TCreateStandardFeedAction) {
  try {
    const content: TContent = yield call(createStandardFeedApi, { data: payload })
    yield put(createStandardFeedSuccess(content))
    yield put(toggleCreationMode(false))
  } catch (error) {
    yield put(createStandardFeedFailure(apiCallErrorHandling(error)))
  }
}

function* watchCreateStandardFeedAsync() {
  yield takeLatest(ContentActionTypes.CREATE_FEED_STANDARD, createStandardFeedAsync)
}

function* watchFetchContentAsync() {
  yield takeLatest(ContentActionTypes.FETCH_CONTENT, fetchContentAsync)
}

function* watchFetchDroppedContentAsync() {
  yield takeEvery(ContentActionTypes.FETCH_DROPPED_CONTENT_PREVIEW, fetchDroppedContentPreviewAsync)
}

function* watchFetchContentPreviewAsync() {
  yield takeLatest(ContentActionTypes.FETCH_CONTENT_PREVIEW, fetchContentPreviewAsync)
}

function* watchFetchContentRelatesToAsync() {
  yield takeLatest(ContentActionTypes.FETCH_CONTENT_RELATES_TO, fetchContentRelatesToAsync)
}

function* watchFetchContentShowHierarchyAsync() {
  yield takeLatest(ContentActionTypes.FETCH_CONTENT_SHOW_HIERARCHY, fetchContentShowHierarchyAsync)
}

function* watchFetchContentAssociationsAsync() {
  yield takeLatest(ContentActionTypes.FETCH_CONTENT_ASSOCIATIONS, fetchContentAssociationsAsync)
}

function* watchUpdateContentAsync() {
  yield takeLatest(ContentActionTypes.UPDATE_CONTENT, updateContentAsync)
}

function* watchUpdateContentWithImageAsync() {
  yield takeLatest(ContentActionTypes.UPDATE_CONTENT_WITH_IMAGE, updateContentWithImageAsync)
}

function* watchUpdateContentWithImagesAsync() {
  yield takeLatest(ContentActionTypes.UPDATE_CONTENT_WITH_IMAGES, updateContentWithImagesAsync)
}

function* watchUpdateContentAssociationsAsync() {
  yield takeLatest(ContentActionTypes.UPDATE_CONTENT_ASSOCIATIONS, updateContentAssociationsAsync)
}

function* watchCreateContentAsync() {
  yield takeLatest(ContentActionTypes.CREATE_CONTENT, createContentAsync)
}

function* watchDeleteContentAsync() {
  yield takeLatest(ContentActionTypes.DELETE_CONTENT, deleteContentAsync)
}

function* watchDeleteContentAssociationsAsync() {
  yield takeLatest(ContentActionTypes.DELETE_CONTENT_ASSOCIATIONS, deleteContentAssociationsAsync)
}

export function* rootContentSaga() {
  yield all([
    fork(watchFetchContentAsync),
    fork(watchFetchDroppedContentAsync),
    fork(watchFetchContentPreviewAsync),
    fork(watchFetchContentRelatesToAsync),
    fork(watchFetchContentShowHierarchyAsync),
    fork(watchFetchContentAssociationsAsync),
    fork(watchUpdateContentAsync),
    fork(watchUpdateContentWithImageAsync),
    fork(watchUpdateContentWithImagesAsync),
    fork(watchUpdateContentAssociationsAsync),
    fork(watchCreateContentAsync),
    fork(watchDeleteContentAsync),
    fork(watchDeleteContentAssociationsAsync),
    fork(watchCreateStandardFeedAsync),
  ])
}
