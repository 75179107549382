import { all, call, fork, put, takeLatest } from 'redux-saga/effects'

import { getUserApi } from 'api/User'
import IUser from 'models/user/user'
import { apiCallErrorHandling } from 'services/apicallsagaservice/apiCallErrorHandling'

import { fetchUserSuccess, fetchUserFailure } from './user.action'
import { EUserActionTypes } from './user.action.types'

export function* fetchUserAsync() {
  try {
    const user: IUser = yield call(getUserApi)
    yield put(fetchUserSuccess(user))
  } catch (error) {
    yield put(fetchUserFailure(apiCallErrorHandling(error)))
  }
}

function* watchFetchUserAsync() {
  yield takeLatest(EUserActionTypes.FETCH_USER, fetchUserAsync)
}

export function* rootUserSaga() {
  yield all([fork(watchFetchUserAsync)])
}
