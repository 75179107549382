export enum RequestActionTypes {
  FETCH_SLOT_DETAIL = '@Request FETCH_SLOT_DETAIL',
  FETCH_SLOT_DETAIL_SUCCESS = '@Request FETCH_SLOT_DETAIL_SUCCESS',
  FETCH_SLOT_DETAIL_FAIL = '@Request FETCH_SLOT_DETAIL_FAIL',
  RESET_SLOT_CONTENT_DETAIL = '@Request RESET_SLOT_CONTENT_DETAIL',
  FETCH_SLOT_DETAIL_CANCEL = '@Request FETCH_SLOT_DETAIL_CANCEL',
  FETCH_SLOT_PREVIEW_DETAIL_SUCCESS = '@Request FETCH_SLOT_PREVIEW_DETAIL_',
  SET_CURRENT_SLOT = '@Request SET_CURRENT_SLOT',
  CLEAR_CURRENT_SLOT = '@Request CLEAR_CURRENT_SLOT',
}
