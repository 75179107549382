import ApiFlowManagerDetail from 'models/apiflowmanager/ApiFlowManagerDetail'
import { EApiFlowManagerType, EApiFlowManagerPresentationType } from 'models/apiflowmanager/Enumeration'
import { IContainer, TContainer, TBaseSlot } from 'models/container/container'
import { IContent, TContent } from 'models/content/content'
import { RequestActionTypes } from 'modules/request-form/action-types'
import { TRequestAvailableActions } from 'modules/request-form/request-form.actions'
import { ContainerActionTypes } from 'redux/actions/container'
import { IApiFlowManager } from 'types/common'

export interface IRequestState extends IApiFlowManager {
  slot: TBaseSlot
  positionIndexes: number[]
  slotDetails: (TContent | TContainer)[]
  slotPreviewDetails: (IContent | IContainer)[]
}

export const initialState: IRequestState = {
  slot: null,
  positionIndexes: [],
  slotDetails: [],
  slotPreviewDetails: [],
  apiFlowManagerDetail: new ApiFlowManagerDetail(EApiFlowManagerType.LOADER, EApiFlowManagerPresentationType.GLOBAL),
  apiFlowManagerDetails: [],
}

const clearSlotPreviewDetails = (state: IRequestState): IRequestState => ({
  ...state,
  slotPreviewDetails: [],
})

const setSlotPreviewDetails = (state: IRequestState, detail: IContent | IContainer) => ({
  ...state,
  slotPreviewDetails: [...state.slotPreviewDetails, detail],
})

const setCurrentSlot = (
  state: IRequestState = initialState,
  slot: TBaseSlot,
  positionIndexes: number[]
): IRequestState => ({
  ...state,
  slot,
  positionIndexes,
  apiFlowManagerDetail: undefined,
  apiFlowManagerDetails: [],
})

const clearCurrentSlot = (state: IRequestState = initialState): IRequestState => ({
  ...state,
  slot: null,
  positionIndexes: [],
  apiFlowManagerDetail: undefined,
  apiFlowManagerDetails: [],
})

const updateSlotDetails = (state: IRequestState, detail: TContent | TContainer): IRequestState => {
  return {
    ...state,
    slotDetails: [...state.slotDetails.filter((d) => d.id !== detail.id), detail],
  }
}

const updateSlotDetailFails = (state: IRequestState, apiFlowManagerDetail: ApiFlowManagerDetail): IRequestState => {
  return {
    ...state,
    apiFlowManagerDetails: [...state.apiFlowManagerDetails, apiFlowManagerDetail],
  }
}

export default (state = initialState, action: TRequestAvailableActions) => {
  switch (action.type) {
    case RequestActionTypes.SET_CURRENT_SLOT:
      return setCurrentSlot(state, action.slot, action.positionIndexes)
    case RequestActionTypes.CLEAR_CURRENT_SLOT:
      return clearCurrentSlot(state)
    case RequestActionTypes.FETCH_SLOT_DETAIL_SUCCESS:
      return updateSlotDetails(state, action.detail)
    case RequestActionTypes.FETCH_SLOT_PREVIEW_DETAIL_SUCCESS:
      return setSlotPreviewDetails(state, action.detail)
    case ContainerActionTypes.FETCH_CONTAINER_PREVIEW_SUCCESS:
      return clearSlotPreviewDetails(state)
    case RequestActionTypes.FETCH_SLOT_DETAIL_FAIL:
      return updateSlotDetailFails(state, action.apiFlowManagerDetail)
    default:
      return state
  }
}
