import uniqBy from 'lodash/uniqBy'

import { IListOption } from 'components/autocompleteselect/AutoCompleteSelect'
import ApiFlowManagerDetail from 'models/apiflowmanager/ApiFlowManagerDetail'
import { EApiFlowManagerType, EApiFlowManagerPresentationType } from 'models/apiflowmanager/Enumeration'
import { SelectActionTypes } from 'redux/actions/select'

import { TSelectReducerAction, ISelectState } from './select.d'

export const initialSelectState: ISelectState = {
  showsList: [],
  audioShowsList: [],
  apiFlowManagerDetail: new ApiFlowManagerDetail(EApiFlowManagerType.LOADER, EApiFlowManagerPresentationType.GLOBAL),
}

const setFetchShowsListSuccess = (state: ISelectState, payload: IListOption[]): ISelectState => {
  return {
    ...state,
    showsList: uniqBy([...state.showsList, ...payload], 'value'),
    apiFlowManagerDetail: undefined,
  }
}

const setFetchShowsListFailure = (state: ISelectState, payload: ApiFlowManagerDetail): ISelectState => ({
  ...state,
  apiFlowManagerDetail: payload,
})

const setFetchAudioShowsListSuccess = (state: ISelectState, payload: IListOption[]): ISelectState => {
  return {
    ...state,
    audioShowsList: uniqBy([...state.audioShowsList, ...payload], 'value'),
    apiFlowManagerDetail: undefined,
  }
}

export const selectReducer = (state: ISelectState = initialSelectState, action: TSelectReducerAction) => {
  switch (action.type) {
    case SelectActionTypes.FETCH_VIDEO_SHOWS_LIST_SUCCESS:
      return setFetchShowsListSuccess(state, action.payload)
    case SelectActionTypes.FETCH_VIDEO_SHOWS_LIST_FAILURE:
      return setFetchShowsListFailure(state, action.payload)
    case SelectActionTypes.FETCH_AUDIO_SHOWS_LIST_SUCCESS:
      return setFetchAudioShowsListSuccess(state, action.payload)
    case SelectActionTypes.FETCH_AUDIO_SHOWS_LIST_FAILURE:
      return setFetchShowsListFailure(state, action.payload)
    default:
      return state
  }
}
