import * as React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import Errors, { EErrors } from 'components/errors/Errors'
import ApiFlowManagerDetail from 'models/apiflowmanager/ApiFlowManagerDetail'
import { IUserState } from 'redux/reducers/user'
import i18n from 'translations/i18n'
import { IStoreState } from 'types/common'

import { default as s } from './auth.styles.scss'

const AuthPage = (): JSX.Element => {
  const routerHistory = useHistory()

  const auth = useSelector<IStoreState>((state: IStoreState) => state.user.apiFlowManagerDetail) as ApiFlowManagerDetail
  const user = useSelector<IStoreState>((state: IStoreState) => state.user) as IUserState

  if (user.lastName && user.firstName) {
    routerHistory.push(`/recherche`)
  }

  const helpDeskLink = 'https://groupemedia.atlassian.net/servicedesk/customer/portal/24/group/-1'
  const errorMsg = () => {
    switch (auth?.errorType) {
      case 403:
        return (
          <div className={s.container}>
            <Errors
              type={EErrors.FULL}
              btnLabel={i18n.t('auth:403.prefix')}
              errorMessage={i18n.t('auth:403.prefix')}
              errorMessageDetails={i18n.t('auth:403.errorMessageDetails')}
              actionHandler={() => {
                window.open(helpDeskLink)
              }}
              reloadLabel={i18n.t('auth:403.reloadLabel')}
            />
          </div>
        )
      case 401:
        return (
          <div className={s.container}>
            <Errors
              type={EErrors.FULL}
              btnLabel={i18n.t('auth:401.prefix')}
              errorMessage={i18n.t('auth:401.prefix')}
              errorMessageDetails={i18n.t('auth:401.errorMessageDetails')}
              actionHandler={() => {
                window.open(helpDeskLink)
              }}
              reloadLabel={i18n.t('auth:401.reloadLabel')}
            />
          </div>
        )
      default:
        return (
          <div className={s.container}>
            <Errors
              type={EErrors.FULL}
              btnLabel={i18n.t('auth:default.prefix')}
              errorMessage={i18n.t('auth:default.prefix')}
              errorMessageDetails={i18n.t('auth:default.errorMessageDetails')}
              actionHandler={() => {
                window.open(helpDeskLink)
              }}
              reloadLabel={i18n.t('auth:default.reloadLabel')}
            />
          </div>
        )
    }
  }
  return <div className={s.wrapper}>{errorMsg()}</div>
}

export default AuthPage
