import ApiFlowManagerDetail from 'models/apiflowmanager/ApiFlowManagerDetail'
import { EApiFlowManagerType, EApiFlowManagerPresentationType } from 'models/apiflowmanager/Enumeration'
import { ICategory } from 'models/taxonomy/Taxonomy'
import { IShow, ITag } from 'modules/search/sub-modules/search-service/search-service.def'
import { SearchPfuActionTypes } from 'redux/actions/search-pfu'

import { TFacetReducerAction, IFacetState } from './facet.d'

export const initialFacetState: IFacetState = {
  tags: [],
  interests: [],
  shows: [],
  audioShows: [],
  apiFlowManagerDetail: new ApiFlowManagerDetail(EApiFlowManagerType.LOADER, EApiFlowManagerPresentationType.GLOBAL),
}

const setFetchTagsSuccess = (state: IFacetState, payload: ITag[]): IFacetState => ({
  ...state,
  tags: payload,
  apiFlowManagerDetail: undefined,
})

const setFetchTagsFailure = (state: IFacetState, payload: ApiFlowManagerDetail): IFacetState => ({
  ...state,
  apiFlowManagerDetail: payload,
})

const setFetchInterestsSuccess = (state: IFacetState, payload: ICategory[]): IFacetState => ({
  ...state,
  interests: payload,
  apiFlowManagerDetail: undefined,
})

const setFetchInterestsFailure = (state: IFacetState, payload: ApiFlowManagerDetail): IFacetState => ({
  ...state,
  apiFlowManagerDetail: payload,
})

const setFetchShowsSuccess = (state: IFacetState, payload: IShow[]): IFacetState => ({
  ...state,
  shows: payload,
  apiFlowManagerDetail: undefined,
})

const setFetchShowsFailure = (state: IFacetState, payload: ApiFlowManagerDetail): IFacetState => ({
  ...state,
  apiFlowManagerDetail: payload,
})

const setFetchAudioShowsSuccess = (state: IFacetState, payload: IShow[]): IFacetState => ({
  ...state,
  audioShows: payload,
  apiFlowManagerDetail: undefined,
})

const setFetchAudioShowsFailure = (state: IFacetState, payload: ApiFlowManagerDetail): IFacetState => ({
  ...state,
  apiFlowManagerDetail: payload,
})

export const facetReducer = (state: IFacetState = initialFacetState, action: TFacetReducerAction) => {
  switch (action.type) {
    case SearchPfuActionTypes.FETCH_TAGS_SUCCESS:
      return setFetchTagsSuccess(state, action.payload)
    case SearchPfuActionTypes.FETCH_TAGS_FAILURE:
      return setFetchTagsFailure(state, action.payload)
    case SearchPfuActionTypes.FETCH_INTERESTS_SUCCESS:
      return setFetchInterestsSuccess(state, action.payload)
    case SearchPfuActionTypes.FETCH_INTERESTS_FAILURE:
      return setFetchInterestsFailure(state, action.payload)
    case SearchPfuActionTypes.FETCH_VIDEO_SHOWS_SUCCESS:
      return setFetchShowsSuccess(state, action.payload)
    case SearchPfuActionTypes.FETCH_VIDEO_SHOWS_FAILURE:
      return setFetchShowsFailure(state, action.payload)
    case SearchPfuActionTypes.FETCH_AUDIO_SHOWS_SUCCESS:
      return setFetchAudioShowsSuccess(state, action.payload)
    case SearchPfuActionTypes.FETCH_AUDIO_SHOWS_FAILURE:
      return setFetchAudioShowsFailure(state, action.payload)
    default:
      return state
  }
}
