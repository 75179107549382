import {
  IAudioShowResolvedItem,
  IRecommendationConfig,
  IStandardConfig,
  ITimeSlot,
  IVideoSeasonResolvedItem,
  IVideoShowResolvedItem,
  IVisualEntertainmentConfig,
  TBaseSlot,
} from 'models/container/container'
import { EConfigSource, EContainerSlotType, EDays, ESlotConfig } from 'models/container/enumeration'
import { dateOrUndefined, hourToDate } from 'utils/date'

import { IListOption } from '../../../../components/autocompleteselect/AutoCompleteSelect'

function getConfigProperty(
  slot: TBaseSlot,
  key:
    | 'contentCategories'
    | 'contentTags'
    | 'contentSources'
    | 'contentTypes'
    | 'contentOwners'
    | 'contentQueryTypologies'
    | 'shows'
    | 'showSeasons'
    | 'genres'
    | 'audioShows'
): string[] | undefined {
  if (slot.config.type === ESlotConfig.VISUAL_ENTERTAINMENT) {
    return slot.config[key as keyof IVisualEntertainmentConfig] as string[]
  }
  if (slot.config.type === ESlotConfig.STANDARD) {
    return slot.config[key as keyof IStandardConfig] as string[]
  }
  if (slot.config.type === ESlotConfig.RECOMMENDATION) {
    return slot.config[key as keyof IRecommendationConfig] as string[]
  }

  return undefined
}

export const activeTimeSlotsToDays = (activeTimeSlots: ITimeSlot[] = []): IActiveDays => {
  return {
    [EDays.MONDAY]: getDay(EDays.MONDAY, activeTimeSlots),
    [EDays.TUESDAY]: getDay(EDays.TUESDAY, activeTimeSlots),
    [EDays.WEDNESDAY]: getDay(EDays.WEDNESDAY, activeTimeSlots),
    [EDays.THURSDAY]: getDay(EDays.THURSDAY, activeTimeSlots),
    [EDays.FRIDAY]: getDay(EDays.FRIDAY, activeTimeSlots),
    [EDays.SATURDAY]: getDay(EDays.SATURDAY, activeTimeSlots),
    [EDays.SUNDAY]: getDay(EDays.SUNDAY, activeTimeSlots),
  }
}

export const activeTimeSlotsToStartTime = (activeTimeSlots: ITimeSlot[] = []): Date => {
  if (activeTimeSlots.length === 0) {
    return hourToDate('00:00')
  }

  return hourToDate(activeTimeSlots[0].startBoundary.timeOfDay)
}

export const activeTimeSlotsToEndTime = (activeTimeSlots: ITimeSlot[] = []): Date => {
  if (activeTimeSlots.length === 0) {
    return hourToDate('23:59')
  }

  return hourToDate(activeTimeSlots[0].endBoundary.timeOfDay)
}

export const activeTimeSlotsStartDay = (activeTimeSlots: ITimeSlot[] = []): EDays => {
  if (!activeTimeSlots.length) {
    return null
  }
  return activeTimeSlots[0].startBoundary.dayOfWeek
}
export const activeTimeSlotsEndDay = (activeTimeSlots: ITimeSlot[] = []): EDays => {
  if (!activeTimeSlots.length) {
    return null
  }
  return activeTimeSlots[0].endBoundary.dayOfWeek
}

const getDay = (day: EDays, timeSlots: ITimeSlot[]): boolean => {
  // allows you to have all the checkbox active when you open the programming
  // acceptance criteria for PFU-23783
  if (timeSlots.length === 0) {
    return true
  }

  return timeSlots.some((timeSlot) => timeSlot.startBoundary.dayOfWeek === day)
}

export interface IActiveDays {
  [EDays.MONDAY]: boolean
  [EDays.TUESDAY]: boolean
  [EDays.WEDNESDAY]: boolean
  [EDays.THURSDAY]: boolean
  [EDays.FRIDAY]: boolean
  [EDays.SATURDAY]: boolean
  [EDays.SUNDAY]: boolean
}

export enum RecurrenceType {
  NONE = 'INACTIVE',
  CONSECUTIVE = 'CONSECUTIVE',
  INTERMITTENT = 'INTERMITTENT',
}

export interface IRecurrence {
  type: RecurrenceType
  startDateTime: Date | null
  startDateDay: EDays | null
  endDateTime: Date
  endDateDay: EDays
  activeDays: IActiveDays
}

export default class ContainerRequestForm {
  public name: string
  public type: EContainerSlotType
  public contentCategories: string[]
  public contentTags: string[]
  public genres: string[]
  public shows: string[]
  public showSeasons: string[]
  public showsLabels: IListOption[]
  public audioShowsLabels: IListOption[]
  public audioShows: string[]
  public contentSources: string[]
  public contentTypes: string[]
  public contentQueryTypologies: string[]
  public contentOwners: string[]
  public contentAdministrativeRegionsLabels: IListOption[]
  public contentAdministrativeRegions: number[]
  public source: EConfigSource
  public identifier: string
  public positions: number[]
  public containerConfiguration: ESlotConfig
  public activationStartDateTime: Date
  public activationEndDateTime: Date
  public requestCompletedItems: boolean
  public resolvedShows: IVideoShowResolvedItem[]
  public resolvedVideoSeasons: IVideoSeasonResolvedItem[]
  public resolvedAudioShows: IAudioShowResolvedItem[]
  public requestRecurrence: IRecurrence

  constructor(slot: TBaseSlot, positionIndexes: number[]) {
    this.name = slot.name || ''
    this.type = slot.type
    this.identifier = slot.config.type === ESlotConfig.IDENTIFIER ? slot.config.identifier : undefined
    this.contentTypes = getConfigProperty(slot, 'contentTypes')
    this.contentQueryTypologies = getConfigProperty(slot, 'contentQueryTypologies')
    this.contentSources = getConfigProperty(slot, 'contentSources')
    this.contentCategories = getConfigProperty(slot, 'contentCategories')
    this.contentOwners = getConfigProperty(slot, 'contentOwners')
    this.contentAdministrativeRegions =
      slot.config.type === ESlotConfig.STANDARD ? slot.config.contentAdministrativeRegions : undefined
    this.contentTags = getConfigProperty(slot, 'contentTags')
    this.genres = getConfigProperty(slot, 'genres')
    this.shows = getConfigProperty(slot, 'shows')
    this.showSeasons = getConfigProperty(slot, 'showSeasons')
    this.audioShows = getConfigProperty(slot, 'audioShows')
    this.source = slot.config.type === ESlotConfig.IDENTIFIER ? slot.config.source : undefined
    this.activationStartDateTime = dateOrUndefined(slot.config.activationStartDateTime)
    this.activationEndDateTime = dateOrUndefined(slot.config.activationEndDateTime)
    this.positions = positionIndexes
    this.containerConfiguration = slot.config.type ? slot.config.type : ESlotConfig.STANDARD
    this.requestCompletedItems = 'requestCompletedItems' in slot.config ? slot.config.requestCompletedItems : undefined
    this.requestRecurrence = {
      type: slot.config.recurrence?.recurrenceType || RecurrenceType.NONE,
      startDateTime: activeTimeSlotsToStartTime(slot.config.recurrence?.activeTimeSlots),
      endDateTime: activeTimeSlotsToEndTime(slot.config.recurrence?.activeTimeSlots),
      startDateDay: activeTimeSlotsStartDay(slot.config.recurrence?.activeTimeSlots),
      endDateDay: activeTimeSlotsEndDay(slot.config.recurrence?.activeTimeSlots),
      activeDays: activeTimeSlotsToDays(slot.config.recurrence?.activeTimeSlots),
    }
    this.resolvedShows = 'resolvedShows' in slot.config ? slot.config.resolvedShows : undefined
    this.resolvedVideoSeasons = 'resolvedVideoSeasons' in slot.config ? slot.config.resolvedVideoSeasons : undefined
    this.resolvedAudioShows = 'resolvedAudioShows' in slot.config ? slot.config.resolvedAudioShows : undefined
  }
}
