import ApiFlowManagerDetail from 'models/apiflowmanager/ApiFlowManagerDetail'
import { TContainer } from 'models/container/container'
import { TContainerUpdateParams } from 'models/detail/container/ContainerUpdate'
import { TContainerCreateDTO } from 'models/forms/container/ContainerCreateDTO'

import {
  TFetchContainerAction,
  TFetchContainerSuccessAction,
  TFetchContainerFailureAction,
  TUpdateContainerAction,
  TUpdateContainerSuccessAction,
  TUpdateContainerFailureAction,
  TCreateContainerAction,
  TCreateContainerSuccessAction,
  TCreateContainerFailureAction,
  TDeleteContainerAction,
  TDeleteContainerSuccessAction,
  TDeleteContainerFailureAction,
  TFetchContainerPreviewAction,
  TFetchContainerPreviewSuccessAction,
  TFetchContainerPreviewFailureAction,
} from './container'
import { ContainerActionTypes } from './container.action.types'

export const fetchContainer = (id: string): TFetchContainerAction => ({
  type: ContainerActionTypes.FETCH_CONTAINER,
  payload: id,
})

export const fetchContainerSuccess = (content: TContainer): TFetchContainerSuccessAction => ({
  type: ContainerActionTypes.FETCH_CONTAINER_SUCCESS,
  payload: content,
})

export const fetchContainerFailure = (apiFlowManagerDetail: ApiFlowManagerDetail): TFetchContainerFailureAction => ({
  type: ContainerActionTypes.FETCH_CONTAINER_FAILURE,
  payload: apiFlowManagerDetail,
})

export const fetchContainerPreview = (id: string): TFetchContainerPreviewAction => ({
  type: ContainerActionTypes.FETCH_CONTAINER_PREVIEW,
  payload: id,
})

export const fetchContainerPreviewSuccess = (content: TContainer): TFetchContainerPreviewSuccessAction => ({
  type: ContainerActionTypes.FETCH_CONTAINER_PREVIEW_SUCCESS,
  payload: content,
})

export const fetchContainerPreviewFailure = (
  apiFlowManagerDetail: ApiFlowManagerDetail
): TFetchContainerPreviewFailureAction => ({
  type: ContainerActionTypes.FETCH_CONTAINER_PREVIEW_FAILURE,
  payload: apiFlowManagerDetail,
})

export const updateContainer = (id: string, body: TContainerUpdateParams): TUpdateContainerAction => ({
  type: ContainerActionTypes.UPDATE_CONTAINER,
  payload: { id, body },
})

export const updateContainerSuccess = (content: TContainer): TUpdateContainerSuccessAction => ({
  type: ContainerActionTypes.UPDATE_CONTAINER_SUCCESS,
  payload: content,
})

export const updateContainerFailure = (apiFlowManagerDetail: ApiFlowManagerDetail): TUpdateContainerFailureAction => ({
  type: ContainerActionTypes.UPDATE_CONTAINER_FAILURE,
  payload: apiFlowManagerDetail,
})

export const createContainer = (container: TContainerCreateDTO): TCreateContainerAction => ({
  type: ContainerActionTypes.CREATE_CONTAINER,
  payload: container,
})

export const createContainerSuccess = (container: TContainer): TCreateContainerSuccessAction => ({
  type: ContainerActionTypes.CREATE_CONTAINER_SUCCESS,
  payload: container,
})

export const createContainerFailure = (apiFlowManagerDetail: ApiFlowManagerDetail): TCreateContainerFailureAction => ({
  type: ContainerActionTypes.CREATE_CONTAINER_FAILURE,
  payload: apiFlowManagerDetail,
})

export const deleteContainer = (id: string): TDeleteContainerAction => ({
  type: ContainerActionTypes.DELETE_CONTAINER,
  payload: id,
})

export const deleteContainerSuccess = (): TDeleteContainerSuccessAction => ({
  type: ContainerActionTypes.DELETE_CONTAINER_SUCCESS,
})

export const deleteContainerFailure = (apiFlowManagerDetail: ApiFlowManagerDetail): TDeleteContainerFailureAction => ({
  type: ContainerActionTypes.DELETE_CONTAINER_FAILURE,
  payload: apiFlowManagerDetail,
})
