import * as React from 'react'

import Markdown from 'markdown-to-jsx'

import { default as s } from './release-notes-page.styles.scss'
import {
  sprint46And47,
  sprint48,
  sprint49,
  sprint50,
  sprint51,
  sprint52to55,
  sprint56to57,
} from './releasesNote/sprint'

const PlaylistOrchestratePage = (): JSX.Element => {
  return (
    <div className={s.wrapper}>
      <div className={s.container}>
        <a href="/" className={s.goHome}>
          Retour vers QUBrique
        </a>
        <h1>Notes de version</h1>
        <Markdown>{sprint56to57}</Markdown>
        <Markdown>{sprint52to55}</Markdown>
        <Markdown>{sprint51}</Markdown>
        <Markdown>{sprint50}</Markdown>
        <Markdown>{sprint49}</Markdown>
        <Markdown>{sprint48}</Markdown>
        <Markdown>{sprint46And47}</Markdown>
      </div>
    </div>
  )
}

export default PlaylistOrchestratePage
