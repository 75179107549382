import { all, call, fork, put, takeLatest } from 'redux-saga/effects'

import { fetchContainerApi, updateContainerApi, createContainerApi, deleteContainerApi } from 'api/Container'
import { TContainer } from 'models/container/container'
import { toggleCreationMode } from 'modules/create-panel-detail/createContainerActions'
import { toggleEditMode } from 'modules/detailpanel/commonDetailPanelActions'
import { clearCurrentSlot } from 'modules/request-form/request-form.actions'
import { apiCallErrorHandling } from 'services/apicallsagaservice/apiCallErrorHandling'

import { clearSelectedCards } from '../requests'

import {
  fetchContainerSuccess,
  fetchContainerFailure,
  updateContainerSuccess,
  updateContainerFailure,
  createContainerFailure,
  deleteContainerFailure,
  createContainerSuccess,
  fetchContainerPreviewSuccess,
  fetchContainerPreviewFailure,
  deleteContainerSuccess,
} from './container.action'
import { ContainerActionTypes } from './container.action.types'
import {
  TFetchContainerAction,
  TUpdateContainerAction,
  TCreateContainerAction,
  TDeleteContainerAction,
  TFetchContainerPreviewAction,
} from './container.d'

export function* fetchContainerAsync({ payload }: TFetchContainerAction) {
  try {
    const container: TContainer = yield call(fetchContainerApi, payload)

    yield put(fetchContainerSuccess(container))
    yield put(clearCurrentSlot())
  } catch (error) {
    yield put(fetchContainerFailure(apiCallErrorHandling(error, { id: payload })))
  }
}

export function* fetchContainerPreviewAsync({ payload }: TFetchContainerPreviewAction) {
  try {
    const container: TContainer = yield call(fetchContainerApi, payload)
    yield put(fetchContainerPreviewSuccess(container))
  } catch (error) {
    yield put(fetchContainerPreviewFailure(apiCallErrorHandling(error, { id: payload })))
  }
}

export function* updateContainerAsync({ payload }: TUpdateContainerAction) {
  try {
    yield call(updateContainerApi, payload.id, payload.body)
    const container: TContainer = yield call(fetchContainerApi, payload.id)

    yield put(updateContainerSuccess({ ...container }))
    yield put(toggleEditMode(false))
    yield put(clearCurrentSlot())
    yield put(clearSelectedCards())
  } catch (error) {
    yield put(updateContainerFailure(apiCallErrorHandling(error, { id: payload.id })))
  }
}

export function* createContainerAsync({ payload }: TCreateContainerAction) {
  try {
    const container: TContainer = yield call(createContainerApi, { data: payload })

    yield put(createContainerSuccess(container))
    yield put(toggleCreationMode(false))
  } catch (error) {
    yield put(createContainerFailure(apiCallErrorHandling(error)))
    yield put(toggleCreationMode(true))
  }
}

export function* deleteContainerAsync({ payload }: TDeleteContainerAction) {
  try {
    yield call(deleteContainerApi, payload)
    yield put(deleteContainerSuccess())
  } catch (error) {
    yield put(deleteContainerFailure(apiCallErrorHandling(error, { id: payload })))
  }
}

function* watchFetchContainerAsync() {
  yield takeLatest(ContainerActionTypes.FETCH_CONTAINER, fetchContainerAsync)
}

function* watchFetchContainerPreviewAsync() {
  yield takeLatest(ContainerActionTypes.FETCH_CONTAINER_PREVIEW, fetchContainerPreviewAsync)
}

function* watchUpdateContainerAsync() {
  yield takeLatest(ContainerActionTypes.UPDATE_CONTAINER, updateContainerAsync)
}

function* watchCreateContainerAsync() {
  yield takeLatest(ContainerActionTypes.CREATE_CONTAINER, createContainerAsync)
}

function* watchDeleteContainerAsync() {
  yield takeLatest(ContainerActionTypes.DELETE_CONTAINER, deleteContainerAsync)
}

export function* rootContainerSaga() {
  yield all([
    fork(watchFetchContainerAsync),
    fork(watchFetchContainerPreviewAsync),
    fork(watchUpdateContainerAsync),
    fork(watchCreateContainerAsync),
    fork(watchDeleteContainerAsync),
  ])
}
