export const sprint46And47 = `
## Nouvelles livraisons – Sprint 46-47 (14 Mai, 2021)

### Ce sprint se termine avec la livraison des fonctionnalités suivantes:

*   Appliquer les intérêts aux vidéos via leur émission (PFU-42086). Les intérêts d'une émission sont désormais applicables aux vidéos qui font partie de celle-ci. On sauve du temps et, en plus, on ouvre la porte à des requêtes plus riches et complètes.
*   Voir les sources Qub des articles (PFU-46268). Maintenant, lorsque l'on écrit des articles dans EMP pour une source Qub spécifique, cette information est maintenant conservée et présentée dans Qubrique (TVA+, Qub Musique et Qub Radio).
*   Cliquer sur l'ID PFU d'un article dans Qubrique et voir le résultat dans Universel (PFU-45772). Pendant plusieurs mois, le clic permettait de voir l'article... dans Qub Musique! Ce temps est révolu; nous vous invitons à cliquer sans modération pour profiter de l'habillage Universel.
*   Filtrer par type de capsule audio (PFU-44505). Les options de filtres facilitent l'orchestration, permettent de peaufiner la recherche. Pour cette raison, de nouveaux filtres, au niveau des sous-types, sont maintenant disponibles pour les contenus audio. Hourra!
*   Établir la source d'une page (PFU-39998). Les orchestrateurs peuvent maintenant établir la source des pages qu'ils créent, soit: Qub Musique, TVA+, Qub Radio et Qub Info. Cela permettra aux Apps d'appliquer les bonnes mises en page.
`

export const sprint48 = `
## Nouvelles livraisons – Sprint 48 (4 Juin, 2021)

### Ce sprint se termine avec la livraison des fonctionnalités suivantes:

*   TADA ! Accéder à une page dans Qubrique pour les releases notes (PFU-26860). L'équipe est heureuse de pouvoir vous partager ses nouvelles livraisons grâce à cette nouvelle page. Elle sera mise à jour à la fin de chaque sprint ce qui vous permettra de ne rien manquer. Cette page sera appelée à évoluer selon votre feedback.
*   Pouvoir créer un carrousel miroir d'un carrousel original pour déterminer un titre public différent (PFU-45049). Fini les duplicata de carrousels uniquement pour changer leur titre public! Un carrousel miroir permet de conserver le même contenu qu'un carrousel original tout en pouvant modifier le titre public, le titre de travail, le sous-type et, pour l'option mosaïque, changer le nombre de rangées.
*   Pouvoir faire plusieurs recherches et trouver plusieurs contenus/contenants grâce à des onglets dans la partie centrale (PFU-19115). Comment? En cliquant sur le petit + dans le haut de la partie centrale. Des onglets supplémentaires sont ainsi disponibles pour effectuer de nouvelles recherches. Les panneaux de gauche et du centre s'ajusteront selon les recherches/résultats tandis que le panneau de droite ne changera qu'au clic d'une carte. Un bel ajout pour faciliter l'orchestration!
*   Pouvoir voir la "Version" dans les titres de chansons afin de pouvoir distinguer lesdites chansons (PFU-20660). Ce travail profite à la fois aux utilisateurs de la PFU et aux orchestrateurs. D'une part, dans la PFU, dans un album, l'utilisateur voit maintenant la version des chansons. Plein de valeur niveau orchestration aussi puisque cette version est visible et recherchable dans Qubrique!
*   Pouvoir retirer des albums qui ont été retirés du catalogue de Qobuz avant de recevoir la mise à jour mensuelle du catalogue (PFU-46309). Le retrait est possible sur cette page Qubrique:{' '} [https://qubrique.qub.ca/orchestration-playlist](https://qubrique.qub.ca/orchestration-playlist){' '} en utilisant l'ID PFU de l'album.
*   Pouvoir ajouter 2 images pour un bandeau et 4 images pour une affiche dans Qubrique (PFU-46208). Toujours mettre 4 images même si seulement 2 étaient nécessaires obligeait les orchestrateurs à mettre des images inutiles pour répondre à la règle contraignante. Voilà chose du passé.
*   Pouvoir voir la bonne source Qub sur les pages (PFU-48136). Au dernier sprint, il devenait possible d'ajouter une source Qub à une page... mais impossible de le faire pour les pages existantes. Problème résolu, les pages existantes ont maintenant leur source Qub !
*   Ajouter "VRAI" à la fin du slider de disponibilité (PFU-48144). En ajoutant cette disponibilité, cela nous permet de travailler le dossier d'ingestion du projet VRAI. Sachez que tout le dossier de permission et de disponibilité évoluera dans les prochains mois. Ce slider sera appelé à changer aussi. La suite à venir !
*   Voir les bons logos partout (PFU-47810, PFU-49066, PFU-48728). Ce n'est pas tant une fonctionnalité d'orchestration mais c'est tellement l'fun de voir les bons logos pour Billie, TVA Sports et AddikTV :wink:
*   (en cours) Ne plus voir le champ "description" de l'affiche (PFU-41156). Ne le cherchez plus, ce champ qui créait de la confusion n'est plus dans la fiche de détail d'une affiche, ni dans la section création et modification de l'affiche.
*   (en cours) Pouvoir rechercher par ID d'origine. Parfois les ID des systèmes d'origine sont le meilleur moyen de rechercher un contenu dans Qubrique. Et c'est maintenant possible de rechercher ainsi : tout objet étant une correspondance exacte avec l’ID entré sera mis en valeur dans la carte centrale.
`

export const sprint49 = `
## Nouvelles livraisons – Sprint 49 (28 Juin, 2021)

### Ce sprint se termine avec la livraison des fonctionnalités suivantes:

## Historique de consultation

Il est maintenant possible de visualiser les 50 derniers objets cliqués dans Qubrique. Comment ? Il n'y a qu'à cliquer sur l'icône "Historique" dans la barre bleue de gauche. Vous pouvez retrouver et orchestrer le contenu récemment consultés. Il est aussi possible de naviguer des icônes "Recherche" et "Historique". Une autre fonctionnalité qui facilite l'orchestration de contenu. Yeah !

## Davantage de contenu visible dans le tout voir !

Un carrousel présente jusqu'à 20 cartes; rien de nouveau ici. Cependant, lorsque l'on clique sur "tout voir", il est maintenant possible d'y présenter 80 cartes, au lieu de 20, par requête.

## Émission entièrement orchestrable

Si l'on ingère des vidéos dont l'émission est inexistante, notre système crée la page émission avec des saisons et des carrousels par défaut. Nous gagnons beaucoup de temps grâce à cette automatisation... mais elle est rigide et ne répond pas aux besoins de certaines émissions. Nous avons donc ajouté l'option "Émission entièrement orchestrable". Lorsque cette option est activée, les saisons et les carrousels ne sont pas générés par défaut. Cela permet une pleine flexibilité d'orchestration.`

export const sprint50 = `
##2 belles nouveautés pour Qubrique - Spint 50 (28 juin - 19 juillet 2021)


###1 - PROGRAMMATION POUR LES JOURS XY DE LA SEMAINE
Vous voulez qu'un contenu ou qu'un carrousel apparaissent dans Qubrique seulement les lundis et jeudis? Parfait! Il n'y a qu'à cocher les jours souhaités sous la période de diffusion! Cette programmation s'appliquera tant et aussi longtemps que la période de diffusion est active  

**L'ajout de la plage horaire de diffusion, en mode intermittent (par exemple, les jours sélectionnés de 13h à 15h) ou en mode continu (par exemple du jeudi 13h au dimanche 19h), viendra compléter cette fonctionnalité dans les prochaines semaines. Restez à l'affût 
 
##2- DIFFUSEURS
Vous voulez visualiser un "À lire aussi" ou un "Sur le même sujet" dans le bas des articles? Voilà chose possible avec les diffuseurs! Un diffuseur permet de cibler des articles, selon leurs sources et leurs catégories puis d'y définir les carrousels que l'on veut visualiser dans le bas de ces articles.**Les étapes dans Qubrique**   

1.  Créer un diffuseur et y définir les articles (sources, catégories) ciblés;
2.  Créer ou choisir un carrousel selon le contenu que l'on veut diffuser;
3.  Glisser le carrousel choisi dans l'onglet "contenu" du diffuseur;
4.  Profiter de ce carrousel dans le bas de tous les articles ciblés.

##Attention
Pour le moment, un diffuseur est visible uniquement dans le bas **des articles**. Aussi, lorsque l'on définit du contenu dans un diffuseur, il faut **glisser des carrousels et non créer une requête**.`

export const sprint51 = `

##De belles nouveautés pour Qubrique en ce sprint 51 - (19 juillet au 9 août 2021)

##Navigation unifiée

Fini les silos entre Qubrique, Qobuz et Qubuz nouveautés ! Le passage d'une expérience à une autre s'effectue maintenant à partir des onglets de recherche, dans la même navigation globale. Ainsi, un onglet de recherche peut présenter Qubrique, tandis qu'un autre Qobuz ou Qobuz Nouveautés.Pourquoi ?  

-   Pour profiter des onglets de recherche autant dans Qubrique que Qobuz que Qobuz nouveautés
-   Pour passer d'une expérience à une autre rapidement
-   Pour permettre une orchestration simple dans une même navigation globale.

À savoir: en cliquant le petit + pour ouvrir un nouvel onglet de recherche, celui-ci s'ouvrira dans Qubrique, Qobuz ou Qobuz nouveautés selon l'onglet de recherche qui était préalablement actif. Ce n'est pas la bonne expérience ? Simplement ouvrir le nouvel onglet en cliquant plutôt sur le petit burger (les 3 petits points) !

##Programmation d'une requête entre les heures X et Y

Il est déjà possible de programmer une requête sur une période de temps, aussi avec une récurrence à la semaine (par exemple, du 28 août 2020 jusqu’au 29 octobre 2020, les lundis et jeudis).

Afin d’offrir un plus grand choix de programmation, il est maintenant possible de programmer le tout selon une plage de diffusion dans les jours ciblés (par exemple, durant la période du 28 août 2020 jusqu’au 29 octobre 2020, tous les lundis et jeudis, de 19h à 21h).

##Article sous embargo visible et orchestrable dans Qubrique##  

Les articles sous embargo (selon un horodatage défini dans EMP) sont maintenant visibles dans Qubrique, ce qui permet une orchestration desdits articles avant leur diffusion dans la PFU. Ces articles comportent un libellé "Embargo", ce qui permet de les reconnaître.

##Chantier en cours - En-têtes
Un nouvel onglet dans le détail des fils est maintenant disponible dans Qubrique, il permettra d'orchestrer les en-têtes. C'est un chantier en cours donc si vous orchestrez la chose, pour le moment le résultat ne s'affichera pas dans la PFU; ça s'en vient!

`

export const sprint52to55 = `
##Des livres dans qub !
Avez-vous remarqué les livres dans Qubrique ? Oui oui, ils sont maintenant ingérés. Wow!

Ainsi, dans Qubrique, vous remarquerez les filtres pour la source “Qub livre” et le type “Livre”. Aussi, vous verrez les cartes centrales et le panneau de détail bien rempli pour chacune d'entre elles. Et, dans les requêtes, la source livre s'y retrouve. Nous attendons maintenant les cartes de [Qub](https://qub.ca)  pour orchestrer tout ce beau contenu.

##Programmation d'une plage de diffusion sur plusieurs jours consécutifs

L'équipe avait développé la programmation à la journée (les lundis et jeudis) et la plage de diffusion pour chaque journée sélectionnée (les lundis et jeudis de 19h à 21h). Maintenant, il est possible de programmer sur plusieurs jours consécutifs (du vendredi 16h au dimanche 23h). Vive les carrousels de semaine et de weekend!

##Orchestration des pages d'accueil

Vous pouvez maintenant orchestrer des pages d'accueils dans des affiches, des bandeaux et tous les types de carrousels. Alleluia 

##Gestion et visibilité des articles sous embargo

Les articles sous embargo sont visibles et orchestrables dans Qubrique. Personne ne voit ces articles sur [Qub](https://qub.ca) tant que la date de l'embargo n'est pas tombée SAUF s’ils ont le lien sécurisé fourni dans Qubrique.

##Amélioration du visuel de la source sur la carte centrale

Auparavant, toutes les sources étaient en noir et blanc et en très petit format sur les cartes centrales. Il devenait difficile de reconnaître certaines sources, le cas extrême étant JdM et JdQ. Ceci est chose du passé puisque les logos sont plus clair et en couleur. Yayyyy

##Duplication des affiches 

Dupliquer une affiche et changer son appel à l'action? Allez-y c’est fait!

##Qub Info de EMP2 jusqu'à qub.ca

Dans EMP2, on peut choisir Qub Info comme source pour les articles. Cela va permettre d'envoyer des articles d'information uniquement à Qub si souhaité (on peut penser aux dossiers immersifs à terme).

##Amélioration de différents éléments radio

Plusieurs améliorations peuvent être observées : la cohérence dans le nom des filtres audio, la pastille du nom des émissions sur les cartes centrales des clips audio (information aussi disponible dans le détail).

#Septembre 2021

##Ajout des "détenteur" dans la reprise de lecture

Principalement pour bien séparer les contenus Vrai de TVA+, le choix du détenteur a été ajouté dans la requête de reprise de lecture. À noter que tous les détenteurs y sont disponibles, donc la reprise de lecture s'applique aussi pour du contenu audio et musique.

##Ajout de granularité dans les requêtes audio

Faire une requête standard sur les typologies Épisode (audio), Bande-annonce (audio), Intégrale, Segment, Extra et Émission (audio), c’est beaucoup plus intéressant que sur Audio uniquement  

##Utilisation de plusieurs onglets dans Qubrique

1 onglet de recherche, c'est trop peu. Allons-y pour 5... non pour 10 ! Voilà une belle amélioration pour faciliter l'orchestration.

##Pouvoir inactiver un diffuseur

Les diffuseurs c'est beau, mais si besoin il y a, les inactiver c'est pratique.
`

export const sprint56to57 = `
##Nouveautés Qubrique

#Décembre 2021

##Des carrousels menant à des pages ou des fils plutôt qu'à un “tout voir”, c’est possible!
Pour changer la destination d’un carrousel - par exemple, le carrousel des épisodes Sucré Salé menant à l'émission, ou les derniers articles du Journal de Montréal menant au fil de cette source - il suffit de glisser la carte de destination souhaité dans la boîte “contenu lié” visbile dans l’onglet détails du carrousel.

##De nouveaux filtres d’ajoutent dans Qubrique pour les détenteurs et Shopify
Pour voir tout le contenu lié à la grande famille “INFO” (comprenant le Journal de Montréal, 7 jours, 24 heures et autres marques) ou TVA+ (dont Zeste, Casa), vous n’avez qu'à filtrer par le bon détenteur. Aussi, nous avons ajouté dans les options de filtres par système d’origine, Shopify.

##Les carrousels avec le même titre, ce n’est pas possible et Qubrique vous en informe
Ne soyez pas surpris de le voir, nous présentons maintenant un message à l’orchestrateur lorsqu’il tente de créer un carrousel (ou une page) ayant le même titre qu’un autre.

##Meilleure gestion des doublons du fil d’accueil
La gestion des doublons retirait plusieurs résultats de requêtes dans le fil d’accueil. À cause de ces nombreux retraits le fil ne représentait pas du tout ce qui était orchestré dans Qubrique. L'équipe a donc grandement amélioré la gestion de ces doublons permettant une meilleure consistance entre l'orchestration et la visualisation du fil. 

#Novembre 2021
##Qub radio a maintenant des vidéos
Le compte NumeriQ Qub Radio de Brightcove est maintenant ingéré par la PFU. Il est ainsi possible de retrouver toutes les vidéos de ce compte dans Qubrique, de les orchestrer puis de les consommer dans qub. Les URL ainsi que les bandeaux de pages pour ces vidéos sont aussi ajustés en conséquence.

##Requêtes sur des émissions audio
Les carrousels présentant les derniers clips audio d’une émission n’ont plus à être orchestrés manuellement puisque les requêtes par émission couvre maintenant les émissions audio. Une belle automatisation pour ces ajouts quotidiens.

##Module de permissions dans Qubrique
Les permissions continuent d'évoluer dans qub. Actuellement, Vrai comporte des permissions spéciales mais bientôt il y aura de nouvelles marques puis de nouveaux forfaits (on peut penser au forfait INFO et le concept de teaser). L'équipe a donc travaillé le module de permissions afin de se lier au nouveau service de permission et permettre une belle évolution.

##Des ID PFU cliquable dans Qubrique
Jusqu'à tout récemment, seuls les ID des articles provenant de EMP2 étaient cliquables. Maintenant, il est possible de cliquer sur la presque totalité des ID d'objets dans Qubrique afin de voir le rendu dans qub.
`
