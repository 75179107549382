export enum EDroppableIdentifier {
  'CONTENT_LIST' = 'CONTENT_LIST',
  'FILTERED_LIST' = 'FILTERED_LIST',
  'REQUEST_LIST' = 'REQUEST_LIST',
  'SPONSOR_LIST' = 'SPONSOR_LIST',
  'RELATIONSHIP_LIST' = 'RELATIONSHIP_LIST',
  'POSTER_LINKED_CONTENT_CREATE' = 'POSTER_LINKED_CONTENT_CREATE',
  'POSTER_LINKED_CONTENT_EDIT' = 'POSTER_LINKED_CONTENT_EDIT',
  'CAROUSEL_LINKED_CONTENT_CREATE' = 'CAROUSEL_LINKED_CONTENT_CREATE',
  'CAROUSEL_LINKED_CONTENT_EDIT' = 'CAROUSEL_LINKED_CONTENT_EDIT',
  'VIDEO_PREVIEW_HORIZONTAL' = 'VIDEO_PREVIEW_HORIZONTAL',
  'VIDEO_PREVIEW_VERTICAL' = 'VIDEO_PREVIEW_VERTICAL',
  'FEED' = 'FEED',
  'PAGE' = 'PAGE',
  'MENU' = 'MENU',
  'PARENT_REFERENCE_CREATE' = 'PARENT_REFERENCE_CREATE',
}
