import { IRouteParams } from 'providers/RouterProvider'

import { TRouterHistoryAction } from './router'
import { RouterActionTypes } from './router.action.types'

export const routerHistoryList = (routeParams: IRouteParams): TRouterHistoryAction => ({
  type: RouterActionTypes.ROUTER_HISTORY_LIST_PUSH,
  payload: routeParams,
})

export const routerHistoryUpdateList = (routeParams: IRouteParams[]): TRouterHistoryAction => ({
  type: RouterActionTypes.ROUTER_HISTORY_LIST_UPDATE,
  payload: routeParams,
})

export const routerHistoryListClear = (): TRouterHistoryAction => ({
  type: RouterActionTypes.ROUTER_HISTORY_LIST_CLEAR,
})

export const routerHistoryOrigin = (location: { location: Location }): TRouterHistoryAction => ({
  type: RouterActionTypes.ROUTER_HISTORY_ORIGIN_PUSH,
  payload: location,
})
