import { Configuration } from '@azure/msal-browser'

import config from 'config'

export const msalConfig: Configuration = {
  cache: {
    cacheLocation: 'localStorage',
    secureCookies: true,
    storeAuthStateInCookie: true,
  },
  auth: {
    authority: config.msalConfig.authority,
    clientId: config.msalConfig.clientId,
    redirectUri: window.location.origin,
    postLogoutRedirectUri: window.location.origin,
  },
}
