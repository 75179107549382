import uniqBy from 'lodash/uniqBy'

import ApiFlowManagerDetail from 'models/apiflowmanager/ApiFlowManagerDetail'
import { EApiFlowManagerType, EApiFlowManagerPresentationType } from 'models/apiflowmanager/Enumeration'
import { ICards } from 'models/card/GenericCard'
import { CardsActionTypes } from 'redux/actions/cards'
import { SearchPfuActionTypes } from 'redux/actions/search-pfu'

import { ISearchState, TSearchReducerAction } from './search.d'

export const initialSearchState: ISearchState = {
  offset: 0,
  start: 0,
  total: 0,
  exhaustiveTotal: true,
  cards: [],
  apiFlowManagerDetail: new ApiFlowManagerDetail(EApiFlowManagerType.LOADER, EApiFlowManagerPresentationType.GLOBAL),
}

const setSearch = (state: ISearchState, payload: ICards): ISearchState => ({
  ...state,
  offset: payload.offset,
  start: payload.start,
  total: payload.total,
  exhaustiveTotal: payload.exhaustiveTotal,
  cards: payload.cards,
  apiFlowManagerDetail: undefined,
})

const setSearchSuccess = (state: ISearchState, payload: ICards): ISearchState => ({
  ...state,
  offset: payload.offset,
  start: payload.start,
  total: payload.total,
  exhaustiveTotal: payload.exhaustiveTotal,
  cards: uniqBy([...state.cards, ...payload.cards], 'id'),
  apiFlowManagerDetail: undefined,
})

const setSearchFailure = (state: ISearchState, payload: ApiFlowManagerDetail): ISearchState => ({
  ...state,
  apiFlowManagerDetail: payload,
})

export const searchReducer = (state: ISearchState = initialSearchState, action: TSearchReducerAction) => {
  switch (action.type) {
    case SearchPfuActionTypes.SEARCH_PFU_CATALOG_SUCCESS:
      return setSearchSuccess(state, action.payload)
    case SearchPfuActionTypes.SEARCH_PFU_CATALOG_FAILURE:
      return setSearchFailure(state, action.payload)
    case SearchPfuActionTypes.SEARCH_PFU_HISTORY:
      return setSearch(state, action.payload)
    case CardsActionTypes.RESET_CARDS:
      return initialSearchState
    default:
      return state
  }
}
