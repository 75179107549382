import isEqual from 'lodash/isEqual'
import shuffle from 'lodash/shuffle'

import { ISlot, TBaseSlot } from 'models/container/container'
import { ESlotConfig } from 'models/container/enumeration'
import { RequestsActionTypes } from 'redux/actions/requests'
import ContainerFormService from 'services/ContainerFormService'

import { IRequestsState, TRequestsReducerAction } from './requests.d'
import { syncColors } from './requests.utils'

export const initialRequestsState: IRequestsState = {
  colors: {},
  positions: [],
  slots: [],
  selectedCards: [],
}

const initSlots = (state: IRequestsState, slots: ISlot[]) => {
  const uniqueSlots = ContainerFormService.slotsToUniqueSlots(slots)
  const slotColors = ContainerFormService.slotColors(uniqueSlots)

  return {
    ...state,
    colors: slotColors,
    positions: ContainerFormService.slotsToPositions(slots),
    slots: uniqueSlots,
  }
}

const addEmptyPosition = (state: IRequestsState): IRequestsState => ({
  ...state,
  positions: [...state.positions, null],
})

const emptyPositionAtIndex = (state: IRequestsState, index: number): IRequestsState => {
  const slotToDelete = state.positions.find((_, i) => i === index)
  const isSlotUnique = state.positions.filter((position) => position === slotToDelete).length === 1

  const positions = state.positions.map((position, i) => {
    if (index === i) {
      return null
    }

    return position
  })

  const slots = state.slots
    .map((slot) => {
      // if it's the last slot by identifier, we can safely delete it
      if (slot.config.type === ESlotConfig.IDENTIFIER && isSlotUnique && slot.name === slotToDelete) {
        return null
      }
      return slot
    })
    .filter(Boolean)

  return {
    ...state,
    positions,
    slots,
  }
}

const saveSlotAtIndexes = (
  state: IRequestsState,
  payload: { oldSlot: TBaseSlot | null; newSlot: TBaseSlot; indexes: number[] }
): IRequestsState => {
  const newPositions = () => {
    if (isEqual(state.positions, payload.indexes)) {
      return state.positions
    }

    const positionsWithoutOldAndNewSlot = state.positions.filter((pos) => {
      if (payload.oldSlot) {
        return pos !== payload.oldSlot.name && pos !== payload.newSlot.name
      }
      return pos !== payload.newSlot.name
    })

    return Array.from({ length: payload.indexes.length + positionsWithoutOldAndNewSlot.length }).map((_, i) => {
      return payload.indexes.includes(i) ? payload.newSlot.name : positionsWithoutOldAndNewSlot.shift()
    })
  }

  const newSlots = () => {
    const slots = state.slots

    // is new
    if (!payload.oldSlot) {
      return [...slots, payload.newSlot]
    }

    if (!slots.some((slot) => slot.name === payload.newSlot.name)) {
      return slots.map((slot) => {
        if (slot.name === payload.oldSlot.name) {
          return { ...slot, name: payload.newSlot.name }
        }
        return slot
      })
    }

    return slots.map((slot) => {
      if (slot.name === payload.newSlot.name) {
        return payload.newSlot
      }
      return slot
    })
  }

  return {
    ...state,
    colors: syncColors(state.colors, state.slots, newSlots()),
    positions: newPositions(),
    slots: newSlots(),
  }
}

const insertSlotBeforeIndex = (
  state: IRequestsState,
  payload: { newSlot: TBaseSlot; index: number }
): IRequestsState => {
  const newSlots = () => {
    if (state.slots.some((slot) => slot.name === payload.newSlot.name)) {
      return state.slots
    }

    return [...state.slots, payload.newSlot]
  }

  return {
    ...state,
    colors: syncColors(state.colors, state.slots, newSlots()),
    positions: [
      ...state.positions.slice(0, payload.index),
      payload.newSlot.name,
      ...state.positions.slice(payload.index),
    ],
    slots: newSlots(),
  }
}

const insertSlotsBeforeIndex = (
  state: IRequestsState,
  payload: { newSlots: TBaseSlot[]; index: number }
): IRequestsState => {
  const isInSlots = (slot: TBaseSlot) => {
    return state.slots.some((oldSlot) => oldSlot.name === slot.name)
  }

  const newSlots = () => {
    if (payload.newSlots.every(isInSlots)) {
      return state.slots
    }
    if (payload.newSlots.some(isInSlots)) {
      return [...state.slots, ...payload.newSlots.filter((slot) => !isInSlots(slot))]
    }
    return [...state.slots, ...payload.newSlots]
  }
  return {
    ...state,
    colors: syncColors(state.colors, state.slots, newSlots()),
    positions: [
      ...state.positions.slice(0, payload.index),
      ...payload.newSlots.map((slot) => slot.name),
      ...state.positions.slice(payload.index),
    ],
    slots: newSlots(),
  }
}

const updateSlotAtIndex = (state: IRequestsState, payload: { newSlot: TBaseSlot; index: number }): IRequestsState => {
  const newPositions = () => {
    return Array.from({ length: Math.max(state.positions.length, payload.index + 1) }).map((_, posIndex) => {
      if (posIndex === payload.index) {
        return payload.newSlot.name
      }

      if (state.positions[posIndex]) {
        return state.positions[posIndex]
      }

      return null
    })
  }

  // tslint:disable no-identical-functions
  // eslint-disable-next-line sonarjs/no-identical-functions
  const newSlots = () => {
    if (state.slots.some((slot) => slot.name === payload.newSlot.name)) {
      return state.slots
    }

    return [...state.slots, payload.newSlot]
  }
  // tslint:enable no-identical-functions

  return {
    ...state,
    colors: syncColors(state.colors, state.slots, newSlots()),
    positions: newPositions(),
    slots: newSlots(),
  }
}

const moveSlotFromIndexTo = (
  state: IRequestsState,
  payload: { fromIndex: number; toIndex: number }
): IRequestsState => {
  const positionsCopy = [...state.positions]
  const numAtFromIndex = positionsCopy[payload.fromIndex]

  positionsCopy.splice(payload.fromIndex, 1)
  positionsCopy.splice(payload.toIndex, 0, numAtFromIndex)

  return {
    ...state,
    positions: positionsCopy,
  }
}

const removeSlotByName = (state: IRequestsState, payload: TBaseSlot['name']): IRequestsState => {
  const newSlots = state.slots.filter((slot) => slot.name !== payload)

  return {
    ...state,
    colors: syncColors(state.colors, state.slots, newSlots),
    positions: state.positions.filter((position) => position !== payload),
    slots: newSlots,
  }
}

const removePositionByIndex = (state: IRequestsState, payload: number): IRequestsState => {
  const slotToDelete = state.positions.find((_, i) => i === payload)
  const isSlotUnique = state.positions.filter((position) => position === slotToDelete).length === 1
  const newSlots = state.slots
    // tslint:disable no-identical-functions
    // eslint-disable-next-line sonarjs/no-identical-functions
    .map((slot) => {
      // if it's the last slot by identifier, we can safely delete it
      if (slot.config.type === ESlotConfig.IDENTIFIER && isSlotUnique && slot.name === slotToDelete) {
        return null
      }
      return slot
    })
    // tslint:enable no-identical-functions
    .filter(Boolean)

  return {
    ...state,
    colors: syncColors(state.colors, state.slots, newSlots),
    positions: state.positions.filter((_, i) => i !== payload),
    slots: newSlots,
  }
}

const shufflePositions = (state: IRequestsState): IRequestsState => {
  // we don't want to enter an infinite loop if all positions are the same, exit early
  if (state.positions.length === 0 || state.positions.every((position) => position === state.positions[0])) {
    return state
  }

  let shuffledPositions = shuffle(state.positions)

  // we want to avoid ending up with the same positions, reshuffle until then
  while (isEqual(state.positions, shuffledPositions)) {
    shuffledPositions = shuffle(state.positions)
  }

  return {
    ...state,
    positions: shuffledPositions,
  }
}

const toggleSelectedCard = (state: IRequestsState, payload: number): IRequestsState => {
  return {
    ...state,
    selectedCards: state.selectedCards.includes(payload)
      ? state.selectedCards.filter((index) => index !== payload)
      : [...state.selectedCards, payload],
  }
}

const clearSelectedCards = (state: IRequestsState): IRequestsState => {
  return {
    ...state,
    selectedCards: [],
  }
}

export const requestsReducer = (state: IRequestsState = initialRequestsState, action?: TRequestsReducerAction) => {
  switch (action.type) {
    case RequestsActionTypes.REQUESTS_INIT_SLOTS:
      return initSlots(state, action.payload)
    case RequestsActionTypes.REQUESTS_ADD_EMPTY_POSITION:
      return addEmptyPosition(state)
    case RequestsActionTypes.REQUESTS_EMPTY_POSITION_AT_INDEX:
      return emptyPositionAtIndex(state, action.payload)
    case RequestsActionTypes.REQUESTS_SAVE_SLOT_AT_INDEXES:
      return saveSlotAtIndexes(state, action.payload)
    case RequestsActionTypes.REQUESTS_INSERT_SLOT_BEFORE_INDEX:
      return insertSlotBeforeIndex(state, action.payload)
    case RequestsActionTypes.REQUESTS_INSERT_SLOTS_BEFORE_INDEX:
      return insertSlotsBeforeIndex(state, action.payload)
    case RequestsActionTypes.REQUESTS_UPDATE_SLOT_AT_INDEX:
      return updateSlotAtIndex(state, action.payload)
    case RequestsActionTypes.REQUESTS_MOVE_SLOT_FROM_INDEX_TO:
      return moveSlotFromIndexTo(state, action.payload)
    case RequestsActionTypes.REQUESTS_REMOVE_SLOT_BY_NAME:
      return removeSlotByName(state, action.payload)
    case RequestsActionTypes.REQUESTS_REMOVE_POSITION_BY_INDEX:
      return removePositionByIndex(state, action.payload)
    case RequestsActionTypes.REQUESTS_SHUFFLE_POSITIONS:
      return shufflePositions(state)
    case RequestsActionTypes.REQUESTS_TOGGLE_SELECTED_CARD:
      return toggleSelectedCard(state, action.payload)
    case RequestsActionTypes.REQUESTS_CLEAR_SELECTED_CARD:
      return clearSelectedCards(state)
    default:
      return state
  }
}
