import * as React from 'react'

import LoaderLinear from 'components/loaderlinear/LoaderLinear'

const AsideMenu = React.lazy(() => import('modules/aside-menu'))
import { default as s } from './AppLayout.scss'

interface IAppLayoutOwnProps {
  children: JSX.Element
}

const AppLayout: React.FC<IAppLayoutOwnProps> = ({ children }) => (
  <div id="app-root-container" className={s.root}>
    <React.Suspense fallback={<LoaderLinear />}>
      <div id="app-bar" className={s.appbar}>
        <AsideMenu />
      </div>
      <div id="app-content" className={s.content}>
        {children}
      </div>
    </React.Suspense>
  </div>
)

export default AppLayout
