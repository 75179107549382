import { CreateContainerActionTypes } from './actionTypes'

export declare function FToggleCreationMode(creationMode: boolean): IToggleCreationMode
export declare function FToggleCreationAdvanceMode(creationAdvancedMode: boolean): IToggleCreationAdvanceMode

export interface IToggleCreationMode {
  type: CreateContainerActionTypes.SET_CREATION_MODE
  creationMode: boolean
}

export interface IToggleCreationAdvanceMode {
  type: CreateContainerActionTypes.SET_CREATION_ADVANDED_MODE
  creationAdvancedMode: boolean
}

export type TCreateContainerAvailableActions = IToggleCreationMode | IToggleCreationAdvanceMode

export interface ICreateContainerActions {
  toggleCreationMode: typeof FToggleCreationMode
  toggleCreationAdvandeMode: typeof FToggleCreationAdvanceMode
}

export const toggleCreationMode = (creationMode: boolean): IToggleCreationMode => ({
  type: CreateContainerActionTypes.SET_CREATION_MODE,
  creationMode,
})

export const toggleCreationAdvandeMode = (creationAdvancedMode: boolean): IToggleCreationAdvanceMode => ({
  type: CreateContainerActionTypes.SET_CREATION_ADVANDED_MODE,
  creationAdvancedMode,
})

export const CreateContainerActions: ICreateContainerActions = {
  toggleCreationMode,
  toggleCreationAdvandeMode,
}
