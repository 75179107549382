import IContent, { IContentWithContext } from 'models/detail/content/Content'
import { IStandardFeedCreatePayloadParams } from 'models/detail/content/ContentCreate'
import { EContext } from 'models/detail/Enumeration'
import AxiosContentUIService from 'services/AxiosContentUIService'

const withContext = (content: IContent): IContentWithContext => ({
  ...content,
  __context: EContext.CONTENT,
})

export const createStandardFeedApi = async (params: IStandardFeedCreatePayloadParams): Promise<IContent> =>
  AxiosContentUIService.Instance.post(`/feed`, params.data).then((response) => withContext(response.data))
