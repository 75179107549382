import ApiFlowManagerDetail from 'models/apiflowmanager/ApiFlowManagerDetail'
import { EApiFlowManagerPresentationType, EApiFlowManagerType } from 'models/apiflowmanager/Enumeration'
import { TContainer } from 'models/container/container'
import { TContent } from 'models/content/content'
import { ContainerActionTypes } from 'redux/actions/container'
import { ContentActionTypes } from 'redux/actions/content'

import { IContainerDetailState, IContentDetailState, TDetailReducerAction, TDetailState } from './detail.d'

export const initialDetailState: TDetailState = {
  container: undefined,
  content: undefined,
  showUploadButton: undefined,
  apiFlowManagerDetail: undefined,
}

const setDetailLoading = (state: TDetailState): TDetailState => ({
  ...state,
  apiFlowManagerDetail: new ApiFlowManagerDetail(EApiFlowManagerType.LOADER, EApiFlowManagerPresentationType.GLOBAL),
})

const setUploadImage = (state: TDetailState): TDetailState => ({
  ...state,
  showUploadButton: true,
})

const clearUploadImageUpload = (state: TDetailState): TDetailState => ({
  ...state,
  showUploadButton: undefined,
})

const setContentAssociationsSuccess = (state: TDetailState): TDetailState => ({
  ...state,
  apiFlowManagerDetail: undefined,
})

const setContentSuccess = (state: TDetailState, payload: TContent): IContentDetailState => ({
  ...state,
  container: undefined,
  content: payload,
  apiFlowManagerDetail: undefined,
})

const setContainerSuccess = (state: TDetailState, payload: TContainer): IContainerDetailState => ({
  ...state,
  container: payload,
  content: undefined,
  apiFlowManagerDetail: undefined,
})

const setDetailFailure = (state: TDetailState, payload: ApiFlowManagerDetail): TDetailState => ({
  ...state,
  apiFlowManagerDetail: payload,
})

export const detailReducer = (state: TDetailState = initialDetailState, action: TDetailReducerAction) => {
  switch (action.type) {
    case ContentActionTypes.FETCH_CONTENT:
    case ContentActionTypes.UPDATE_CONTENT:
    case ContentActionTypes.UPDATE_CONTENT_ASSOCIATIONS:
    case ContentActionTypes.DELETE_CONTENT_ASSOCIATIONS:
    case ContentActionTypes.UPDATE_CONTENT_WITH_IMAGE:
    case ContentActionTypes.CREATE_CONTENT:
    case ContentActionTypes.DELETE_CONTENT:
    case ContainerActionTypes.FETCH_CONTAINER:
    case ContainerActionTypes.UPDATE_CONTAINER:
    case ContainerActionTypes.CREATE_CONTAINER:
    case ContainerActionTypes.DELETE_CONTAINER:
    case ContentActionTypes.CREATE_FEED_STANDARD:
      return setDetailLoading(state)
    case ContentActionTypes.UPDATE_CONTENT_ASSOCIATIONS_SUCCESS:
    case ContentActionTypes.DELETE_CONTENT_ASSOCIATIONS_SUCCESS:
      return setContentAssociationsSuccess(state)
    case ContentActionTypes.FETCH_CONTENT_SUCCESS:
    case ContentActionTypes.UPDATE_CONTENT_SUCCESS:
    case ContentActionTypes.CREATE_CONTENT_SUCCESS:
    case ContentActionTypes.CREATE_FEED_STANDARD_SUCCESS:
      return setContentSuccess(state, action.payload)
    case ContainerActionTypes.FETCH_CONTAINER_SUCCESS:
    case ContainerActionTypes.UPDATE_CONTAINER_SUCCESS:
    case ContainerActionTypes.CREATE_CONTAINER_SUCCESS:
      return setContainerSuccess(state, action.payload)
    case ContentActionTypes.FETCH_CONTENT_FAILURE:
    case ContentActionTypes.UPDATE_CONTENT_FAILURE:
    case ContentActionTypes.CREATE_CONTENT_FAILURE:
    case ContentActionTypes.DELETE_CONTENT_FAILURE:
    case ContentActionTypes.UPDATE_CONTENT_ASSOCIATIONS_FAILURE:
    case ContentActionTypes.DELETE_CONTENT_ASSOCIATIONS_FAILURE:
    case ContainerActionTypes.FETCH_CONTAINER_FAILURE:
    case ContainerActionTypes.UPDATE_CONTAINER_FAILURE:
    case ContainerActionTypes.CREATE_CONTAINER_FAILURE:
    case ContentActionTypes.CREATE_FEED_STANDARD_FAILURE:
    case ContainerActionTypes.DELETE_CONTAINER_FAILURE:
      return setDetailFailure(state, action.payload)
    case ContentActionTypes.UPLOAD_IMAGE:
      return setUploadImage(state)
    case ContentActionTypes.UPLOAD_IMAGE_SUCCESS:
      return clearUploadImageUpload(state)
    case ContentActionTypes.DELETE_CONTENT_SUCCESS:
    case ContainerActionTypes.DELETE_CONTAINER_SUCCESS:
      return initialDetailState
    default:
      return state
  }
}
