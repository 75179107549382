import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  DragDropContext as BeautifulDndContext,
  DraggableLocation,
  DragStart,
  DragUpdate,
  DropResult,
} from '@hello-pangea/dnd'

import useIntermediateFormik from 'hooks/useIntermediateFormik'
import { useRouter } from 'hooks/useRouter'
import { ECardType } from 'models/card/CardType'
import { TPfuCard } from 'models/card/GenericCard'
import { TBaseSlot } from 'models/container/container'
import { EContainerState, ESlotConfig } from 'models/container/enumeration'
import { EContentState, EContentType, EContentTypology, ERecordType } from 'models/content/enumeration'
import { EDroppableIdentifier } from 'models/dragndrop/DragAndDrop'
import { insertSlotBeforeIndex, insertSlotsBeforeIndex, moveSlotFromIndexTo } from 'redux/actions/requests'
import ContainerFormService from 'services/ContainerFormService'
import { IStoreState } from 'types/common'

interface IDuplicateState {
  slots: TBaseSlot[]
  index: number
  destinationId: EDroppableIdentifier
  isOpen: boolean
}

export interface IDragAndDropContextInterface {
  handleDragStart: (dragStart: DragStart) => void
  handleDragEnd: (dropResult: DropResult) => void
  handleDragUpdate: (initial: DragUpdate) => void
  dragging: TPfuCard
  dragDestination: EDroppableIdentifier
  isDragValid: boolean
  isDragDuplicate: boolean
  duplicate: {
    state: IDuplicateState
    confirm: () => void
    cancel: () => void
  }
}

export const DragAndDropContext = React.createContext<IDragAndDropContextInterface | null>(null)

function isError(card: TPfuCard) {
  if (card.cardType === ECardType.CONTENT) {
    return card.status === EContentState.ERROR
  }

  if (card.cardType === ECardType.CONTAINER) {
    return card.status === EContainerState.ERROR
  }

  return false
}

function isContent(card: TPfuCard) {
  return card.cardType !== ECardType.CONTAINER
}

function slotsSizeLessThen(slots: TBaseSlot[], maxSize: number) {
  return slots.length < maxSize
}

function isIncompleteContent(card: TPfuCard) {
  return card.cardType === ECardType.CONTENT && card.status === EContentState.INCOMPLETE
}

function isDuplicates(slots: TBaseSlot[], cards: TPfuCard[]) {
  if (slots.length === 0) {
    return false
  }
  return slots.some((slot) => {
    return cards.some((card) => {
      return slot.config.type === ESlotConfig.IDENTIFIER
        ? slot.config.identifier === card.id
        : slot.name.substring(0, slot.name.length - 14) === card.name
    })
  })
}

function isVideoPreview(card: TPfuCard) {
  return card.recordType === ERecordType.VIDEO_PREVIEW
}

function isSponsor(card: TPfuCard) {
  return card.recordType === ERecordType.SPONSOR
}

function isTemplate(card: TPfuCard) {
  return card.recordType === ERecordType.TEMPLATE
}

function validateDrag({
  source,
  destination,
  card,
  currentId,
  rules = [],
}: {
  source: EDroppableIdentifier
  destination: EDroppableIdentifier
  card: TPfuCard
  currentId: string
  rules?: boolean[]
}) {
  if (isError(card)) {
    return false
  }

  if (card.id === currentId) {
    return false
  }

  if (source === destination) {
    return true
  }

  if (rules.length === 0) {
    return true
  }

  return rules.every(Boolean)
}

export const DragAndDropProvider = ({ children }: { children: React.ReactNode }) => {
  const [duplicate, setDuplicate] = React.useState<IDuplicateState>({
    slots: [],
    index: null,
    destinationId: null,
    isOpen: false,
  })
  const [dragging, setDragging] = React.useState<TPfuCard>(null)
  const [dragDestination, setDragDestination] = React.useState<EDroppableIdentifier>(null)
  const [dragIsValid, setDragIsValid] = React.useState<boolean | null>(null)
  const [dragIsDuplicate, setDragIsDuplicate] = React.useState<boolean | null>(false)
  const { cards, slots, selectedCards } = useSelector((state: IStoreState) => ({
    cards: state.search.cards,
    slots: state.requests.slots,
    selectedCards: state.requests.selectedCards,
  }))

  const dispatch = useDispatch()
  const router = useRouter()

  const { setChanged } = useIntermediateFormik()

  function clearDrag() {
    setDragging(null)
    setDragDestination(null)
    setDragIsValid(null)
    setDragIsDuplicate(null)
  }

  function confirmDuplicate() {
    if (duplicate.destinationId === EDroppableIdentifier.REQUEST_LIST && duplicate.slots.length === 1) {
      dispatch(insertSlotBeforeIndex(duplicate.slots[0], duplicate.index))
    }
    if (duplicate.destinationId === EDroppableIdentifier.REQUEST_LIST && duplicate.slots.length > 1) {
      dispatch(insertSlotsBeforeIndex(duplicate.slots, duplicate.index))
    }
    setDuplicate({ slots: [], index: null, destinationId: null, isOpen: false })
  }

  function cancelDuplicate() {
    setDuplicate({ slots: [], index: null, destinationId: null, isOpen: false })
  }

  function handleCheckIsValid(isValid: boolean, destinationId: EDroppableIdentifier, card: TPfuCard, index?: number) {
    if (isValid) {
      setChanged({
        destinationId,
        card,
        index,
      })
    }
  }

  /* eslint-disable sonarjs/cognitive-complexity */

  /* tslint:disable cognitive-complexity */
  function handleDrag({
    source,
    destination,
    draggableId,
  }: {
    source: DraggableLocation
    destination: DraggableLocation
    draggableId?: string
  }): {
    isDragValid: boolean
    isDragDuplicate?: boolean
    onUpdate?: () => void
    onEnd: () => void
  } {
    const sourceId = source.droppableId
    const destinationId = destination.droppableId
    const card = cards[source.index]

    const isMultiple = selectedCards.length > 1 && selectedCards.includes(source.index)

    if (
      sourceId === EDroppableIdentifier.CONTENT_LIST &&
      (destinationId === EDroppableIdentifier.POSTER_LINKED_CONTENT_CREATE ||
        destinationId === EDroppableIdentifier.POSTER_LINKED_CONTENT_EDIT ||
        destinationId === EDroppableIdentifier.FEED ||
        destinationId === EDroppableIdentifier.RELATIONSHIP_LIST) &&
      !isMultiple
    ) {
      const isValid = validateDrag({
        source: sourceId,
        destination: destinationId,
        card,
        currentId: router.current.detailId,
        rules: [isContent(card), !isTemplate(card), !isIncompleteContent(card)],
      })

      return {
        isDragValid: isValid,
        onEnd: () => {
          handleCheckIsValid(isValid, destinationId, card, destination.index)
        },
      }
    }

    if (sourceId === EDroppableIdentifier.SPONSOR_LIST && destinationId === EDroppableIdentifier.SPONSOR_LIST) {
      return {
        isDragValid: true,
        onEnd: () => {
          dispatch(moveSlotFromIndexTo(source.index, destination.index))
        },
      }
    }

    if (
      sourceId === EDroppableIdentifier.CONTENT_LIST &&
      (destinationId === EDroppableIdentifier.CAROUSEL_LINKED_CONTENT_CREATE ||
        destinationId === EDroppableIdentifier.CAROUSEL_LINKED_CONTENT_EDIT) &&
      !isMultiple
    ) {
      let isValid = true

      if (card.type === EContentType.POSTER_CONTENT) {
        isValid = false
      } else {
        isValid = validateDrag({
          source: sourceId,
          destination: destinationId,
          card,
          currentId: router.current.detailId,
          rules: [isContent(card), !isTemplate(card), !isIncompleteContent(card)],
        })
      }

      return {
        isDragValid: isValid,
        onEnd: () => {
          handleCheckIsValid(isValid, destinationId, card, destination.index)
        },
      }
    }
    if (
      sourceId === EDroppableIdentifier.CONTENT_LIST &&
      destinationId === EDroppableIdentifier.PARENT_REFERENCE_CREATE &&
      !isMultiple
    ) {
      let isValid = false
      if (
        [
          EContentTypology.PAGE,
          EContentTypology.CUSTOM_PAGE,
          EContentTypology.PAGE_SPECIAL,
          EContentTypology.PAGE_GENRE,
          EContentTypology.PAGE_EVENT,
          EContentTypology.PAGE_HOME,
          EContentTypology.FEED,
          EContentTypology.CAROUSEL,
          EContentTypology.CHANNEL,
          EContentTypology.VIDEO_SHOW,
          EContentTypology.VIDEO_SEASON,
          EContentTypology.AUDIO_SHOW,
          EContentTypology.AUDIO_SEASON,
        ].some((type) => type === card.typology)
      ) {
        isValid = validateDrag({
          source: sourceId,
          destination: destinationId,
          card,
          currentId: router.current.detailId,
          rules: [isContent(card), !isTemplate(card)],
        })
      }

      return {
        isDragValid: isValid,
        onEnd: () => {
          handleCheckIsValid(isValid, destinationId, card, destination.index)
        },
      }
    }

    if (
      sourceId === EDroppableIdentifier.CONTENT_LIST &&
      (destinationId === EDroppableIdentifier.VIDEO_PREVIEW_HORIZONTAL ||
        destinationId === EDroppableIdentifier.VIDEO_PREVIEW_VERTICAL) &&
      !isMultiple
    ) {
      const isValid = validateDrag({
        source: sourceId,
        destination: destinationId,
        card,
        currentId: router.current.detailId,
        rules: [isContent(card), isVideoPreview(card), !isTemplate(card), !isIncompleteContent(card)],
      })

      return {
        isDragValid: isValid,
        onEnd: () => {
          handleCheckIsValid(isValid, destinationId, card)
        },
      }
    }

    if (
      sourceId === EDroppableIdentifier.CONTENT_LIST &&
      destinationId === EDroppableIdentifier.RELATIONSHIP_LIST &&
      isMultiple
    ) {
      const filterCards = cards.filter((_, index) => selectedCards.includes(index))
      const filterSlots = filterCards.map((card) => ({
        data: card,
        isValid: validateDrag({
          source: sourceId,
          destination: destinationId,
          card,
          currentId: router.current.detailId,
          rules: [isContent(card), !isTemplate(card), !isIncompleteContent(card)],
        }),
      }))

      const allSlotsAreValid = filterSlots.every((cur) => cur.isValid)

      return {
        isDragValid: allSlotsAreValid,
        onEnd: () => {
          const filtered = filterSlots.filter((slot) => slot.isValid)

          if (allSlotsAreValid) {
            setChanged(filterSlots.map((slot) => slot.data))
          } else if (filtered.length) {
            setChanged(filtered.map((slot) => slot.data))
          }
        },
      }
    }

    if (
      sourceId === EDroppableIdentifier.CONTENT_LIST &&
      destinationId === EDroppableIdentifier.REQUEST_LIST &&
      isMultiple
    ) {
      const filterCards = cards.filter((_, index) => selectedCards.includes(index))
      const filterSlots = filterCards.map((card) => ({
        data: ContainerFormService.cardToSlot(card),
        isValid: validateDrag({
          source: sourceId,
          destination: destinationId,
          card,
          currentId: router.current.detailId,
          rules: [!isTemplate(card), !isIncompleteContent(card)],
        }),
      }))

      const allSlotsAreValid = filterSlots.every((cur) => cur.isValid)

      return {
        isDragValid: allSlotsAreValid,
        isDragDuplicate: isDuplicates(slots, filterCards),
        onEnd: () => {
          if (allSlotsAreValid) {
            if (isDuplicates(slots, filterCards)) {
              setDuplicate({
                slots: filterSlots.map((slot) => slot.data),
                index: destination.index,
                destinationId,
                isOpen: true,
              })
            } else {
              dispatch(
                insertSlotsBeforeIndex(
                  filterSlots.map((slot) => slot.data),
                  destination.index
                )
              )
            }
          } else {
            const filtered = filterSlots.filter((slot) => slot.isValid)
            if (filtered.length) {
              if (isDuplicates(slots, filterCards)) {
                setDuplicate({
                  slots: filtered.map((slot) => slot.data),
                  index: destination.index,
                  destinationId,
                  isOpen: true,
                })
              } else {
                dispatch(
                  insertSlotsBeforeIndex(
                    filtered.map((slot) => slot.data),
                    destination.index
                  )
                )
              }
            }
          }
        },
      }
    }

    if (
      sourceId === EDroppableIdentifier.CONTENT_LIST &&
      (destinationId === EDroppableIdentifier.REQUEST_LIST || destinationId === EDroppableIdentifier.SPONSOR_LIST)
    ) {
      const card = cards[source.index]
      const slot = ContainerFormService.cardToSlot(card)

      const validationRules =
        destinationId === EDroppableIdentifier.REQUEST_LIST
          ? [!isTemplate(card), !isIncompleteContent(card)]
          : [isSponsor(card), slotsSizeLessThen(slots, 5), !isDuplicates(slots, [card]), !isIncompleteContent(card)]

      const isValid = validateDrag({
        source: sourceId,
        destination: destinationId,
        card,
        currentId: router.current.detailId,
        rules: validationRules,
      })

      return {
        isDragValid: isValid,
        isDragDuplicate: isDuplicates(slots, [card]),
        onEnd: () => {
          if (isValid) {
            if (isDuplicates(slots, [card])) {
              setDuplicate({ slots: [slot], index: destination.index, destinationId, isOpen: true })
            } else {
              dispatch(insertSlotBeforeIndex(slot, destination.index))
            }
          }
        },
      }
    }

    if (sourceId === EDroppableIdentifier.CONTENT_LIST && destinationId === EDroppableIdentifier.MENU && !isMultiple) {
      let isValid = true

      if (card.type !== EContentType.MENU) {
        isValid = false
      } else {
        isValid = validateDrag({
          source: sourceId,
          destination: destinationId,
          card,
          currentId: router.current.detailId,
          rules: [isContent(card), !isTemplate(card), !isIncompleteContent(card)],
        })
      }

      return {
        isDragValid: isValid,
        onEnd: () => {
          handleCheckIsValid(isValid, destinationId, card, destination.index)
        },
      }
    }

    if (sourceId === EDroppableIdentifier.FILTERED_LIST && destinationId === EDroppableIdentifier.REQUEST_LIST) {
      return {
        isDragValid: true,
        onEnd: () => {
          const slotName = draggableId.replace(/^(\w*)-(\d*)-(.*)$/, '$3')
          const slot = slots.find((s) => s.name === slotName)
          dispatch(insertSlotBeforeIndex(slot, destination.index))
        },
      }
    }

    if (sourceId === EDroppableIdentifier.REQUEST_LIST && destinationId === EDroppableIdentifier.REQUEST_LIST) {
      return {
        isDragValid: true,
        onEnd: () => {
          dispatch(moveSlotFromIndexTo(source.index, destination.index))
        },
      }
    }

    if (
      (sourceId === EDroppableIdentifier.FEED && destinationId === EDroppableIdentifier.FEED) ||
      (sourceId === EDroppableIdentifier.PAGE && destinationId === EDroppableIdentifier.PAGE)
    ) {
      return {
        isDragValid: true,
        onEnd: () => {
          setChanged({
            destinationId,
            sourceId,
            sourceIndex: source.index,
            destinationIndex: destination.index,
          })
        },
      }
    }

    return undefined
  }

  /* eslint-enable sonarjs/cognitive-complexity */

  /* tslint:enable cognitive-complexity */

  function handleDragStart(dragStart: DragStart) {
    const source = dragStart.source.droppableId

    // TODO: handle all sources?
    if (source === EDroppableIdentifier.CONTENT_LIST) {
      const card = cards[dragStart.source.index]
      setDragging(card)
    }
  }

  function handleDragEnd(dropResult: DropResult) {
    clearDrag()

    // no destination, return early
    if (!dropResult.destination || dropResult.reason === 'CANCEL') {
      return
    }

    const { onEnd } = handleDrag({
      source: dropResult.source,
      destination: dropResult.destination,
      draggableId: dropResult.draggableId,
    })

    onEnd()
  }

  function handleDragUpdate(initial: DragUpdate) {
    if (!initial.destination) {
      setDragIsValid(null)
      return
    }

    const { isDragValid, isDragDuplicate } = handleDrag({ source: initial.source, destination: initial.destination })

    setDragDestination(initial.destination.droppableId as EDroppableIdentifier)
    setDragIsValid(isDragValid)
    setDragIsDuplicate(isDragDuplicate)
  }

  const memoizedContextValue = React.useMemo(
    () => ({
      handleDragStart,
      handleDragEnd,
      handleDragUpdate,
      dragging,
      dragDestination,
      isDragValid: dragIsValid,
      isDragDuplicate: dragIsDuplicate,
      duplicate: {
        state: duplicate,
        confirm: confirmDuplicate,
        cancel: cancelDuplicate,
      },
    }),
    [
      handleDragStart,
      handleDragEnd,
      handleDragUpdate,
      dragDestination,
      dragging,
      dragIsValid,
      dragIsDuplicate,
      duplicate,
      confirmDuplicate,
    ]
  )

  return (
    <DragAndDropContext.Provider value={memoizedContextValue}>
      <BeautifulDndContext onDragStart={handleDragStart} onDragEnd={handleDragEnd} onDragUpdate={handleDragUpdate}>
        {children}
      </BeautifulDndContext>
    </DragAndDropContext.Provider>
  )
}
