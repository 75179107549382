import ApiFlowManagerDetail from 'models/apiflowmanager/ApiFlowManagerDetail'
import { EApiFlowManagerPresentationType, EApiFlowManagerType } from 'models/apiflowmanager/Enumeration'
import { IPermission } from 'models/permission/Permission'
import { PermissionActionTypes } from 'redux/actions/permission'
import { TPermissionAction } from 'redux/actions/permission'

import { IPermissionState } from './permission'

export const initialPermissionState: IPermissionState = {
  allPermissions: [],
  apiFlowManagerDetail: undefined,
}

const setPermissionLoading = (state: IPermissionState) => ({
  ...state,
  apiFlowManagerDetail: new ApiFlowManagerDetail(EApiFlowManagerType.LOADER, EApiFlowManagerPresentationType.BANNER),
})

const setAllPermissions = (state: IPermissionState, permissions: IPermission[]): IPermissionState => ({
  ...state,
  allPermissions: permissions,
  apiFlowManagerDetail: undefined,
})

const setPermissionFailure = (state: IPermissionState, apiFlow: ApiFlowManagerDetail) => ({
  ...state,
  apiFlowManagerDetail: apiFlow,
})

export const permissionReducer = (state: IPermissionState = initialPermissionState, action: TPermissionAction) => {
  switch (action.type) {
    case PermissionActionTypes.FETCH_ALL_PERMISSIONS:
      return setPermissionLoading(state)
    case PermissionActionTypes.FETCH_ALL_PERMISSIONS_SUCCESS:
      return setAllPermissions(state, action.payload)
    case PermissionActionTypes.FETCH_ALL_PERMISSIONS_FAILURE:
      return setPermissionFailure(state, action.payload)
    default:
      return state
  }
}
