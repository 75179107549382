import { EContainerSubTypePage, EContainerType, EContainerTypology } from 'models/container/enumeration'
import { EPageSubTypology } from 'models/content/enumeration'

import { IContainerContentCreateForm } from './ContainerPageCreateForm'

export default class ContainerPageCreateDTO {
  objectType: 'ContainerCreate'
  type: EContainerType
  typology: EContainerTypology.PAGE
  subTypology: EPageSubTypology

  constructor(
    public name = '',
    public publicTitle = '',
    public alias = '',
    public aliasLink = '',
    public subType: EContainerSubTypePage,
    public content: IContainerContentCreateForm = { type: '', title: '', imageUrl: null }
  ) {
    this.objectType = 'ContainerCreate'
    this.type = EContainerType.PAGE
    this.typology = EContainerTypology.PAGE
    this.subTypology = EPageSubTypology[subType as keyof typeof EPageSubTypology]
    this.subType = subType
  }
}
