import { IListOption } from 'components/autocompleteselect/AutoCompleteSelect'
import ApiFlowManagerDetail from 'models/apiflowmanager/ApiFlowManagerDetail'

import {
  TFetchAudioShowsListAction,
  TFetchAudioShowsListFailureAction,
  TFetchAudioShowsListSuccessAction,
  TFetchShowsListAction,
  TFetchShowsListFailureAction,
  TFetchShowsListSuccessAction,
} from './select'
import { SelectActionTypes } from './select.action.types'

export const fetchShowsList = (value: IListOption[]): TFetchShowsListAction => ({
  type: SelectActionTypes.FETCH_VIDEO_SHOWS_LIST,
  payload: value,
})

export const fetchShowsListSuccess = (showsList: IListOption[]): TFetchShowsListSuccessAction => ({
  type: SelectActionTypes.FETCH_VIDEO_SHOWS_LIST_SUCCESS,
  payload: showsList,
})

export const fetchShowsListFailure = (apiFlowManagerDetail: ApiFlowManagerDetail): TFetchShowsListFailureAction => ({
  type: SelectActionTypes.FETCH_VIDEO_SHOWS_LIST_FAILURE,
  payload: apiFlowManagerDetail,
})

export const fetchAudioShowsList = (value: IListOption[]): TFetchAudioShowsListAction => ({
  type: SelectActionTypes.FETCH_AUDIO_SHOWS_LIST,
  payload: value,
})

export const fetchAudioShowsListSuccess = (showsList: IListOption[]): TFetchAudioShowsListSuccessAction => ({
  type: SelectActionTypes.FETCH_AUDIO_SHOWS_LIST_SUCCESS,
  payload: showsList,
})

export const fetchAudioShowsListFailure = (
  apiFlowManagerDetail: ApiFlowManagerDetail
): TFetchAudioShowsListFailureAction => ({
  type: SelectActionTypes.FETCH_AUDIO_SHOWS_LIST_FAILURE,
  payload: apiFlowManagerDetail,
})
