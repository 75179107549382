import * as React from 'react'

import LinearProgress from '@mui/material/LinearProgress'

import 'assets/css/variables.scss'
import { default as s } from './LoaderLinear.scss'

const LoaderLinear = () => <LinearProgress className={s.loader} />

export default LoaderLinear
