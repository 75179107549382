import { EContainerType, EContainerTypology } from 'models/container/enumeration'

import { ISlotNoResolvedItem } from '../CreateSlot'

export default class ContainerDefaultCreateDTO {
  objectType: 'ContainerCreate'
  type: EContainerType.CAROUSEL
  typology: EContainerTypology.CAROUSEL

  constructor(
    public name = '',
    public publicTitle = '',
    public alias = '',
    public subType = '',
    public subTypology = '',
    public mosaicRowsXS?: number,
    public mosaicRowsS?: number,
    public mosaicRowsL?: number,
    public slots?: ISlotNoResolvedItem[]
  ) {
    this.objectType = 'ContainerCreate'
    this.type = EContainerType.CAROUSEL
    this.typology = EContainerTypology.CAROUSEL
  }
}
