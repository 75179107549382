import { all, call, fork, put, takeLatest } from 'redux-saga/effects'

import { fetchTaxonomyApi } from 'api/Taxonomy'
import { ITaxonomy } from 'models/taxonomy/Taxonomy'
import { apiCallErrorHandling } from 'services/apicallsagaservice/apiCallErrorHandling'

import { fetchTaxonomiesSuccess, fetchTaxonomiesFailure } from './taxonomy.action'
import { ETaxonomyActionTypes } from './taxonomy.action.types'

export function* fetchTaxonomiesAsync() {
  try {
    const taxonomies: ITaxonomy[] = yield call(fetchTaxonomyApi)
    yield put(fetchTaxonomiesSuccess(taxonomies))
  } catch (error) {
    yield put(fetchTaxonomiesFailure(apiCallErrorHandling(error)))
  }
}

function* watchFetchTaxonomiesAsync() {
  yield takeLatest(ETaxonomyActionTypes.FETCH_TAXONOMIES, fetchTaxonomiesAsync)
}

export function* rootTaxonomySaga() {
  yield all([fork(watchFetchTaxonomiesAsync)])
}
