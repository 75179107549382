import * as React from 'react'

import ButtonIcon, { EButtonIcon } from 'components/buttonicon/ButtonIcon'
import Buttons, { EVariant } from 'components/buttons/Buttons'

import error from './assets/error.svg'
import s from './Errors.scss'

export enum EErrors {
  BANNER = 'Banner',
  FULL = 'Full',
}

export interface IErrorsProps {
  /* Status de la chip */
  type: EErrors | null
  /* Message d'erreur */
  errorMessage: string
  /* Description du message d'erreur */
  errorMessageDetails?: string
  /* Libélé du bouton rafraichir */
  reloadLabel?: string
  /** Function to execute when reload button is clicked */
  actionHandler?: (val?: number | string) => void // needs function - cannot type
  /** Label used mainly for accessibility */
  btnLabel: string
}

const Errors = ({ type, errorMessage, errorMessageDetails, reloadLabel, actionHandler, btnLabel }: IErrorsProps) => {
  const onclick = () => {
    return actionHandler ? actionHandler() : undefined
  }

  let errorClassName
  let displayIcon
  let reloadButton
  if (EErrors.FULL === type) {
    errorClassName = s.containerFull
    displayIcon = true
    reloadButton = (
      <Buttons disabled={false} variant={EVariant.CONTAINED} onClick={onclick} dataQa="error-retry-button">
        {reloadLabel}
      </Buttons>
    )
  } else {
    errorClassName = s.containerBanner
    displayIcon = false
    reloadButton = <ButtonIcon btnIcon={EButtonIcon.REFRESH} onClick={onclick} btnLabel={btnLabel} />
  }

  return (
    <div className={errorClassName} data-qa="errorContainer">
      <div data-qa="error">
        {displayIcon && <img src={error} alt="image" aria-hidden="true" />}
        <span className={s.errorMsg}>{errorMessage}</span>
        <span className={s.errorMsgDetails}>{errorMessageDetails}</span>
        {typeof actionHandler === 'function' && reloadButton}
      </div>
    </div>
  )
}

export default Errors
