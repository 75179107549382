import ApiFlowManagerDetail from 'models/apiflowmanager/ApiFlowManagerDetail'
import { ICards } from 'models/card/GenericCard'
import { EContentOwner } from 'models/content/enumeration'
import { ICategory } from 'models/taxonomy/Taxonomy'
import { IShow, ITag } from 'modules/search/sub-modules/search-service/search-service.def'

import { SearchPfuActionTypes } from './search-pfu.action.types'
import {
  TFetchTagsAction,
  TFetchTagsSuccessAction,
  TFetchTagsFailureAction,
  TSearchPfuCatalogAction,
  TSearchPfuCatalogSuccessAction,
  TSearchPfuCatalogFailureAction,
  TSearchPfuHistoryAction,
  TFetchShowsAction,
  TFetchShowsSuccessAction,
  TFetchShowsFailureAction,
  TFetchAudioShowsSuccessAction,
  TFetchAudioShowsFailureAction,
  TFetchAudioShowsAction,
  TFetchInterestsAction,
  TFetchInterestsSuccessAction,
  TFetchInterestsFailureAction,
  ISearchPayloadData,
} from './search-pfu.d'

export const fetchTags = (value: string): TFetchTagsAction => ({
  type: SearchPfuActionTypes.FETCH_TAGS,
  payload: value,
})

export const fetchTagsSuccess = (tags: ITag[]): TFetchTagsSuccessAction => ({
  type: SearchPfuActionTypes.FETCH_TAGS_SUCCESS,
  payload: tags,
})

export const fetchTagsFailure = (apiFlowManagerDetail: ApiFlowManagerDetail): TFetchTagsFailureAction => ({
  type: SearchPfuActionTypes.FETCH_TAGS_FAILURE,
  payload: apiFlowManagerDetail,
})

export const fetchInterests = (data: ISearchPayloadData): TFetchInterestsAction => ({
  type: SearchPfuActionTypes.FETCH_INTERESTS,
  payload: data,
})

export const fetchInterestsSuccess = (interests: ICategory[]): TFetchInterestsSuccessAction => ({
  type: SearchPfuActionTypes.FETCH_INTERESTS_SUCCESS,
  payload: interests,
})

export const fetchInterestsFailure = (apiFlowManagerDetail: ApiFlowManagerDetail): TFetchInterestsFailureAction => ({
  type: SearchPfuActionTypes.FETCH_INTERESTS_FAILURE,
  payload: apiFlowManagerDetail,
})

export const fetchShows = (value: string, owner?: EContentOwner): TFetchShowsAction => ({
  type: SearchPfuActionTypes.FETCH_VIDEO_SHOWS,
  payload: { search: value, owner },
})

export const fetchAudioShows = (value: string): TFetchAudioShowsAction => ({
  type: SearchPfuActionTypes.FETCH_AUDIO_SHOWS,
  payload: value,
})

export const fetchShowsSuccess = (shows: IShow[]): TFetchShowsSuccessAction => ({
  type: SearchPfuActionTypes.FETCH_VIDEO_SHOWS_SUCCESS,
  payload: shows,
})

export const fetchAudioShowsSuccess = (audioShows: IShow[]): TFetchAudioShowsSuccessAction => ({
  type: SearchPfuActionTypes.FETCH_AUDIO_SHOWS_SUCCESS,
  payload: audioShows,
})

export const fetchShowsFailure = (apiFlowManagerDetail: ApiFlowManagerDetail): TFetchShowsFailureAction => ({
  type: SearchPfuActionTypes.FETCH_VIDEO_SHOWS_FAILURE,
  payload: apiFlowManagerDetail,
})

export const fetchAudioShowsFailure = (apiFlowManagerDetail: ApiFlowManagerDetail): TFetchAudioShowsFailureAction => ({
  type: SearchPfuActionTypes.FETCH_AUDIO_SHOWS_FAILURE,
  payload: apiFlowManagerDetail,
})

export const searchPfuCatalog = (offset: number): TSearchPfuCatalogAction => ({
  type: SearchPfuActionTypes.SEARCH_PFU_CATALOG,
  payload: offset,
})

export const searchPfuCatalogSuccess = (cards: ICards): TSearchPfuCatalogSuccessAction => ({
  type: SearchPfuActionTypes.SEARCH_PFU_CATALOG_SUCCESS,
  payload: cards,
})

export const searchPfuCatalogFailure = (
  apiFlowManagerDetail: ApiFlowManagerDetail
): TSearchPfuCatalogFailureAction => ({
  type: SearchPfuActionTypes.SEARCH_PFU_CATALOG_FAILURE,
  payload: apiFlowManagerDetail,
})

export const searchPfuHistory = (cards: ICards): TSearchPfuHistoryAction => ({
  type: SearchPfuActionTypes.SEARCH_PFU_HISTORY,
  payload: cards,
})
