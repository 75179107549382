import * as React from 'react'

import { PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { createRoot } from 'react-dom/client'

import AxiosContentUIService from 'services/AxiosContentUIService'
import AxiosPermissionService from 'services/AxiosPermissionService'
import AxiosSearchService from 'services/AxiosSearchService'
import { msalConfig } from 'utils/authConfig'

import AuthManager from './AuthManager'

const msalInstance = new PublicClientApplication(msalConfig)

const AppProvider = () => {
  AxiosContentUIService.initializeAxios(msalInstance)
  AxiosPermissionService.initializeAxios(msalInstance)
  AxiosSearchService.initializeAxios(msalInstance)

  return (
    <MsalProvider instance={msalInstance}>
      <AuthManager />
    </MsalProvider>
  )
}

const root = createRoot(document.getElementById('root'))
root.render(<AppProvider />)
