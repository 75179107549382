import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser'
import { TokenClaims } from '@azure/msal-common'
import { InternalAxiosRequestConfig } from 'axios'

import config from 'config'

const scopes = [config.msalConfig.scope]
const videotronTenantId = config.msalConfig.tenantId

export const startLogin = (msalInstance: IPublicClientApplication): Promise<void> => {
  return msalInstance.loginRedirect({ scopes })
}

export const registerLogin = (msalInstance: IPublicClientApplication): boolean => {
  const videotronAccount = msalInstance.getAllAccounts().find((acc) => acc.tenantId === videotronTenantId)
  if (accountTokensAreValid(videotronAccount)) {
    msalInstance.setActiveAccount(videotronAccount)
    return true
  } else {
    return false
  }
}

const accountTokensAreValid = (account: AccountInfo): boolean => {
  if (!account) {
    return false
  }
  const mustExpireAfter = Date.now() + 5000
  const tokensExpire = (account.idTokenClaims as TokenClaims).exp * 1000
  return mustExpireAfter < tokensExpire
}

export const interceptRequest = async (
  msalInstance: IPublicClientApplication,
  request: InternalAxiosRequestConfig
): Promise<InternalAxiosRequestConfig> => {
  try {
    try {
      const authResult = await msalInstance.acquireTokenSilent({
        scopes,
        account: msalInstance.getActiveAccount(),
      })
      request.headers.Authorization = `Bearer ${authResult.accessToken}`
      return request
    } catch (reason) {
      return await Promise.reject(reason)
    }
  } catch (e) {
    return Promise.reject(e)
  }
}
