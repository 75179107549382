import ApiFlowManagerDetail from 'models/apiflowmanager/ApiFlowManagerDetail'
import { EApiFlowManagerType, EApiFlowManagerPresentationType } from 'models/apiflowmanager/Enumeration'
import IUser from 'models/user/user'
import { EUserActionTypes } from 'redux/actions/user/user.action.types'

import { TUserReducerAction, IUserState } from './user'

export const initialUserState: IUserState = {
  firstName: undefined,
  lastName: undefined,
  apiFlowManagerDetail: undefined,
}

const setUserLoading = (state: IUserState): IUserState => ({
  ...state,
  apiFlowManagerDetail: new ApiFlowManagerDetail(EApiFlowManagerType.LOADER, EApiFlowManagerPresentationType.GLOBAL),
})

const setUserSuccess = (state: IUserState, user: IUser): IUserState => ({
  ...state,
  firstName: user.firstName,
  lastName: user.lastName,
  apiFlowManagerDetail: undefined,
})

const setUserFailure = (state: IUserState, apiFlow: ApiFlowManagerDetail): IUserState => ({
  ...state,
  apiFlowManagerDetail: apiFlow,
})

export const userReducer = (state: IUserState = initialUserState, action: TUserReducerAction) => {
  switch (action.type) {
    case EUserActionTypes.FETCH_USER:
      return setUserLoading(state)
    case EUserActionTypes.FETCH_USER_SUCCESS:
      return setUserSuccess(state, action.payload)
    case EUserActionTypes.FETCH_USER_FAILURE:
      return setUserFailure(state, action.payload)
    default:
      return state
  }
}
