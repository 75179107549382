import * as React from 'react'

import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress'

import 'assets/css/variables.scss'
import { default as s } from './Loader.scss'

export type ILoaderCUIProps = CircularProgressProps

export enum ELoaderVariant {
  INDETERMINATE = 'indeterminate',
  DETERMINATE = 'determinate',
  STATIC = 'static',
}

const LoaderCUI = ({ variant, disableShrink, size, thickness, value }: ILoaderCUIProps) => (
  <div data-qa="Loader">
    <CircularProgress
      variant={variant}
      disableShrink={disableShrink}
      size={size}
      thickness={thickness}
      value={value}
      className={s.spinner}
    />
  </div>
)

export default LoaderCUI
