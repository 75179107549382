import ApiFlowManagerDetail from 'models/apiflowmanager/ApiFlowManagerDetail'
import { EApiFlowManagerType, EApiFlowManagerPresentationType } from 'models/apiflowmanager/Enumeration'
import { IContainer } from 'models/container/container'
import { IContent } from 'models/content/content'
import { TContainerCreateDTO } from 'models/forms/container/ContainerCreateDTO'
import { TContentCreateDTO } from 'models/forms/content/ContentCreateDTO'
import {
  ContainerActionTypes,
  TCreateContainerAction,
  TCreateContainerSuccessAction,
  TCreateContainerFailureAction,
} from 'redux/actions/container'
import { ContentActionTypes, TCreateContentAction, TCreateContentFailureAction } from 'redux/actions/content'
import { TCreateContentSuccessAction } from 'redux/actions/content/content'
import { IApiFlowManager } from 'types/common'

import { CreateContainerActionTypes } from '../create-panel-detail/actionTypes'
import { TCreateContainerAvailableActions } from '../create-panel-detail/createContainerActions'

import { DetailPanelActionTypes } from './actionTypes'
import { TDetailPanelAvailableActions } from './commonDetailPanelActions'

export type TAvailableDetailPanelType = IContent | IContainer | TContainerCreateDTO | TContentCreateDTO

export interface IDetailPanelState extends IApiFlowManager {
  detail: TAvailableDetailPanelType
  detailPreview: IContent | IContainer
  detailSaveLoader: boolean
  detailSaveSuccess: boolean
  mode: EDetailMode
  currentTab: number
}

export enum EDetailMode {
  EDIT,
  PRE_CREATE,
  CREATE,
}

export const initialState: IDetailPanelState = {
  detail: undefined,
  detailPreview: undefined,
  detailSaveLoader: false,
  detailSaveSuccess: false,
  apiFlowManagerDetail: new ApiFlowManagerDetail(EApiFlowManagerType.LOADER, EApiFlowManagerPresentationType.GLOBAL),
  mode: undefined,
  currentTab: 0,
}

const setDetailFail = (state: IDetailPanelState, apiFlowManagerDetail: ApiFlowManagerDetail): IDetailPanelState => ({
  ...state,
  apiFlowManagerDetail,
  detailSaveLoader: false,
  detailSaveSuccess: false,
})

const setDetailLoaderStart = (state: IDetailPanelState): IDetailPanelState => ({
  ...state,
  detailSaveLoader: true,
})

const setDetailLoaderEnd = (state: IDetailPanelState): IDetailPanelState => ({
  ...state,
  detailSaveLoader: false,
  detailSaveSuccess: true,
})

const toggleEditMode = (state: IDetailPanelState, toggle: boolean): IDetailPanelState => ({
  ...state,
  mode: toggle ? EDetailMode.EDIT : undefined,
  apiFlowManagerDetail: toggle
    ? new ApiFlowManagerDetail(EApiFlowManagerType.LOADER, EApiFlowManagerPresentationType.GLOBAL)
    : undefined,
})

const toggleCreationMode = (state: IDetailPanelState, toggle: boolean): IDetailPanelState => ({
  ...state,
  mode: toggle ? EDetailMode.PRE_CREATE : undefined,
  detailSaveSuccess: false,
})

const setCurrentTab = (state: IDetailPanelState, tab: number): IDetailPanelState => ({
  ...state,
  currentTab: tab,
})

const toggleCreationAdvandeMode = (state: IDetailPanelState, toggle: boolean): IDetailPanelState => ({
  ...state,
  mode: toggle ? EDetailMode.CREATE : undefined,
  apiFlowManagerDetail: new ApiFlowManagerDetail(EApiFlowManagerType.SUCCESS, EApiFlowManagerPresentationType.GLOBAL),
})

export default (
  state: IDetailPanelState = initialState,
  action:
    | TDetailPanelAvailableActions
    | TCreateContainerAvailableActions
    | TCreateContainerAction
    | TCreateContainerSuccessAction
    | TCreateContainerFailureAction
    | TCreateContentAction
    | TCreateContentSuccessAction
    | TCreateContentFailureAction
) => {
  switch (action.type) {
    case DetailPanelActionTypes.RESET_DETAIL:
      return initialState
    case DetailPanelActionTypes.SET_CURRENT_TAB:
      return setCurrentTab(state, action.tab)
    case DetailPanelActionTypes.TOGGLE_DETAIL_EDIT:
      return toggleEditMode(state, action.toggle)
    // CONTAINER
    case ContainerActionTypes.CREATE_CONTAINER:
      return setDetailLoaderStart(state)
    case ContainerActionTypes.CREATE_CONTAINER_SUCCESS:
      return setDetailLoaderEnd(state)
    case ContainerActionTypes.CREATE_CONTAINER_FAILURE:
      return setDetailFail(state, action.payload)
    // CONTENT
    case ContentActionTypes.CREATE_CONTENT:
      return setDetailLoaderStart(state)
    case ContentActionTypes.CREATE_CONTENT_SUCCESS:
      return setDetailLoaderEnd(state)
    case ContentActionTypes.CREATE_CONTENT_FAILURE:
      return setDetailFail(state, action.payload)
    case CreateContainerActionTypes.SET_CREATION_MODE:
      return toggleCreationMode(state, action.creationMode)
    case CreateContainerActionTypes.SET_CREATION_ADVANDED_MODE:
      return toggleCreationAdvandeMode(state, action.creationAdvancedMode)
    default:
      return state
  }
}
