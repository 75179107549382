export enum SearchPfuActionTypes {
  FETCH_TAGS = '@Facet FETCH_TAGS',
  FETCH_TAGS_SUCCESS = '@Facet FETCH_TAGS_SUCCESS',
  FETCH_TAGS_FAILURE = '@Facet FETCH_TAGS_FAILURE',
  FETCH_INTERESTS = '@Facet FETCH_INTERESTS',
  FETCH_INTERESTS_SUCCESS = '@Facet FETCH_INTERESTS_SUCCESS',
  FETCH_INTERESTS_FAILURE = '@Facet FETCH_INTERESTS_FAILURE',
  FETCH_VIDEO_SHOWS = '@Pfu FETCH_VIDEO_SHOWS',
  FETCH_VIDEO_SHOWS_SUCCESS = '@Pfu FETCH_VIDEO_SHOWS_SUCCESS',
  FETCH_VIDEO_SHOWS_FAILURE = '@Pfu FETCH_VIDEO_SHOWS_FAILURE',
  FETCH_AUDIO_SHOWS = '@Pfu FETCH_AUDIO_SHOWS',
  FETCH_AUDIO_SHOWS_SUCCESS = '@Pfu FETCH_AUDIO_SHOWS_SUCCESS',
  FETCH_AUDIO_SHOWS_FAILURE = '@Pfu FETCH_AUDIO_SHOWS_FAILURE',
  SEARCH_PFU_CATALOG = '@Pfu SEARCH_PFU_CATALOG',
  SEARCH_PFU_CATALOG_SUCCESS = '@Pfu SEARCH_PFU_CATALOG_SUCCESS',
  SEARCH_PFU_CATALOG_FAILURE = '@Pfu SEARCH_PFU_CATALOG_FAILURE',
  SEARCH_PFU_HISTORY = '@Pfu SEARCH_PFU_HISTORY',
}
