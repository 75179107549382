import { call, fork, put, takeLatest } from 'redux-saga/effects'

import { fetchValidPermissions } from 'api/Permissions'
import { EApiFlowManagerPresentationType } from 'models/apiflowmanager/Enumeration'
import { IPermission } from 'models/permission/Permission'
import { PermissionActionTypes } from 'redux/actions/permission'
import { fetchAllPermissionsSuccess, fetchAllPermissionsFailure } from 'redux/actions/permission/permission.action'
import { apiCallErrorHandling } from 'services/apicallsagaservice/apiCallErrorHandling'

export function* fetchValidPermissionsAsync() {
  try {
    const permissions: IPermission[] = yield call(fetchValidPermissions)

    yield put(fetchAllPermissionsSuccess(permissions))
  } catch (error) {
    yield put(
      fetchAllPermissionsFailure(
        apiCallErrorHandling(error, { presentationType: EApiFlowManagerPresentationType.BANNER })
      )
    )
  }
}

function* watchFetchValidPermission() {
  yield takeLatest(PermissionActionTypes.FETCH_ALL_PERMISSIONS, fetchValidPermissionsAsync)
}

export function* rootAllPermissionsSaga() {
  yield fork(watchFetchValidPermission)
}
