import { AxiosError } from 'axios'

import ApiFlowManagerDetail from 'models/apiflowmanager/ApiFlowManagerDetail'
import {
  EApiFlowManagerErrorType,
  EApiFlowManagerPresentationType,
  EApiFlowManagerType,
} from 'models/apiflowmanager/Enumeration'

const addApiFlowManagerDetailErrorType = (
  apiFlowManagerDetail: ApiFlowManagerDetail,
  error: AxiosError
): ApiFlowManagerDetail => {
  if (error.response) {
    return apiFlowManagerDetail.addErrorType(error.response.status)
  } else if (error.code === 'ECONNABORTED') {
    return apiFlowManagerDetail.addErrorType(1000)
  } else {
    return apiFlowManagerDetail.addErrorType(1100)
  }
}

export const apiCallErrorHandling = (
  error?: AxiosError<{ message?: string }>,
  params?: { id?: string; presentationType?: EApiFlowManagerPresentationType }
): ApiFlowManagerDetail => {
  if (error) {
    const apiFlowManagerDetail = new ApiFlowManagerDetail().addType(EApiFlowManagerType.ERROR)
    addApiFlowManagerDetailErrorType(apiFlowManagerDetail, error)

    if (error.config && error.config.method) {
      apiFlowManagerDetail.addRequestType(error.config.method as EApiFlowManagerErrorType)
      apiFlowManagerDetail.addPresentationType(
        error.config.method === 'get' ? EApiFlowManagerPresentationType.GLOBAL : EApiFlowManagerPresentationType.BANNER
      )
    }

    if (params && params.id) {
      apiFlowManagerDetail.id = params.id
    }
    if (params && params.presentationType) {
      apiFlowManagerDetail.addPresentationType(params.presentationType)
    }

    apiFlowManagerDetail.message = error?.response?.data?.message

    return apiFlowManagerDetail
  }

  return null
}
