import { format as formatFns, isValid as dateIsValid, set } from 'date-fns'
import { fr } from 'date-fns/locale/fr'

import { EDays } from 'models/container/enumeration'

const daysOfWeek = [
  EDays.MONDAY,
  EDays.TUESDAY,
  EDays.WEDNESDAY,
  EDays.THURSDAY,
  EDays.FRIDAY,
  EDays.SATURDAY,
  EDays.SUNDAY,
]

export const dateOrUndefined = (date: Date | string | number | undefined) => {
  return date && dateIsValid(new Date(date)) ? new Date(date) : undefined
}

export const isUnknownFutureDate = (date: string): boolean => {
  return date === '+19600-01-01T00:00:00Z'
}

export const hourToDate = (hour: string): Date => {
  const [hours, minutes] = hour.split(':').map(Number)

  return set(new Date(), { hours, minutes })
}

export const nextDayOfWeek = (dayOfWeek: EDays): EDays => {
  const indexDayOfWeek = daysOfWeek.indexOf(dayOfWeek)

  if (indexDayOfWeek === daysOfWeek.length - 1) {
    return daysOfWeek[0]
  }

  return daysOfWeek[indexDayOfWeek + 1]
}

export const formatDate = (value: Date | number, format: string) => {
  return formatFns(value, format, { locale: fr })
}

// Timestamp must be *1000 to display right value
export const formatTimeStampToISOString = (value: number | string): string => {
  // Keep working until date format are all set to timestamp
  if (typeof value === 'number') {
    return new Date(value * 1000).toISOString()
  } else {
    return value
  }
}
