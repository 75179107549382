import React from 'react'

export interface IIntermediateFormikContextInterface {
  changed: any | null
  setChanged: (value: any) => void
  clearChanged: () => void
}

export const IntermediateFormikContext = React.createContext<IIntermediateFormikContextInterface | null>(null)

export const IntermediateFormikProvider = ({ children }: { children: React.ReactNode }) => {
  const [changed, setChanged] = React.useState<any | null>(null)

  const memoizedContextValue = React.useMemo(
    () => ({
      changed,
      setChanged,
      clearChanged: () => setChanged(null),
    }),
    [changed, setChanged]
  )

  return (
    <IntermediateFormikContext.Provider value={memoizedContextValue}>{children}</IntermediateFormikContext.Provider>
  )
}
