import React from 'react'

import isEqual from 'lodash/isEqual'

function useDeepCompareMemoize(value: React.DependencyList): React.DependencyList {
  const ref = React.useRef<React.DependencyList>()

  if (!isEqual(value, ref.current)) {
    ref.current = value
  }

  return ref.current
}

export function useDeepCompareMemo<T>(factory: () => T, dependencies: React.DependencyList): T {
  return React.useMemo(factory, useDeepCompareMemoize(dependencies))
}
