import initial from 'lodash/initial'
import last from 'lodash/last'

import { EFilterType } from 'models/filters/Enumeration'
import { TFilterItem, TPfuFilterItem } from 'modules/filters-panel/filters-panel'
import { FiltersActionTypes, TFiltersAction } from 'redux/actions/filters'
import { ISearchPayloadData } from 'redux/actions/search-pfu/search-pfu'
import { initialPfuFilters } from 'services/filters/redux-filters'

interface ITab {
  active: boolean
  query: string
}

export enum ETabContext {
  QUB = 'QUB',
  JDM = 'JDM',
  JDQ = 'JDQ',
  TVAN = 'TVAN',
  TVAPLUS = 'TVAPLUS',
  TVAS = 'TVAS',
  'CLUB_ILLICO' = 'CLUB_ILLICO',
  ALL = 'all',
}

export interface IRegularTab extends ITab {
  context: ETabContext
  filters: TPfuFilterItem[]
}

export type TTab = IRegularTab

export interface IFiltersState {
  expanded: boolean
  tabs: TTab[]
}

export const initialFiltersState: IFiltersState = {
  expanded: true,
  tabs: [
    {
      context: ETabContext.ALL,
      active: true,
      query: '',
      filters: initialPfuFilters,
    },
  ],
}

const setFilter = <T extends TFilterItem>(stateFilter: T, filter: T, isSelected: boolean) => {
  if (filter.type === stateFilter.type && filter.name === stateFilter.name) {
    return { ...stateFilter, isSelected }
  }
  return stateFilter
}

const getPfuFilters = (stateFilterList: TPfuFilterItem[], filterItemList: TPfuFilterItem[], selectFilters: boolean) => {
  const tagFilters = filterItemList.reduce(
    (acc: TPfuFilterItem[], filter: TPfuFilterItem) =>
      filter.type === EFilterType.TAGS ? [...acc, { ...filter, isSelected: selectFilters }] : acc,
    []
  )
  const interestFilters = filterItemList.reduce(
    (acc: TPfuFilterItem[], filter: TPfuFilterItem) =>
      filter.type === EFilterType.INTERESTS ? [...acc, { ...filter, isSelected: selectFilters }] : acc,
    []
  )
  const pfuFilters = stateFilterList.map((stateFilter) =>
    filterItemList.some((filter) => filter.name === stateFilter.name)
      ? { ...stateFilter, isSelected: selectFilters }
      : stateFilter
  )

  return [...pfuFilters, ...tagFilters, ...interestFilters]
}

const setQuery = (state: IFiltersState, query: string): IFiltersState => ({
  ...state,
  tabs: state.tabs.map((tab) => {
    if (tab.active) {
      return {
        ...tab,
        query,
      }
    }

    return tab
  }),
})

const selectPfuFilter = (state: IFiltersState, filterItem: TPfuFilterItem): IFiltersState => {
  return {
    ...state,
    tabs: state.tabs.map((tab) => {
      if (tab.active) {
        return {
          ...tab,
          filters: tab.filters.map((filter) => setFilter(filter, filterItem, true)),
        }
      }

      return tab
    }),
  }
}
const unselectPfuFilter = (state: IFiltersState, filterItem: TPfuFilterItem): IFiltersState => ({
  ...state,
  tabs: state.tabs.map((tab) => {
    if (tab.active) {
      return {
        ...tab,
        filters: tab.filters.map((filter) => setFilter(filter, filterItem, false)),
      }
    }

    return tab
  }),
})

const unselectMultiPfuFilters = (state: IFiltersState, filterItemList: TPfuFilterItem[]): IFiltersState => ({
  ...state,
  tabs: state.tabs.map((tab) => {
    if (tab.active) {
      return {
        ...tab,
        filters: getPfuFilters(tab.filters, filterItemList, false),
      }
    }

    return tab
  }),
})

const selectMultiPfuFilters = (state: IFiltersState, filterItemList: TPfuFilterItem[]): IFiltersState => ({
  ...state,
  tabs: state.tabs.map((tab) => {
    if (tab.active) {
      return {
        ...tab,
        filters: getPfuFilters(tab.filters, filterItemList, true),
      }
    }

    return tab
  }),
})

const clearSelectedPfuFilters = (state: IFiltersState): IFiltersState => ({
  ...state,
  tabs: state.tabs.map((tab) => {
    if (tab.active) {
      return {
        ...tab,
        filters: tab.filters.reduce(
          (filters: TPfuFilterItem[], filter: TPfuFilterItem) =>
            filter.type !== EFilterType.TAGS ? [...filters, { ...filter, isSelected: false }] : filters,
          []
        ),
      }
    }

    return tab
  }),
})

const addPfuTagFilter = (state: IFiltersState, tag: string): IFiltersState => ({
  ...state,
  tabs: state.tabs.map((tab) => {
    if (tab.active) {
      return {
        ...tab,
        filters: [...tab.filters, { type: EFilterType.TAGS, name: tag, isSelected: true }],
      }
    }

    return tab
  }),
})

const removePfuTagFilter = (state: IFiltersState, tag: string): IFiltersState => ({
  ...state,
  tabs: state.tabs.map((tab) => {
    if (tab.active) {
      return {
        ...tab,
        filters: tab.filters.filter((filter) => !(filter.type === EFilterType.TAGS && filter.name === tag)),
      }
    }

    return tab
  }),
})

const clearPfuTagFilters = (state: IFiltersState): IFiltersState => ({
  ...state,
  tabs: state.tabs.map((tab) => {
    if (tab.active) {
      return {
        ...tab,
        filters: tab.filters.filter((filter) => filter.type !== EFilterType.TAGS),
      }
    }

    return tab
  }),
})

const addPfuInterestFilter = (state: IFiltersState, data: ISearchPayloadData): IFiltersState => ({
  ...state,
  tabs: state.tabs.map((tab) => {
    if (tab.active) {
      return {
        ...tab,
        filters: [
          ...tab.filters,
          { type: EFilterType.INTERESTS, name: data.name, label: data.label, isSelected: true },
        ],
      }
    }

    return tab
  }),
})

const removePfuInterestFilter = (state: IFiltersState, data: ISearchPayloadData): IFiltersState => ({
  ...state,
  tabs: state.tabs.map((tab) => {
    if (tab.active) {
      return {
        ...tab,
        filters: tab.filters.filter((filter) => !(filter.type === EFilterType.INTERESTS && filter.name === data.name)),
      }
    }

    return tab
  }),
})

const clearPfuInterestFilters = (state: IFiltersState): IFiltersState => ({
  ...state,
  tabs: state.tabs.map((tab) => {
    if (tab.active) {
      return {
        ...tab,
        filters: tab.filters.filter((filter) => filter.type !== EFilterType.INTERESTS),
      }
    }

    return tab
  }),
})

const clearQueryAndPfuFilters = (state: IFiltersState): IFiltersState => ({
  ...state,
  tabs: state.tabs.map((tab) => {
    if (tab.active) {
      return {
        ...tab,
        query: '',
        filters: tab.filters.reduce(
          (filters: TPfuFilterItem[], filter: TPfuFilterItem) =>
            filter.type !== EFilterType.TAGS ? [...filters, { ...filter, isSelected: false }] : filters,
          []
        ),
      }
    }

    return tab
  }),
})

const toggleExpanded = (state: IFiltersState): IFiltersState => ({
  ...state,
  expanded: !state.expanded,
})

const setExpanded = (state: IFiltersState, isExpanded: boolean): IFiltersState => ({
  ...state,
  expanded: isExpanded,
})

const addNewTab = (state: IFiltersState): IFiltersState => {
  const activeTab = state.tabs.find((tab) => tab.active)

  return {
    ...state,
    tabs: [
      ...state.tabs.map((tab) => {
        return { ...tab, active: false }
      }),
      { context: activeTab.context, active: true, query: '', filters: initialPfuFilters },
    ],
  }
}

const addNewTabWithContext = (state: IFiltersState, context: ETabContext): IFiltersState => {
  return {
    ...state,
    tabs: [
      ...state.tabs.map((tab) => {
        return { ...tab, active: false }
      }),
      { context, active: true, query: '', filters: initialPfuFilters },
    ],
  }
}

const removeTab = (state: IFiltersState, index: number): IFiltersState => {
  const filteredTabs = state.tabs.filter((_, i) => i !== index)
  const activeTabIndex = state.tabs.findIndex((tab) => tab.active)

  // deleting anything but the current tab
  if (activeTabIndex !== index) {
    return {
      ...state,
      tabs: filteredTabs,
    }
  }

  // deleting the currently active tab, given it's the last one
  if (state.tabs.length - 1 === index) {
    const initialTabs = initial(filteredTabs)
    const lastTab = last(filteredTabs)

    return {
      ...state,
      tabs: [...initialTabs, { ...lastTab, active: true }],
    }
  }

  return {
    ...state,
    tabs: filteredTabs.map((tab, i) => {
      if (i === index) {
        return { ...tab, active: true }
      }

      return tab
    }),
  }
}

const switchTab = (state: IFiltersState, index: number): IFiltersState => {
  const activeTab = state.tabs.find((tab) => tab.active)

  // avoid doing anything if we are trying to switch to the currently active tab
  if (state.tabs.indexOf(activeTab) === index) {
    return state
  }

  return {
    ...state,
    tabs: state.tabs.map((tab, i) => {
      if (i === index) {
        return { ...tab, active: true }
      }

      return { ...tab, active: false }
    }),
  }
}

export const filtersReducer = (state: IFiltersState = initialFiltersState, action: TFiltersAction): IFiltersState => {
  switch (action.type) {
    case FiltersActionTypes.SET_QUERY:
      return setQuery(state, action.payload)
    case FiltersActionTypes.SELECT_PFU_FILTER:
      return selectPfuFilter(state, action.payload)
    case FiltersActionTypes.UNSELECT_PFU_FILTER:
      return unselectPfuFilter(state, action.payload)
    case FiltersActionTypes.UNSELECT_MULTI_PFU_FILTERS:
      return unselectMultiPfuFilters(state, action.payload)
    case FiltersActionTypes.SELECT_MULTI_PFU_FILTERS:
      return selectMultiPfuFilters(state, action.payload)
    case FiltersActionTypes.CLEAR_SELECTED_PFU_FILTERS:
      return clearSelectedPfuFilters(state)
    case FiltersActionTypes.ADD_PFU_TAG_FILTER:
      return addPfuTagFilter(state, action.payload)
    case FiltersActionTypes.REMOVE_PFU_TAG_FILTER:
      return removePfuTagFilter(state, action.payload)
    case FiltersActionTypes.CLEAR_PFU_TAG_FILTERS:
      return clearPfuTagFilters(state)
    case FiltersActionTypes.ADD_PFU_INTEREST_FILTER:
      return addPfuInterestFilter(state, action.payload)
    case FiltersActionTypes.REMOVE_PFU_INTEREST_FILTER:
      return removePfuInterestFilter(state, action.payload)
    case FiltersActionTypes.CLEAR_PFU_INTEREST_FILTERS:
      return clearPfuInterestFilters(state)
    case FiltersActionTypes.CLEAR_QUERY_AND_PFU_FILTERS:
      return clearQueryAndPfuFilters(state)
    case FiltersActionTypes.TOGGLE_EXPANDED:
      return toggleExpanded(state)
    case FiltersActionTypes.SET_EXPANDED:
      return setExpanded(state, action.payload)
    case FiltersActionTypes.ADD_NEW_TAB:
      return addNewTab(state)
    case FiltersActionTypes.ADD_NEW_TAB_WITH_CONTEXT:
      return addNewTabWithContext(state, action.payload)
    case FiltersActionTypes.REMOVE_TAB:
      return removeTab(state, action.payload)
    case FiltersActionTypes.SWITCH_TAB:
      return switchTab(state, action.payload)
    default:
      return state
  }
}
