import differenceBy from 'lodash/differenceBy'
import isEqual from 'lodash/isEqual'

import { TBaseSlot } from 'models/container/container'
import { ESlotConfig } from 'models/container/enumeration'
import { AVAILABLE_REQUEST_COLORS } from 'services/ContainerFormService'

import { IRequestsState } from './requests'

export const requestSlotFilter = (slot: TBaseSlot) => slot.config.type !== ESlotConfig.IDENTIFIER

const getOccurences = (stateColors: IRequestsState['colors']) => {
  return AVAILABLE_REQUEST_COLORS.reduce<{ [color: string]: number }>((occ, color) => {
    const occurenceOfColor = Object.values(stateColors).reduce((total, slotColor) => {
      return slotColor === color ? total + 1 : total
    }, 0)

    return {
      ...occ,
      [color]: occurenceOfColor,
    }
  }, {})
}

const getLeastUsedColors = (stateColors: IRequestsState['colors']): string[] => {
  const occurences = getOccurences(stateColors)

  const smallest = Math.min(...Object.values(occurences))

  return Object.keys(occurences).reduce<string[]>((colors, curr) => {
    return occurences[curr] === smallest ? [...colors, curr] : colors
  }, [])
}

const getFirstLeastUsedColor = (stateColors: IRequestsState['colors']): string => {
  const leastUsedColors = getLeastUsedColors(stateColors)

  return AVAILABLE_REQUEST_COLORS.find((color) => leastUsedColors.includes(color))
}

export const syncColors = (
  stateColors: IRequestsState['colors'],
  oldSlots: IRequestsState['slots'],
  newSlots: IRequestsState['slots']
): IRequestsState['colors'] => {
  if (isEqual(oldSlots, newSlots)) {
    return stateColors
  }

  const oldRequestSlots = oldSlots.filter(requestSlotFilter)
  const newRequestSlots = newSlots.filter(requestSlotFilter)

  if (newRequestSlots.length > oldRequestSlots.length) {
    const [newSlot] = differenceBy(newRequestSlots, oldRequestSlots, 'name')
    const color = getFirstLeastUsedColor(stateColors)

    return { ...stateColors, [newSlot.name]: color }
  }

  if (newRequestSlots.length < oldRequestSlots.length) {
    const [removedSlot] = differenceBy(oldRequestSlots, newRequestSlots, 'name')
    delete stateColors[removedSlot.name]

    return { ...stateColors }
  }

  const nameChanged = differenceBy(oldRequestSlots, newRequestSlots, 'name').length
  if (nameChanged) {
    const [oldUpdatedSlot] = differenceBy(oldRequestSlots, newRequestSlots, 'name')
    const [newUpdatedSlot] = differenceBy(newRequestSlots, oldRequestSlots, 'name')

    const stateColorsCopy = { ...stateColors }
    stateColorsCopy[newUpdatedSlot.name] = stateColorsCopy[oldUpdatedSlot.name]
    delete stateColorsCopy[oldUpdatedSlot.name]

    return stateColorsCopy
  }

  return stateColors
}
