import ApiFlowManagerDetail from 'models/apiflowmanager/ApiFlowManagerDetail'
import { IContentVideoPreview, IRelationsGroup, TContent } from 'models/content/content'
import { IAssociation } from 'models/detail/content/ContentAssociation'
import { TContentUpdateParams } from 'models/detail/content/ContentUpdate'
import IShowHierarchy from 'models/detail/content/ShowHierarchy'
import { TContentCreateDTO } from 'models/forms/content/ContentCreateDTO'
import ContentStandardFeedCreateDTO from 'models/forms/content/ContentStandardFeedCreateDTO'

import { ContentActionTypes } from './content.action.types'
import {
  TClearAssociationsSuccessAction,
  TClearContentPreviewSuccessAction,
  TCreateContentAction,
  TCreateContentFailureAction,
  TCreateContentSuccessAction,
  TCreateStandardFeedAction,
  TCreateStandardFeedFailureAction,
  TCreateStandardFeedSuccessAction,
  TDeleteContentAction,
  TDeleteContentAssociationsAction,
  TDeleteContentAssociationsFailureAction,
  TDeleteContentAssociationsSuccessAction,
  TDeleteContentFailureAction,
  TDeleteContentSuccessAction,
  TFetchContentAction,
  TFetchContentAssociationsAction,
  TFetchContentAssociationsFailureAction,
  TFetchContentAssociationsSuccessAction,
  TFetchContentFailureAction,
  TFetchContentPreviewAction,
  TFetchContentPreviewFailureAction,
  TFetchContentPreviewSuccessAction,
  TFetchContentRelatesToAction,
  TFetchContentRelatesToFailureAction,
  TFetchContentRelatesToSuccessAction,
  TFetchContentShowHierarchyAction,
  TFetchContentShowHierarchyFailureAction,
  TFetchContentShowHierarchySuccessAction,
  TFetchContentSuccessAction,
  TFetchDroppedContentPreviewAction,
  TFetchDroppedContentPreviewFailureAction,
  TFetchDroppedContentPreviewSuccessAction,
  TUpdateContentAction,
  TUpdateContentAssociationsAction,
  TUpdateContentAssociationsFailureAction,
  TUpdateContentAssociationsSuccessAction,
  TUpdateContentFailureAction,
  TUpdateContentSuccessAction,
  TUpdateContentWithImageAction,
  TUpdateContentWithImagesAction,
  TUploadImageAction,
  TUploadImageSuccessAction,
} from './content.d'

export const fetchContent = (id: string): TFetchContentAction => ({
  type: ContentActionTypes.FETCH_CONTENT,
  payload: id,
})

export const fetchContentSuccess = (content: TContent): TFetchContentSuccessAction => ({
  type: ContentActionTypes.FETCH_CONTENT_SUCCESS,
  payload: content,
})

export const fetchContentFailure = (apiFlowManagerDetail: ApiFlowManagerDetail): TFetchContentFailureAction => ({
  type: ContentActionTypes.FETCH_CONTENT_FAILURE,
  payload: apiFlowManagerDetail,
})

export const fetchDroppedContentPreview = (id: string): TFetchDroppedContentPreviewAction => ({
  type: ContentActionTypes.FETCH_DROPPED_CONTENT_PREVIEW,
  payload: id,
})

export const fetchDroppedContentPreviewSuccess = (
  content: IContentVideoPreview
): TFetchDroppedContentPreviewSuccessAction => ({
  type: ContentActionTypes.FETCH_DROPPED_CONTENT_PREVIEW_SUCCESS,
  payload: content,
})

export const fetchDroppedContentPreviewFailure = (
  apiFlowManagerDetail: ApiFlowManagerDetail
): TFetchDroppedContentPreviewFailureAction => ({
  type: ContentActionTypes.FETCH_DROPPED_CONTENT_PREVIEW_FAILURE,
  payload: apiFlowManagerDetail,
})

export const fetchContentPreview = (id: string): TFetchContentPreviewAction => ({
  type: ContentActionTypes.FETCH_CONTENT_PREVIEW,
  payload: id,
})

export const fetchContentPreviewSuccess = (content: TContent): TFetchContentPreviewSuccessAction => ({
  type: ContentActionTypes.FETCH_CONTENT_PREVIEW_SUCCESS,
  payload: content,
})

export const clearContentPreview = (): TClearContentPreviewSuccessAction => ({
  type: ContentActionTypes.CLEAR_CONTENT_PREVIEW,
})

export const fetchContentPreviewFailure = (
  apiFlowManagerDetail: ApiFlowManagerDetail
): TFetchContentPreviewFailureAction => ({
  type: ContentActionTypes.FETCH_CONTENT_PREVIEW_FAILURE,
  payload: apiFlowManagerDetail,
})

export const fetchContentRelatesTo = (id: string, relationTypes?: string[]): TFetchContentRelatesToAction => ({
  type: ContentActionTypes.FETCH_CONTENT_RELATES_TO,
  payload: { id, relationTypes },
})

export const fetchContentRelatesToSuccess = (
  contentRelationsGroups: IRelationsGroup[]
): TFetchContentRelatesToSuccessAction => ({
  type: ContentActionTypes.FETCH_CONTENT_RELATES_TO_SUCCESS,
  payload: contentRelationsGroups,
})

export const fetchContentRelatesToFailure = (apiFlow: ApiFlowManagerDetail): TFetchContentRelatesToFailureAction => ({
  type: ContentActionTypes.FETCH_CONTENT_RELATES_TO_FAILURE,
  payload: apiFlow,
})

export const fetchContentShowHierarchy = (id: string): TFetchContentShowHierarchyAction => ({
  type: ContentActionTypes.FETCH_CONTENT_SHOW_HIERARCHY,
  payload: id,
})

export const fetchContentShowHierarchySuccess = (
  showHierarchy: IShowHierarchy
): TFetchContentShowHierarchySuccessAction => ({
  type: ContentActionTypes.FETCH_CONTENT_SHOW_HIERARCHY_SUCCESS,
  payload: showHierarchy,
})

export const fetchContentShowHierarchyFailure = (
  apiFlow: ApiFlowManagerDetail
): TFetchContentShowHierarchyFailureAction => ({
  type: ContentActionTypes.FETCH_CONTENT_SHOW_HIERARCHY_FAILURE,
  payload: apiFlow,
})

export const fetchContentAssociations = (id: string): TFetchContentAssociationsAction => ({
  type: ContentActionTypes.FETCH_CONTENT_ASSOCIATIONS,
  payload: id,
})

export const fetchContentAssociationsSuccess = (associations: TContent[]): TFetchContentAssociationsSuccessAction => ({
  type: ContentActionTypes.FETCH_CONTENT_ASSOCIATIONS_SUCCESS,
  payload: associations,
})

export const fetchContentAssociationsFailure = (
  apiFlow: ApiFlowManagerDetail
): TFetchContentAssociationsFailureAction => ({
  type: ContentActionTypes.FETCH_CONTENT_ASSOCIATIONS_FAILURE,
  payload: apiFlow,
})

export const clearAssociations = (): TClearAssociationsSuccessAction => ({
  type: ContentActionTypes.CLEAR_ASSOCIATION,
})

export const updateContent = (id: string, body: TContentUpdateParams): TUpdateContentAction => ({
  type: ContentActionTypes.UPDATE_CONTENT,
  payload: { id, body },
})

export const updateContentWithImage = (
  id: string,
  body: TContentUpdateParams,
  image: File
): TUpdateContentWithImageAction => ({
  type: ContentActionTypes.UPDATE_CONTENT_WITH_IMAGE,
  payload: { id, body, image },
})

export const updateContentWithImages = (
  id: string,
  body: TContentUpdateParams,
  image?: File,
  image16X9?: File,
  image7X1?: File,
  image3X1?: File,
  image2X3?: File,
  showCaseImage16X9?: File,
  showCaseImage2X3?: File,
  backgroundImage73By5?: File,
  backgroundImage16By6?: File,
  headerImage?: File
): TUpdateContentWithImagesAction => ({
  type: ContentActionTypes.UPDATE_CONTENT_WITH_IMAGES,
  payload: {
    id,
    body,
    image,
    image16X9,
    image7X1,
    image3X1,
    image2X3,
    showCaseImage16X9,
    showCaseImage2X3,
    backgroundImage73By5,
    backgroundImage16By6,
    headerImage,
  },
})

export const updateContentSuccess = (content: TContent): TUpdateContentSuccessAction => ({
  type: ContentActionTypes.UPDATE_CONTENT_SUCCESS,
  payload: content,
})

export const updateContentFailure = (apiFlowManagerDetail: ApiFlowManagerDetail): TUpdateContentFailureAction => ({
  type: ContentActionTypes.UPDATE_CONTENT_FAILURE,
  payload: apiFlowManagerDetail,
})

export const updateContentAssociations = (associations: IAssociation[]): TUpdateContentAssociationsAction => ({
  type: ContentActionTypes.UPDATE_CONTENT_ASSOCIATIONS,
  payload: associations,
})

export const updateContentAssociationsSuccess = (): TUpdateContentAssociationsSuccessAction => ({
  type: ContentActionTypes.UPDATE_CONTENT_ASSOCIATIONS_SUCCESS,
})

export const updateContentAssociationsFailure = (
  apiFlow: ApiFlowManagerDetail
): TUpdateContentAssociationsFailureAction => ({
  type: ContentActionTypes.UPDATE_CONTENT_ASSOCIATIONS_FAILURE,
  payload: apiFlow,
})

export const createContent = (contentCreate: TContentCreateDTO): TCreateContentAction => ({
  type: ContentActionTypes.CREATE_CONTENT,
  payload: contentCreate,
})

export const uploadContentImage = (): TUploadImageAction => ({
  type: ContentActionTypes.UPLOAD_IMAGE,
})

export const clearUploadImageUpload = (): TUploadImageSuccessAction => ({
  type: ContentActionTypes.UPLOAD_IMAGE_SUCCESS,
})

export const createContentSuccess = (content: TContent): TCreateContentSuccessAction => ({
  type: ContentActionTypes.CREATE_CONTENT_SUCCESS,
  payload: content,
})

export const createContentFailure = (apiFlowManagerDetail: ApiFlowManagerDetail): TCreateContentFailureAction => ({
  type: ContentActionTypes.CREATE_CONTENT_FAILURE,
  payload: apiFlowManagerDetail,
})

export const deleteContent = (id: string): TDeleteContentAction => ({
  type: ContentActionTypes.DELETE_CONTENT,
  payload: id,
})

export const deleteContentSuccess = (): TDeleteContentSuccessAction => ({
  type: ContentActionTypes.DELETE_CONTENT_SUCCESS,
})

export const deleteContentFailure = (apiFlow: ApiFlowManagerDetail): TDeleteContentFailureAction => ({
  type: ContentActionTypes.DELETE_CONTENT_FAILURE,
  payload: apiFlow,
})

export const deleteContentAssociations = (associations: IAssociation[]): TDeleteContentAssociationsAction => ({
  type: ContentActionTypes.DELETE_CONTENT_ASSOCIATIONS,
  payload: associations,
})

export const deleteContentAssociationsSuccess = (): TDeleteContentAssociationsSuccessAction => ({
  type: ContentActionTypes.DELETE_CONTENT_ASSOCIATIONS_SUCCESS,
})

export const deleteContentAssociationsFailure = (
  apiFlow: ApiFlowManagerDetail
): TDeleteContentAssociationsFailureAction => ({
  type: ContentActionTypes.DELETE_CONTENT_ASSOCIATIONS_FAILURE,
  payload: apiFlow,
})

export const createStandardFeed = (container: ContentStandardFeedCreateDTO): TCreateStandardFeedAction => ({
  type: ContentActionTypes.CREATE_FEED_STANDARD,
  payload: container.getPayload(),
})

export const createStandardFeedSuccess = (container: TContent): TCreateStandardFeedSuccessAction => ({
  type: ContentActionTypes.CREATE_FEED_STANDARD_SUCCESS,
  payload: container,
})

export const createStandardFeedFailure = (
  apiFlowManagerDetail: ApiFlowManagerDetail
): TCreateStandardFeedFailureAction => ({
  type: ContentActionTypes.CREATE_FEED_STANDARD_FAILURE,
  payload: apiFlowManagerDetail,
})
