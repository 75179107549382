import { call, fork, join, put, race, take, takeEvery, CallEffect } from 'redux-saga/effects'

import { RequestActionTypes } from 'modules/request-form/action-types'
import { apiCallErrorHandling } from 'services/apicallsagaservice/apiCallErrorHandling'
import { IApiCallService, IApiCallSagaServiceParams } from 'services/apicallsagaservice/sagaApiCallServiceActions'

/** *************************************************************************************************
 *                                           Workers                                                *
 ***************************************************************************************************/

function* sendRequest(
  apiMethod: (params: any) => any,
  params: IApiCallSagaServiceParams
): Generator<CallEffect<unknown>> {
  try {
    return { results: yield call(apiMethod, params) }
  } catch (error) {
    return { error }
  }
}

export function* sagaApiCallServiceAsync(action: IApiCallService<any, any, any, any, any, any>) {
  // @ts-ignore
  const task: any = yield fork(sendRequest, action.onRequestApi, action.params)
  const { response } = yield race({
    response: join(task),
    cancel: take(action.cancelKey),
  })

  if (!response) {
    return
  }

  if (response.error) {
    yield action.params && action.params.pageOffset > 0
      ? put(action.onFailure(undefined, response.error.response ? response.error.response.status : 1000))
      : put(action.onFailure(apiCallErrorHandling(response.error, action.params), null))
    return
  }

  if (action.onReset && action.params !== undefined && action.params.pageOffset === 0) {
    yield put(action.onReset())
  }

  yield put(action.onSuccess(response.results))
}

/** *************************************************************************************************
 *                                         Watchers                                                 *
 ***************************************************************************************************/

export function* watchSagaApiCallService() {
  yield takeEvery(RequestActionTypes.FETCH_SLOT_DETAIL, sagaApiCallServiceAsync)
}
