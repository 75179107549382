import ApiFlowManagerDetail from 'models/apiflowmanager/ApiFlowManagerDetail'
import { IPermission } from 'models/permission/Permission'

import {
  TFetchAllPermissions,
  TFetchAllPermissionsSuccessAction,
  TFetchAllPermissionsFailureAction,
} from './permission'
import { PermissionActionTypes } from './permission.action.types'

export const fetchAllPermissions = (): TFetchAllPermissions => ({
  type: PermissionActionTypes.FETCH_ALL_PERMISSIONS,
})

export const fetchAllPermissionsSuccess = (allPermissions: IPermission[]): TFetchAllPermissionsSuccessAction => ({
  type: PermissionActionTypes.FETCH_ALL_PERMISSIONS_SUCCESS,
  payload: allPermissions,
})

export const fetchAllPermissionsFailure = (apiFlow: ApiFlowManagerDetail): TFetchAllPermissionsFailureAction => ({
  type: PermissionActionTypes.FETCH_ALL_PERMISSIONS_FAILURE,
  payload: apiFlow,
})
