import { connectRouter } from 'connected-react-router'
import { History } from 'history'
import { combineReducers } from 'redux'

import * as DetailPanelState from 'modules/detailpanel/detailPanelReducer'
import * as RequestState from 'modules/request-form/request-form.reducer'
import { IStoreState } from 'types/common'

import { detailReducer, initialDetailState } from './reducers/detail'
import { droppedContentReducer, initialDroppedContentState } from './reducers/dropped-content'
import { facetReducer, initialFacetState } from './reducers/facet'
import { initialFiltersState, filtersReducer } from './reducers/filters'
import { initialPermissionState, permissionReducer } from './reducers/permission/permission.reducer'
import { initialPreviewState, previewReducer } from './reducers/preview'
import { initialRelationsState, relationsReducer } from './reducers/relations'
import { initialRequestsState, requestsReducer } from './reducers/requests'
import { initialRouterHistoryState, routerHistoryReducer } from './reducers/router'
import { initialSearchState, searchReducer } from './reducers/search'
import { selectReducer, initialSelectState } from './reducers/select'
import { initialTaxonomyState, taxonomyReducer } from './reducers/taxonomy'
import { initialUserState, userReducer } from './reducers/user'

// Define the global initialState of the whole store, used in root index
export const initialStoreState: IStoreState = {
  routerHistory: initialRouterHistoryState,
  detail: initialDetailState,
  detailPanel: DetailPanelState.initialState,
  droppedContent: initialDroppedContentState,
  facet: initialFacetState,
  filtersPanel: initialFiltersState,
  permission: initialPermissionState,
  preview: initialPreviewState,
  relations: initialRelationsState,
  request: RequestState.initialState,
  requests: initialRequestsState,
  search: initialSearchState,
  select: initialSelectState,
  taxonomy: initialTaxonomyState,
  user: initialUserState,
}

export default (history: History) =>
  combineReducers({
    router: connectRouter(history),
    routerHistory: routerHistoryReducer,
    detail: detailReducer,
    detailPanel: DetailPanelState.default,
    droppedContent: droppedContentReducer,
    facet: facetReducer,
    filtersPanel: filtersReducer,
    permission: permissionReducer,
    preview: previewReducer,
    relations: relationsReducer,
    request: RequestState.default,
    requests: requestsReducer,
    search: searchReducer,
    select: selectReducer,
    taxonomy: taxonomyReducer,
    user: userReducer,
  })
