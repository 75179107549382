import ApiFlowManagerDetail from 'models/apiflowmanager/ApiFlowManagerDetail'
import { EApiFlowManagerPresentationType, EApiFlowManagerType } from 'models/apiflowmanager/Enumeration'
import { IContent, IRelationsGroup } from 'models/content/content'
import IShowHierarchy from 'models/detail/content/ShowHierarchy'
import { ContentActionTypes } from 'redux/actions/content'

import { IRelationsState, TRelationsReducerAction } from './relations.d'

export const initialRelationsState: IRelationsState = {
  showHierarchy: undefined,
  relationsGroups: [],
  contentAssociations: [],
}

const setRelationsLoading = (state: IRelationsState): IRelationsState => ({
  ...state,
  apiFlowManagerDetail: new ApiFlowManagerDetail(EApiFlowManagerType.LOADER, EApiFlowManagerPresentationType.GLOBAL),
})

const setShowHierarchySuccess = (state: IRelationsState, showHierarchy: IShowHierarchy): IRelationsState => ({
  ...state,
  showHierarchy,
})

const setRelatesToSuccess = (state: IRelationsState, relationsGroups: IRelationsGroup[]): IRelationsState => ({
  ...state,
  relationsGroups,
})

const setContentAssociationsSuccess = (state: IRelationsState, contentAssociations: IContent[]): IRelationsState => ({
  ...state,
  contentAssociations,
})

const setClearAssociationsSuccess = (state: IRelationsState): IRelationsState => ({
  ...state,
  contentAssociations: [],
})

const setRelationsFailure = (state: IRelationsState, apiFlow: ApiFlowManagerDetail): IRelationsState => ({
  ...state,
  apiFlowManagerDetail: apiFlow,
})

export const relationsReducer = (state: IRelationsState = initialRelationsState, action: TRelationsReducerAction) => {
  switch (action.type) {
    case ContentActionTypes.FETCH_CONTENT_SHOW_HIERARCHY:
    case ContentActionTypes.FETCH_CONTENT_RELATES_TO:
    case ContentActionTypes.FETCH_CONTENT_ASSOCIATIONS:
      return setRelationsLoading(state)
    case ContentActionTypes.FETCH_CONTENT_SHOW_HIERARCHY_SUCCESS:
      return setShowHierarchySuccess(state, action.payload)
    case ContentActionTypes.FETCH_CONTENT_RELATES_TO_SUCCESS:
      return setRelatesToSuccess(state, action.payload)
    case ContentActionTypes.FETCH_CONTENT_ASSOCIATIONS_SUCCESS:
      return setContentAssociationsSuccess(state, action.payload)
    case ContentActionTypes.CLEAR_ASSOCIATION:
      return setClearAssociationsSuccess(state)
    case ContentActionTypes.FETCH_CONTENT_SHOW_HIERARCHY_FAILURE:
    case ContentActionTypes.FETCH_CONTENT_RELATES_TO_FAILURE:
    case ContentActionTypes.FETCH_CONTENT_ASSOCIATIONS_FAILURE:
      return setRelationsFailure(state, action.payload)
    default:
      return state
  }
}
