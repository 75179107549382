import React from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { History } from 'history'

import { useDeepCompareMemo } from 'hooks/useDeepCompareMemo'
import { ECardType } from 'models/card/CardType'

export enum ERouteContext {
  SEARCH = 'recherche',
  HISTORY = 'historique',
}

export enum ERoutePanelDetail {
  CONTENT = 'contenu',
  CONTAINER = 'contenant',
}

export enum ERoutePanel {
  CREATION = 'creation',
  CONTENT_DUPLICATION = 'duplication-contenu',
  CONTAINER_DUPLICATION = 'duplication-contenant',
}
export interface IRouteParams {
  context?: ERouteContext
  panel?: ERoutePanelDetail | ERoutePanel
  detailId?: string
}
export interface IRouterContext {
  pushContext: (context: ERouteContext) => void
  pushDetail: (panel: ERoutePanelDetail, detailId: string) => void
  pushDuplication: (
    panel: ERoutePanel.CONTAINER_DUPLICATION | ERoutePanel.CONTENT_DUPLICATION,
    detailId: string
  ) => void
  pushPanel: (panel: ERoutePanel) => void
  clearPanel: () => void
  utils: {
    mapCardTypeToDetail: (cardType: ECardType) => ERoutePanelDetail
  }
  history: History
  current: IRouteParams
}

export const RouterContext = React.createContext<IRouterContext | null>(null)

export const RouterProvider = ({ children }: { children: React.ReactNode }) => {
  const params = useParams<IRouteParams>()
  const routerHistory = useHistory()

  const pushContext = React.useCallback(
    (context: ERouteContext) => {
      if (params.panel && params.detailId) {
        routerHistory.push(`/${context}/${params.panel}/${params.detailId}`)
      } else {
        routerHistory.push(`/${context}`)
      }
    },
    [params.panel, params.detailId]
  )

  const pushDetail = React.useCallback(
    (panel: ERoutePanelDetail, detailId: string) => {
      routerHistory.push(`/${params.context}/${panel}/${detailId}`)
    },
    [params.context]
  )

  const pushPanel = React.useCallback(
    (panel: ERoutePanel) => {
      routerHistory.push(`/${params.context}/${panel}`)
    },
    [params.context]
  )

  const pushDuplication = React.useCallback(
    (panel: ERoutePanel.CONTAINER_DUPLICATION | ERoutePanel.CONTENT_DUPLICATION, detailId: string) => {
      routerHistory.push(`/${params.context}/${panel}/${detailId}`)
    },
    [params.context]
  )

  const clearPanel = React.useCallback(() => {
    routerHistory.push(`/${params.context}`)
  }, [params.context])

  const mapCardTypeToDetail = React.useCallback((cardType: ECardType): ERoutePanelDetail => {
    switch (cardType) {
      case ECardType.CONTENT:
        return ERoutePanelDetail.CONTENT
      case ECardType.CONTAINER:
        return ERoutePanelDetail.CONTAINER
      default:
        return undefined
    }
  }, [])

  const current: IRouteParams = React.useMemo(
    () => ({
      context: params.context,
      panel: params.panel,
      detailId: params.detailId,
    }),
    [params.context, params.panel, params.detailId]
  )

  const memoizedContextValue = useDeepCompareMemo(
    () => ({
      pushContext,
      pushDetail,
      pushDuplication,
      pushPanel,
      clearPanel,
      utils: {
        mapCardTypeToDetail,
      },
      history: routerHistory,
      current,
    }),
    [pushContext, pushDetail, pushDuplication, pushPanel, clearPanel, mapCardTypeToDetail, routerHistory, current]
  )

  return <RouterContext.Provider value={memoizedContextValue}>{children}</RouterContext.Provider>
}
