import { EApiFlowManagerType, EApiFlowManagerPresentationType, EApiFlowManagerErrorType } from './Enumeration'

export default class ApiFlowManagerDetail {
  constructor(
    public type?: EApiFlowManagerType,
    public presentationType?: EApiFlowManagerPresentationType,
    public requestType?: EApiFlowManagerErrorType,
    public errorType?: number,
    public id?: string,
    public message?: string
  ) {}

  public addType(type: EApiFlowManagerType) {
    this.type = type
    return this
  }

  public addPresentationType(presentationType: EApiFlowManagerPresentationType) {
    this.presentationType = presentationType
    return this
  }

  public addRequestType(requestType: EApiFlowManagerErrorType) {
    this.requestType = requestType
    return this
  }

  public addErrorType(errorType: number) {
    this.errorType = errorType
    return this
  }
}
