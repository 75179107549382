import { ERecordType } from 'models/content/enumeration'
import { EFilterType } from 'models/filters/Enumeration'
import { TFilterItem } from 'modules/filters-panel/filters-panel'

import { ESearchServiceOrchestrationFilterType } from './search-service.def'

export const VIDEO_PARENT_SHOWS_FILTER: TFilterItem = {
  type: EFilterType.TYPE,
  name: ERecordType.VIDEO_PARENT_SHOW,
  isSelected: true,
}

export const AUDIO_SHOWS_FILTER: TFilterItem = {
  type: EFilterType.TYPE,
  name: ERecordType.AUDIO_SHOW,
  isSelected: true,
}

export const SEARCH_SERVICE_FILTER_TYPE_MAP = {
  [EFilterType.TYPE]: ESearchServiceOrchestrationFilterType.TYPE,
  [EFilterType.ORIGIN_SYSTEM]: ESearchServiceOrchestrationFilterType.ORIGIN_SYSTEM,
  [EFilterType.SOURCE]: ESearchServiceOrchestrationFilterType.SOURCE,
  [EFilterType.STATE]: ESearchServiceOrchestrationFilterType.STATE,
  [EFilterType.TAGS]: ESearchServiceOrchestrationFilterType.TAGS,
  [EFilterType.OWNER]: ESearchServiceOrchestrationFilterType.OWNER,
  [EFilterType.LINKED_CONTENT]: ESearchServiceOrchestrationFilterType.LINKED_CONTENT,
  [EFilterType.RECOMMANDABLE]: ESearchServiceOrchestrationFilterType.RECOMMANDABLE,
  [EFilterType.EMBARGO]: ESearchServiceOrchestrationFilterType.EMBARGO,
  [EFilterType.EXPIRED]: ESearchServiceOrchestrationFilterType.EXPIRED,
  [EFilterType.ID]: ESearchServiceOrchestrationFilterType.ID,
  [EFilterType.INTERESTS]: ESearchServiceOrchestrationFilterType.INTERESTS,
}
