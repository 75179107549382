import { fork, all } from 'redux-saga/effects'

import { rootContainerSaga } from 'redux/actions/container'
import { rootContentSaga } from 'redux/actions/content'
import { rootAllPermissionsSaga } from 'redux/actions/permission'
import { rootRouterSaga } from 'redux/actions/router'
import { rootSearchPfuSaga } from 'redux/actions/search-pfu'
import { rootSelectSaga } from 'redux/actions/select'
import { rootTaxonomySaga } from 'redux/actions/taxonomy'
import { rootUserSaga } from 'redux/actions/user'
import { watchSagaApiCallService } from 'services/apicallsagaservice/sagaApiCallContentsService'

export default function* rootSaga() {
  yield all([
    fork(rootContainerSaga),
    fork(rootContentSaga),
    fork(rootSearchPfuSaga),
    fork(rootTaxonomySaga),
    fork(rootUserSaga),
    fork(rootRouterSaga),
    fork(rootSelectSaga),
    fork(watchSagaApiCallService),
    fork(rootAllPermissionsSaga),
  ])
}
