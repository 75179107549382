import { ETabContext } from 'redux/reducers/filters/filters.reducer'
import { IStoreState } from 'types/common'

import { addMissingFilters, buildSearchServiceParams } from './search-service.service'

export const searchServiceParamsGetter = (state: IStoreState): URLSearchParams => {
  const activeTab = state.filtersPanel.tabs.find((tab) => tab.active)

  const { query, filters } = activeTab

  const selectedFilters = filters.filter((filter) => filter.isSelected)

  const enrichedSelectedFilters = addMissingFilters(selectedFilters)

  return buildSearchServiceParams(query, enrichedSelectedFilters)
}

export const searchServiceTenantGetter = (state: IStoreState): string | undefined => {
  const context = state?.filtersPanel.tabs.find((tab) => tab.active)?.context
  return ETabContext.ALL === context ? undefined : context?.toString()
}
