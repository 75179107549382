import { IPublicClientApplication } from '@azure/msal-browser'
import axios, { AxiosInstance } from 'axios'

import config from 'config'

import { registerInterceptors } from './CommonService'

interface INormalizedParams {
  [key: string]: string | string[] | number | boolean | undefined
}

export const normalizeParameters = (params: INormalizedParams): URLSearchParams => {
  const searchParams = new URLSearchParams()

  Object.keys(params).forEach((key: string) => {
    if (params[key] !== undefined) {
      if (Array.isArray(params[key])) {
        const keys = params[key] as string[]
        keys.forEach((filter: string) => {
          searchParams.append(key, filter)
        })
      } else {
        searchParams.append(key, params[key].toString())
      }
    }
  })

  return searchParams
}

export default class AxiosContentUIService {
  public static get Instance(): AxiosInstance {
    return this.instance.axiosInstance
  }

  public static initializeAxios(initValue: IPublicClientApplication | string): AxiosInstance {
    if (!this.instance) {
      if (typeof initValue === 'string') {
        this.instance = new AxiosContentUIService(null, initValue)
      } else {
        this.instance = new AxiosContentUIService(initValue)
      }
    }

    return this.instance.axiosInstance
  }

  private static instance: AxiosContentUIService
  private readonly axiosInstance: AxiosInstance
  private readonly isMock: boolean = config.contentUiService.url.includes('localhost')

  constructor(msalInstance: IPublicClientApplication, defaultBearer?: string) {
    this.axiosInstance = axios.create({
      baseURL: config.contentUiService.url,
      timeout: config.contentUiService.requestTimeout,
      headers: {
        'Content-Type': config.contentUiService.contentType,
      },
    })

    registerInterceptors(this.axiosInstance, msalInstance, defaultBearer)
  }
}
