import { LOCATION_CHANGE } from 'connected-react-router'
import { all, fork, put, PutEffect, take, TakeEffect } from 'redux-saga/effects'

import { RouterActionTypes } from './router.action.types'

function* watchRouterOriginAsync(): Generator<
  | TakeEffect
  | PutEffect<{
      payload: any
      type: RouterActionTypes
    }>
> {
  while (true) {
    const result: any = yield take(LOCATION_CHANGE)
    yield put({ payload: result.payload, type: RouterActionTypes.ROUTER_HISTORY_ORIGIN_PUSH })
  }
}

export function* rootRouterSaga() {
  yield all([fork(watchRouterOriginAsync)])
}
