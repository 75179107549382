import React from 'react'

import {
  IIntermediateFormikContextInterface,
  IntermediateFormikContext,
} from 'providers/IntermediateFormikContextProvider'

export default function useIntermediateFormik(): IIntermediateFormikContextInterface {
  const intermediateFormikContext = React.useContext(IntermediateFormikContext)

  if (!intermediateFormikContext) {
    throw Error('Missing "IntermediateFormik" context')
  }

  return intermediateFormikContext
}
