// TODO: PFU-18212 - clean up usage of "type"/"types" for container and content
export enum EFilterType {
  TYPE = 'type',
  ORIGIN_SYSTEM = 'originSystems',
  SOURCE = 'sources',
  STATE = 'states',
  TAGS = 'tags',
  GENRE = 'genres',
  INTERESTS = 'interests',
  OWNER = 'owner',
  LINKED_CONTENT = 'linked_content',
  RECOMMANDABLE = 'recommandable',
  EMBARGO = 'embargo',
  EXPIRED = 'expired',
  ID = 'id',
}

export enum EFilterItemType {
  BRANCH = 'branch',
  LEAF = 'leaf',
  DIVIDER = 'divider',
}

export enum EFilterTypeBranch {
  PAGE = 'page',
  CAROUSEL = 'carousel',
  FEED = 'feed',
  VIDEO = 'video',
  AUDIO = 'audio',
  BOOK = 'book',
}
