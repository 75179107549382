import { IPublicClientApplication } from '@azure/msal-browser'
import axios, { AxiosInstance } from 'axios'

import config from '../config'

import { registerInterceptors } from './CommonService'

export default class AxiosPermissionService {
  public static get Instance(): AxiosInstance {
    return this.instance.axiosInstance
  }

  public static initializeAxios(initValue: IPublicClientApplication | string): AxiosInstance {
    if (!this.instance) {
      if (typeof initValue === 'string') {
        this.instance = new AxiosPermissionService(null, initValue)
      } else {
        this.instance = new AxiosPermissionService(initValue)
      }
    }

    return this.instance.axiosInstance
  }

  private static instance: AxiosPermissionService
  private readonly axiosInstance: AxiosInstance

  constructor(msalInstance: IPublicClientApplication, defaultBearer?: string) {
    this.axiosInstance = axios.create({
      baseURL: config.permissionService.url,
      timeout: config.permissionService.requestTimeout,
      headers: {
        'Content-Type': config.permissionService.contentType,
      },
    })

    registerInterceptors(this.axiosInstance, msalInstance, defaultBearer)
  }
}
