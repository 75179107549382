export enum ContainerActionTypes {
  FETCH_CONTAINER = '@Container FETCH_CONTAINER',
  FETCH_CONTAINER_SUCCESS = '@Container FETCH_CONTAINER_SUCCESS',
  FETCH_CONTAINER_FAILURE = '@Container FETCH_CONTAINER_FAILURE',
  FETCH_CONTAINER_PREVIEW = '@Container FETCH_CONTAINER_PREVIEW',
  FETCH_CONTAINER_PREVIEW_SUCCESS = '@Container FETCH_CONTAINER_PREVIEW_SUCCESS',
  FETCH_CONTAINER_PREVIEW_FAILURE = '@Container FETCH_CONTAINER_PREVIEW_FAILURE',
  UPDATE_CONTAINER = '@Container UPDATE_CONTAINER',
  UPDATE_CONTAINER_SUCCESS = '@Container UPDATE_CONTAINER_SUCCESS',
  UPDATE_CONTAINER_FAILURE = '@Container UPDATE_CONTAINER_FAILURE',
  CREATE_CONTAINER = '@Container CREATE_CONTAINER',
  CREATE_CONTAINER_SUCCESS = '@Container CREATE_CONTAINER_SUCCESS',
  CREATE_CONTAINER_FAILURE = '@Container CREATE_CONTAINER_FAILURE',
  DELETE_CONTAINER = '@Container DELETE_CONTAINER',
  DELETE_CONTAINER_SUCCESS = '@Container DELETE_CONTAINER_SUCCESS',
  DELETE_CONTAINER_FAILURE = '@Container DELETE_CONTAINER_FAILURE',
}
