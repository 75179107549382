import { EContainerType, EContainerTypology } from 'models/container/enumeration'

import { IContainerContentCreateForm } from './ContainerPageCreateForm'

export default class ContainerVideoShowCreateDTO {
  objectType: 'ContainerCreate'
  type: EContainerType
  typology: EContainerTypology

  constructor(
    public name = '',
    public publicTitle = '',
    public alias = '',
    public aliasLink = '',
    public content: IContainerContentCreateForm = { type: '', title: '', imageUrl: null }
  ) {
    this.objectType = 'ContainerCreate'
    this.type = EContainerType.VIDEO_SHOW
    this.typology = EContainerTypology.VIDEO_SHOW
  }
}
