import { IContainer, IContainerWithContext } from 'models/container/container'
import { IContainerCreateParams } from 'models/detail/container/ContainerCreate'
import { TContainerUpdateParams } from 'models/detail/container/ContainerUpdate'
import { EContext } from 'models/detail/Enumeration'
import FetchDetailParams from 'models/detail/FetchDetailParams'
import AxiosContentUIService from 'services/AxiosContentUIService'

const withContext = (content: IContainer): IContainerWithContext => ({
  ...content,
  __context: EContext.CONTAINER,
})

export const fetchContainerApi = (id: string): Promise<IContainer> =>
  AxiosContentUIService.Instance.get(`/containers/${id}`).then((response) => withContext(response.data))

export const fetchContainerDetailApi = (params: FetchDetailParams): Promise<IContainer> =>
  AxiosContentUIService.Instance.get(`/containers/${params.id}`).then((response) => withContext(response.data))

export const updateContainerApi = (id: string, body: TContainerUpdateParams): Promise<IContainer> =>
  AxiosContentUIService.Instance.patch<IContainer>(`/containers/${id}`, body).then((response) =>
    withContext(response.data)
  )

export const createContainerApi = async (params: IContainerCreateParams): Promise<IContainer> =>
  AxiosContentUIService.Instance.post(`/containers`, params.data).then((response) => withContext(response.data))

export const deleteContainerApi = async (id: string): Promise<void> =>
  AxiosContentUIService.Instance.delete(`/containers/${id}`).then((response) => response.data)
