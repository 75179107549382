import { IPublicClientApplication } from '@azure/msal-browser'
import axios, { AxiosInstance } from 'axios'

import config from '../config'

import { registerInterceptors } from './CommonService'
export default class AxiosSearchService {
  constructor(msalInstance: IPublicClientApplication, defaultBearer?: string) {
    this.axiosInstance = axios.create({
      baseURL: config.searchService.url,
      timeout: config.searchService.requestTimeout,
      headers: {
        'Content-Type': config.searchService.contentType,
      },
    })

    registerInterceptors(this.axiosInstance, msalInstance, defaultBearer)
  }

  private static instance: AxiosSearchService
  private readonly axiosInstance: AxiosInstance

  public static get Instance(): AxiosInstance {
    return this.instance.axiosInstance
  }

  public static initializeAxios(initValue: IPublicClientApplication | string): AxiosInstance {
    if (!this.instance) {
      if (typeof initValue === 'string') {
        this.instance = new AxiosSearchService(null, initValue)
      } else {
        this.instance = new AxiosSearchService(initValue)
      }
    }

    return this.instance.axiosInstance
  }
}
