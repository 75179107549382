import * as React from 'react'

import IconAdd from '@mui/icons-material/Add'
import IconDelete from '@mui/icons-material/DeleteForever'
import IconEdit from '@mui/icons-material/Edit'
import IconArrowDown from '@mui/icons-material/KeyboardArrowDown'
import IconArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import IconArrowRight from '@mui/icons-material/KeyboardArrowRight'
import IconArrowUp from '@mui/icons-material/KeyboardArrowUp'
import IconDuplicate from '@mui/icons-material/Queue'
import Button from '@mui/material/Button'

import LoaderCUI from 'components/loader/Loader'

import burger from './assets/burger.svg'
import preview from './assets/preview.svg'
import publish from './assets/publish.svg'
import save from './assets/save.svg'
import shuffle from './assets/shuffle.svg'
import successImg from './assets/success.svg'
import { default as s } from './Buttons.scss'

export enum EVariant {
  TEXT = 'text',
  CONTAINED = 'contained',
  OUTLINED = 'outlined',
}

export enum EIcon {
  RIGHT = 'right arrow',
  LEFT = 'left arrow',
  UP = 'up arrow',
  DOWN = 'down arrow',
  SAVE = 'save',
  PUBLISH = 'publish',
  PREVIEW = 'preview',
  SUCCESS = 'success',
  ADD = 'add',
  BURGER = 'burger',
  DELETE = 'delete',
  EDIT = 'edit',
  SHUFFLE = 'shuffle',
  DUPLICATE = 'duplicate',
}

export interface IButtonsProps {
  /** Button type */
  type?: 'submit' | 'reset' | 'button'
  /** Buttons primary icon */
  primaryicon?: EIcon
  /** Primary alt text */
  primaryalt?: string
  /** Primary alt text */
  secondaryalt?: string
  /** Buttons secondary icon */
  secondaryicon?: EIcon
  /** Loading animation is active */
  loading?: boolean
  /** Button success state */
  success?: boolean
  /** Button disabled state */
  disabled?: boolean
  /** Button variant */
  variant?: EVariant
  /** Button onClick handler */
  onClick?: React.MouseEventHandler<HTMLElement>
  children: React.ReactElement | string
  dataQa?: string
  dataTestid?: string
}

const Buttons = ({
  variant,
  primaryicon,
  secondaryicon,
  disabled,
  onClick,
  loading,
  success,
  type,
  primaryalt,
  secondaryalt,
  children,
  dataQa,
  dataTestid,
}: IButtonsProps) => {
  const displayIcon = (icon?: EIcon, alt?: string) => {
    switch (icon) {
      case EIcon.RIGHT:
        return <IconArrowRight />
      case EIcon.LEFT:
        return <IconArrowLeft />
      case EIcon.UP:
        return <IconArrowUp />
      case EIcon.DOWN:
        return <IconArrowDown />
      case EIcon.ADD:
        return <IconAdd />
      case EIcon.DELETE:
        return <IconDelete />
      case EIcon.EDIT:
        return <IconEdit />
      case EIcon.DUPLICATE:
        return <IconDuplicate />
      case EIcon.SAVE:
        return <img src={save} alt={alt} />
      case EIcon.PUBLISH:
        return <img src={publish} alt={alt} />
      case EIcon.PREVIEW:
        return <img src={preview} alt={alt} />
      case EIcon.SUCCESS:
        return <img src={successImg} alt={alt} />
      case EIcon.BURGER:
        return <img src={burger} alt={alt} />
      case EIcon.SHUFFLE:
        return <img src={shuffle} alt={alt} />
      default:
        return undefined
    }
  }

  const renderButton = () => {
    const buttonClass = (btnVariant: EVariant) => {
      let classes: string = s.button
      if (primaryicon) {
        classes += ` ${s.withLeftIcon}`
      }
      if (secondaryicon) {
        classes += ` ${s.withRightIcon}`
      }
      if (success) {
        classes += ` ${s.success}`
      }

      switch (btnVariant) {
        case 'contained':
          classes += ` ${s.contained}`
          break
        case 'outlined':
          classes += ` ${s.outlined}`
          break
        default:
          classes += ` ${s.text}`
      }

      return classes
    }

    return (
      <div className={s.buttonContainer}>
        <Button
          variant={variant}
          className={buttonClass(variant)}
          data-qa={dataQa ? dataQa : 'button'}
          disabled={disabled || loading}
          onClick={onClick}
          role="button"
          data-testid={dataTestid ? dataTestid : 'button'}
          type={type}
        >
          {displayIcon(primaryicon, primaryalt)}
          <span className={s.buttonText}>{children}</span>
          {displayIcon(secondaryicon, secondaryalt)}
        </Button>
        {loading && (
          <div className={s.loader}>
            <LoaderCUI size={20} thickness={6} />
          </div>
        )}
      </div>
    )
  }
  return renderButton()
}

export default Buttons
