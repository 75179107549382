import { all, fork, put, takeLatest } from 'redux-saga/effects'

import { apiCallErrorHandling } from 'services/apicallsagaservice/apiCallErrorHandling'

import {
  fetchShowsListSuccess,
  fetchShowsListFailure,
  fetchAudioShowsListSuccess,
  fetchAudioShowsListFailure,
} from './select.action'
import { SelectActionTypes } from './select.action.types'
import { TFetchAudioShowsListAction, TFetchShowsListAction } from './select.d'

export function* fetchShowsListAsync({ payload }: TFetchShowsListAction) {
  try {
    yield put(fetchShowsListSuccess(payload))
  } catch (error) {
    yield put(fetchShowsListFailure(apiCallErrorHandling(error)))
  }
}

export function* fetchAudioShowsListAsync({ payload }: TFetchAudioShowsListAction) {
  try {
    yield put(fetchAudioShowsListSuccess(payload))
  } catch (error) {
    yield put(fetchAudioShowsListFailure(apiCallErrorHandling(error)))
  }
}

function* watchFetchShowListAsync() {
  yield takeLatest(SelectActionTypes.FETCH_VIDEO_SHOWS_LIST, fetchShowsListAsync)
}

function* watchFetchAudioShowListAsync() {
  yield takeLatest(SelectActionTypes.FETCH_AUDIO_SHOWS_LIST, fetchAudioShowsListAsync)
}

export function* rootSelectSaga() {
  yield all([fork(watchFetchShowListAsync)])
  yield all([fork(watchFetchAudioShowListAsync)])
}
