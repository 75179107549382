import { IRouteParams, ERouteContext, ERoutePanelDetail, ERoutePanel } from 'providers/RouterProvider'
import { RouterActionTypes } from 'redux/actions/router'

import { IRouterHistoryState, TRouterHistoryReducerAction } from './router.d'

export const initialRouterHistoryState: IRouterHistoryState = {
  historyList: [],
  history: [],
}

export function pathNameSplit(route: { location: Location }): IRouteParams {
  const [, context, panel, detailId] = route.location.pathname.split('/')

  return {
    context: context as ERouteContext,
    panel: panel as ERoutePanelDetail | ERoutePanel,
    detailId,
  }
}

const setRouterHistory = (state: IRouterHistoryState, payload: { location: Location }): IRouterHistoryState => ({
  ...state,
  history: [...state.history, pathNameSplit(payload)],
})

const setRouterHistoryList = (state: IRouterHistoryState, payload: IRouteParams): IRouterHistoryState => ({
  ...state,
  historyList: [...state.historyList, payload],
})

const updateRouterHistoryList = (state: IRouterHistoryState, payload: IRouteParams[]): IRouterHistoryState => {
  return {
    ...state,
    historyList: payload,
  }
}

const unSetRouterHistoryList = (state: IRouterHistoryState): IRouterHistoryState => ({
  ...state,
  historyList: [],
})

export const routerHistoryReducer = (
  state: IRouterHistoryState = initialRouterHistoryState,
  action?: TRouterHistoryReducerAction
) => {
  switch (action.type) {
    case RouterActionTypes.ROUTER_HISTORY_LIST_PUSH:
      return setRouterHistoryList(state, action.payload)
    case RouterActionTypes.ROUTER_HISTORY_LIST_UPDATE:
      return updateRouterHistoryList(state, action.payload)
    case RouterActionTypes.ROUTER_HISTORY_ORIGIN_PUSH:
      return setRouterHistory(state, action.payload)
    case RouterActionTypes.ROUTER_HISTORY_LIST_CLEAR:
      return unSetRouterHistoryList(state)
    default:
      return state
  }
}
