import ApiFlowManagerDetail from 'models/apiflowmanager/ApiFlowManagerDetail'
import { EApiFlowManagerType, EApiFlowManagerPresentationType } from 'models/apiflowmanager/Enumeration'
import { ITaxonomy } from 'models/taxonomy/Taxonomy'
import { ETaxonomyActionTypes } from 'redux/actions/taxonomy/taxonomy.action.types'

import { ITaxonomyState, ITaxonomyReducerAction } from './taxonomy.d'

export const initialTaxonomyState: ITaxonomyState = {
  taxonomies: [],
  apiFlowManagerDetail: undefined,
}

const setTaxonomyLoading = (state: ITaxonomyState): ITaxonomyState => ({
  ...state,
  apiFlowManagerDetail: new ApiFlowManagerDetail(EApiFlowManagerType.LOADER, EApiFlowManagerPresentationType.GLOBAL),
})

const setTaxonomiesSuccess = (state: ITaxonomyState, taxonomies: ITaxonomy[]): ITaxonomyState => ({
  ...state,
  taxonomies,
  apiFlowManagerDetail: undefined,
})

const setTaxonomiesFailure = (state: ITaxonomyState, apiFlow: ApiFlowManagerDetail): ITaxonomyState => ({
  ...state,
  apiFlowManagerDetail: apiFlow,
})

export const taxonomyReducer = (state: ITaxonomyState = initialTaxonomyState, action: ITaxonomyReducerAction) => {
  switch (action.type) {
    case ETaxonomyActionTypes.FETCH_TAXONOMIES:
      return setTaxonomyLoading(state)
    case ETaxonomyActionTypes.FETCH_TAXONOMIES_SUCCESS:
      return setTaxonomiesSuccess(state, action.payload)
    case ETaxonomyActionTypes.FETCH_TAXONOMIES_FAILURE:
      return setTaxonomiesFailure(state, action.payload)
    default:
      return state
  }
}
