export enum EApiFlowManagerErrorType {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
}

export enum EApiFlowManagerPresentationType {
  BANNER = 'BANNER',
  GLOBAL = 'GLOBAL',
  BUTTON = 'BUTTON',
}

export enum EApiFlowManagerType {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  LOADER = 'LOADER',
}
