export enum RequestsActionTypes {
  REQUESTS_INIT_SLOTS = '@Requests REQUESTS_INIT_SLOTS',
  REQUESTS_ADD_EMPTY_POSITION = '@Requests REQUESTS_ADD_EMPTY_POSITION',
  REQUESTS_EMPTY_POSITION_AT_INDEX = '@Requests REQUESTS_EMPTY_POSITION_AT_INDEX',
  REQUESTS_SAVE_SLOT_AT_INDEXES = '@Requests REQUESTS_SAVE_SLOT_AT_INDEXES',
  REQUESTS_INSERT_SLOT_BEFORE_INDEX = '@Requests REQUESTS_INSERT_SLOT_BEFORE_INDEX',
  REQUESTS_INSERT_SLOTS_BEFORE_INDEX = '@Requests REQUESTS_INSERT_SLOTS_BEFORE_INDEX',
  REQUESTS_UPDATE_SLOT_AT_INDEX = '@Requests REQUESTS_UPDATE_SLOT_AT_INDEX',
  REQUESTS_MOVE_SLOT_FROM_INDEX_TO = '@Requests REQUESTS_MOVE_SLOT_FROM_INDEX_TO',
  REQUESTS_REMOVE_SLOT_BY_NAME = '@Requests REQUESTS_REMOVE_SLOT_BY_NAME',
  REQUESTS_REMOVE_POSITION_BY_INDEX = '@Requests REQUESTS_REMOVE_POSITION_BY_INDEX',
  REQUESTS_SHUFFLE_POSITIONS = '@Requests REQUESTS_SHUFFLE_POSITIONS',
  REQUESTS_TOGGLE_SELECTED_CARD = '@Requests REQUESTS_TOGGLE_SELECTED_CARD',
  REQUESTS_CLEAR_SELECTED_CARD = '@Requests REQUESTS_CLEAR_SELECTED_CARD',
}
