import { composeWithDevTools } from '@redux-devtools/extension'
import { routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { createStore, applyMiddleware, Action } from 'redux'
import logger from 'redux-logger'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import createSagaMiddleware from 'redux-saga'

import { IStoreState } from 'types/common'

import createRootReducer from './reducers'
import rootSaga from './rootSaga'

const DEBUG_REDUX = false

export const history = createBrowserHistory()

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['routerHistory'],
}

export default (initialState: IStoreState) => {
  const sagaMiddleware = createSagaMiddleware()
  let middleware: any = [sagaMiddleware, routerMiddleware(history)]

  if (process.env.NODE_ENV === 'development' && DEBUG_REDUX) {
    middleware = [...middleware, logger]
  }

  const composeEnhancers = composeWithDevTools({
    trace: true,
  })

  const rootReducer = createRootReducer(history)
  const persistedReducer = persistReducer<any, any>(persistConfig, rootReducer)

  // TODO: unknown --> {}
  const store = createStore<any, Action, unknown, unknown>(
    persistedReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middleware))
  )

  const persistor = persistStore(store)

  sagaMiddleware.run(rootSaga)

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./reducers', () => {
      store.replaceReducer(createRootReducer(history))
    })
  }

  return { store, persistor }
}
