import { Action } from 'redux'

import { fetchContainerDetailApi } from 'api/Container'
import { fetchContentApi } from 'api/Content'
import config from 'config'
import ApiFlowManagerDetail from 'models/apiflowmanager/ApiFlowManagerDetail'
import { IContainer, TContainer, TBaseSlot } from 'models/container/container'
import { IContent, TContent } from 'models/content/content'
import FetchDetailParams from 'models/detail/FetchDetailParams'
import { TFetchContainerPreviewSuccessAction } from 'redux/actions/container/container'
import { IApiCallService } from 'services/apicallsagaservice/sagaApiCallServiceActions'

import { RequestActionTypes } from './action-types'

export declare function FOnRequestApi(params: FetchDetailParams): Promise<IContent | IContainer>
export declare function FFetchSlotDetail(params: FetchDetailParams): TFetchSlotDetail
export declare function FFetchSlotDetailSuccess(detail: IContent | IContainer): IFetchSlotDetailSuccess
export declare function FFetchSlotDetailFail(apiFlowManagerDetail: ApiFlowManagerDetail): IFetchSlotDetailFail
export declare function FSetCurrentSlot(slot: TBaseSlot | null, positionIndexes: number[]): ISetCurrentSlot
export declare function FSetCurrentSlotDispatcher(slot: TBaseSlot | null, positionIndexes: number[]): void
export declare function FClearCurrentSlot(): IClearCurrentSlot

export interface IFetchSlotPreviewDetailSuccess extends Action {
  type: RequestActionTypes.FETCH_SLOT_PREVIEW_DETAIL_SUCCESS
  detail: IContent | IContainer
}

export interface IFetchSlotDetailSuccess extends Action {
  type: RequestActionTypes.FETCH_SLOT_DETAIL_SUCCESS
  detail: TContent | TContainer
}

export interface IResetSlotDetail {
  type: RequestActionTypes.RESET_SLOT_CONTENT_DETAIL
}

export interface ICancelFetchSlotDetail {
  type: RequestActionTypes.FETCH_SLOT_DETAIL_CANCEL
}

export interface IFetchSlotDetailFail extends Action {
  type: RequestActionTypes.FETCH_SLOT_DETAIL_FAIL
  apiFlowManagerDetail: ApiFlowManagerDetail
}

export const fetchSlotDetailSuccess = (detail: TContent | TContainer): IFetchSlotDetailSuccess => ({
  type: RequestActionTypes.FETCH_SLOT_DETAIL_SUCCESS,
  detail,
})

export const fetchSlotPreviewDetailSuccess = (detail: IContent | IContainer): IFetchSlotPreviewDetailSuccess => ({
  type: RequestActionTypes.FETCH_SLOT_PREVIEW_DETAIL_SUCCESS,
  detail,
})

export const fetchSlotDetailFail = (apiFlowManagerDetail: ApiFlowManagerDetail): IFetchSlotDetailFail => ({
  type: RequestActionTypes.FETCH_SLOT_DETAIL_FAIL,
  apiFlowManagerDetail,
})

export const cancelFetchSlotDetail = (): ICancelFetchSlotDetail => ({
  type: RequestActionTypes.FETCH_SLOT_DETAIL_CANCEL,
})

export type TFetchSlotDetail = IApiCallService<
  RequestActionTypes.FETCH_SLOT_DETAIL,
  typeof FOnRequestApi,
  typeof FFetchSlotDetailSuccess,
  typeof FFetchSlotDetailFail,
  null,
  FetchDetailParams
>

export type TFetchSlotPreviewDetail = IApiCallService<
  RequestActionTypes.FETCH_SLOT_DETAIL,
  typeof FOnRequestApi,
  typeof fetchSlotPreviewDetailSuccess,
  typeof FFetchSlotDetailFail,
  null,
  FetchDetailParams
>

export type TFetchContentPreviewSlotDetail = IApiCallService<
  RequestActionTypes.FETCH_SLOT_DETAIL,
  typeof fetchContentApi,
  typeof fetchSlotPreviewDetailSuccess,
  typeof FFetchSlotDetailFail,
  null,
  string
>

export type TFetchContentSlotDetail = IApiCallService<
  RequestActionTypes.FETCH_SLOT_DETAIL,
  typeof fetchContentApi,
  typeof FFetchSlotDetailSuccess,
  typeof FFetchSlotDetailFail,
  null,
  string
>

export interface ISetCurrentSlot extends Action {
  type: RequestActionTypes.SET_CURRENT_SLOT
  slot: TBaseSlot | null
  positionIndexes: number[]
}

export interface IClearCurrentSlot extends Action {
  type: RequestActionTypes.CLEAR_CURRENT_SLOT
}

// Expose all available function of this action file, used in container to define DispatchToProps interface of the container
export type TRequestAvailableActions =
  | ISetCurrentSlot
  | IClearCurrentSlot
  | TFetchSlotDetail
  | IFetchSlotDetailFail
  | IFetchSlotDetailSuccess
  | IFetchSlotPreviewDetailSuccess
  | TFetchContainerPreviewSuccessAction

export const setCurrentSlot = (slot: TBaseSlot | null, positionIndexes: number[]): ISetCurrentSlot => ({
  type: RequestActionTypes.SET_CURRENT_SLOT,
  slot,
  positionIndexes,
})

export const clearCurrentSlot = (): IClearCurrentSlot => ({
  type: RequestActionTypes.CLEAR_CURRENT_SLOT,
})

export const fetchSlotContentDetail = (params: FetchDetailParams): TFetchContentSlotDetail => ({
  type: RequestActionTypes.FETCH_SLOT_DETAIL,
  onRequestApi: fetchContentApi,
  onSuccess: fetchSlotDetailSuccess,
  onFailure: fetchSlotDetailFail,
  cancelKey: RequestActionTypes.FETCH_SLOT_DETAIL_CANCEL,
  requestTimeout: config.contentUiService.requestTimeout,
  params: params.id,
})

export const fetchSlotContainerDetail = (params: FetchDetailParams): TFetchSlotDetail => ({
  type: RequestActionTypes.FETCH_SLOT_DETAIL,
  onRequestApi: fetchContainerDetailApi,
  onSuccess: fetchSlotDetailSuccess,
  onFailure: fetchSlotDetailFail,
  cancelKey: RequestActionTypes.FETCH_SLOT_DETAIL_CANCEL,
  requestTimeout: config.contentUiService.requestTimeout,
  params,
})

export const fetchSlotContentPreviewDetail = (params: FetchDetailParams): TFetchContentPreviewSlotDetail => ({
  type: RequestActionTypes.FETCH_SLOT_DETAIL,
  onRequestApi: fetchContentApi,
  onSuccess: fetchSlotPreviewDetailSuccess,
  onFailure: fetchSlotDetailFail,
  cancelKey: RequestActionTypes.FETCH_SLOT_DETAIL_CANCEL,
  requestTimeout: config.contentUiService.requestTimeout,
  params: params.id,
})

export const fetchSlotContainerPreviewDetail = (params: FetchDetailParams): TFetchSlotPreviewDetail => ({
  type: RequestActionTypes.FETCH_SLOT_DETAIL,
  onRequestApi: fetchContainerDetailApi,
  onSuccess: fetchSlotPreviewDetailSuccess,
  onFailure: fetchSlotDetailFail,
  cancelKey: RequestActionTypes.FETCH_SLOT_DETAIL_CANCEL,
  requestTimeout: config.contentUiService.requestTimeout,
  params,
})

export interface IRequestActions {
  setCurrentSlot: typeof FSetCurrentSlot
  clearCurrentSlot: typeof FClearCurrentSlot
  fetchSlotContentDetail: typeof fetchSlotContentDetail
  fetchSlotContainerDetail: typeof FFetchSlotDetail
  fetchSlotDetailSuccess: typeof FFetchSlotDetailSuccess
  fetchSlotContentPreviewDetail: typeof fetchSlotContentPreviewDetail
  fetchSlotContainerPreviewDetail: typeof fetchSlotContainerPreviewDetail
}

export const RequestActions: IRequestActions = {
  setCurrentSlot,
  clearCurrentSlot,
  fetchSlotContentDetail,
  fetchSlotContainerDetail,
  fetchSlotDetailSuccess,
  fetchSlotContentPreviewDetail,
  fetchSlotContainerPreviewDetail,
}
