import ApiFlowManagerDetail from 'models/apiflowmanager/ApiFlowManagerDetail'
import { ITaxonomy } from 'models/taxonomy/Taxonomy'

import { ETaxonomyActionTypes } from './taxonomy.action.types'
import { TFetchTaxonomiesAction, TFetchTaxonomiesSuccessAction, TFetchTaxonomiesFailureAction } from './taxonomy.d'

export const fetchTaxonomies = (): TFetchTaxonomiesAction => ({
  type: ETaxonomyActionTypes.FETCH_TAXONOMIES,
})

export const fetchTaxonomiesSuccess = (taxonomies: ITaxonomy[]): TFetchTaxonomiesSuccessAction => ({
  type: ETaxonomyActionTypes.FETCH_TAXONOMIES_SUCCESS,
  payload: taxonomies,
})

export const fetchTaxonomiesFailure = (apiFlow: ApiFlowManagerDetail): TFetchTaxonomiesFailureAction => ({
  type: ETaxonomyActionTypes.FETCH_TAXONOMIES_FAILURE,
  payload: apiFlow,
})
