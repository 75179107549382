import ApiFlowManagerDetail from 'models/apiflowmanager/ApiFlowManagerDetail'
import { EApiFlowManagerType, EApiFlowManagerPresentationType } from 'models/apiflowmanager/Enumeration'
import { IContainer } from 'models/container/container'
import { TContent } from 'models/content/content'
import { ContainerActionTypes } from 'redux/actions/container'
import { ContentActionTypes } from 'redux/actions/content'

import { IPreviewState, TPreviewReducerAction, IContentPreviewState, IContainerPreviewState } from './preview.d'

export const initialPreviewState: IPreviewState = {
  container: undefined,
  content: undefined,
  apiFlowManagerDetail: undefined,
}

const setPreviewLoading = (state: IPreviewState): IPreviewState => ({
  ...state,
  apiFlowManagerDetail: new ApiFlowManagerDetail(EApiFlowManagerType.LOADER, EApiFlowManagerPresentationType.GLOBAL),
})

const setContainerSuccess = (state: IPreviewState, container: IContainer): IContainerPreviewState => ({
  ...state,
  container,
  content: undefined,
  apiFlowManagerDetail: undefined,
})

const setContentSuccess = (state: IPreviewState, content: TContent): IContentPreviewState => ({
  ...state,
  container: undefined,
  content,
  apiFlowManagerDetail: undefined,
})

const setPreviewFailure = (state: IPreviewState, apiFlowManagerDetail: ApiFlowManagerDetail): IPreviewState => ({
  ...state,
  apiFlowManagerDetail,
})

const setClearPreviewSuccess = (state: IPreviewState): IPreviewState => ({
  ...state,
  container: undefined,
  content: undefined,
})

export const previewReducer = (state: IPreviewState = initialPreviewState, action: TPreviewReducerAction) => {
  switch (action.type) {
    case ContainerActionTypes.FETCH_CONTAINER_PREVIEW:
    case ContentActionTypes.FETCH_CONTENT_PREVIEW:
      return setPreviewLoading(state)
    case ContainerActionTypes.FETCH_CONTAINER_PREVIEW_SUCCESS:
      return setContainerSuccess(state, action.payload)
    case ContentActionTypes.FETCH_CONTENT_PREVIEW_SUCCESS:
      return setContentSuccess(state, action.payload)
    case ContainerActionTypes.FETCH_CONTAINER_PREVIEW_FAILURE:
    case ContentActionTypes.FETCH_CONTENT_PREVIEW_FAILURE:
      return setPreviewFailure(state, action.payload)
    case ContentActionTypes.CLEAR_CONTENT_PREVIEW:
      return setClearPreviewSuccess(state)
    default:
      return state
  }
}
