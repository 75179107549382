import uniqBy from 'lodash/uniqBy'

import ApiFlowManagerDetail from 'models/apiflowmanager/ApiFlowManagerDetail'
import { EApiFlowManagerType, EApiFlowManagerPresentationType } from 'models/apiflowmanager/Enumeration'
import { IContentVideoPreview } from 'models/content/content'
import { ContentActionTypes } from 'redux/actions/content'

import { TDroppedContentReducerAction, IDroppedContentState } from './dropped-content.d'

export const initialDroppedContentState: IDroppedContentState = {
  videoPreviewList: [],
  apiFlowManagerDetail: new ApiFlowManagerDetail(EApiFlowManagerType.LOADER, EApiFlowManagerPresentationType.GLOBAL),
}

const setFetchDroppedContentPreviewSuccess = (
  state: IDroppedContentState,
  payload: IContentVideoPreview
): IDroppedContentState => {
  const videoPreviewListWithoutId = state.videoPreviewList.filter((preview) => preview.id !== payload.id)
  return {
    ...state,
    videoPreviewList: uniqBy([...videoPreviewListWithoutId, payload], 'id'),
    apiFlowManagerDetail: undefined,
  }
}

const setFetchDroppedContentPreviewFailure = (
  state: IDroppedContentState,
  payload: ApiFlowManagerDetail
): IDroppedContentState => ({
  ...state,
  apiFlowManagerDetail: payload,
})

export const droppedContentReducer = (
  state: IDroppedContentState = initialDroppedContentState,
  action: TDroppedContentReducerAction
): IDroppedContentState => {
  switch (action.type) {
    case ContentActionTypes.FETCH_DROPPED_CONTENT_PREVIEW_SUCCESS:
      return setFetchDroppedContentPreviewSuccess(state, action.payload)
    case ContentActionTypes.FETCH_DROPPED_CONTENT_PREVIEW_FAILURE:
      return setFetchDroppedContentPreviewFailure(state, action.payload)
    default:
      return state
  }
}
