import * as React from 'react'
import { useSelector } from 'react-redux'
import { Route, Switch, Redirect, BrowserRouter as ReactRouterProvider } from 'react-router-dom'

import { CssBaseline } from '@mui/material'
import { ConnectedRouter } from 'connected-react-router'

import LoaderLinear from 'components/loaderlinear/LoaderLinear'
import ApiFlowManagerDetail from 'models/apiflowmanager/ApiFlowManagerDetail'
import { EApiFlowManagerType } from 'models/apiflowmanager/Enumeration'
import AuthPage from 'pages/auth'
import ReleaseNotesPage from 'pages/release-notes'
import { DragAndDropProvider } from 'providers/DragAndDropContextProvider'
import { IntermediateFormikProvider } from 'providers/IntermediateFormikContextProvider'
import { RouterProvider } from 'providers/RouterProvider'
import { history } from 'redux/store'
import AppLayout from 'templates/app/AppLayout'
import { IStoreState } from 'types/common'

import { routes } from './routes'

const ContentPage = React.lazy(() => import('templates/contentlayout/ContentsPage'))

const PrivateRoute = ({ component: Component, ...rest }: any) => {
  const auth = useSelector<IStoreState>((state: IStoreState) => state.user.apiFlowManagerDetail) as ApiFlowManagerDetail
  if (auth && auth.type === EApiFlowManagerType.ERROR) {
    return <Redirect to="/auth" />
  }

  return <Route {...rest} render={(props) => <Component {...props} />} />
}

const App = () => {
  return (
    <ReactRouterProvider>
      <ConnectedRouter history={history}>
        <IntermediateFormikProvider>
          <CssBaseline />
          <React.Suspense fallback={<LoaderLinear />}>
            <Switch>
              <PrivateRoute path={routes} exact>
                <RouterProvider>
                  <DragAndDropProvider>
                    <AppLayout>
                      <ContentPage />
                    </AppLayout>
                  </DragAndDropProvider>
                </RouterProvider>
              </PrivateRoute>
              <PrivateRoute path="/notes-de-version" exact>
                <ReleaseNotesPage />
              </PrivateRoute>
              <Route path="/auth" exact>
                <AuthPage />
              </Route>
              <Route>
                <Redirect to="/recherche" />
              </Route>
            </Switch>
          </React.Suspense>
        </IntermediateFormikProvider>
      </ConnectedRouter>
    </ReactRouterProvider>
  )
}

export default App
