import { EContainerType, EContainerTypology } from 'models/container/enumeration'
import { EContentOwner } from 'models/content/enumeration'
export default class ContainerTemplateCreateDTO {
  objectType: 'TemplateContainerCreate'
  type: EContainerType.TEMPLATE
  typology: EContainerTypology.TEMPLATE

  constructor(
    public name = '',
    public publicTitle = '',
    public alias = '',
    public displayPriority = 1,
    public templateRule = {
      contentTypes: [] as string[],
      contentSources: [] as string[],
      contentCategories: [] as string[],
      contentTags: [] as string[],
      contentGenres: [] as string[],
    },
    public owner?: EContentOwner
  ) {
    this.objectType = 'TemplateContainerCreate'
    this.type = EContainerType.TEMPLATE
    this.typology = EContainerTypology.TEMPLATE
  }
}
