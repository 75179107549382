import { TPfuFilterItem } from 'modules/filters-panel/filters-panel'
import { ETabContext } from 'redux/reducers/filters/filters.reducer'

import { ISearchPayloadData } from '../search-pfu/search-pfu'

import { TFiltersAction } from './filters'
import { FiltersActionTypes } from './filters.action.types'

export const setQuery = (query: string): TFiltersAction => ({
  type: FiltersActionTypes.SET_QUERY,
  payload: query,
})

export const selectPfuFilter = (filterItem: TPfuFilterItem): TFiltersAction => ({
  type: FiltersActionTypes.SELECT_PFU_FILTER,
  payload: filterItem,
})

export const unselectPfuFilter = (filterItem: TPfuFilterItem): TFiltersAction => ({
  type: FiltersActionTypes.UNSELECT_PFU_FILTER,
  payload: filterItem,
})

export const selectMultiPfuFilters = (filterItems: TPfuFilterItem[]): TFiltersAction => ({
  type: FiltersActionTypes.SELECT_MULTI_PFU_FILTERS,
  payload: filterItems,
})

export const unselectMultiPfuFilters = (filterItems: TPfuFilterItem[]): TFiltersAction => ({
  type: FiltersActionTypes.UNSELECT_MULTI_PFU_FILTERS,
  payload: filterItems,
})

export const clearSelectedPfuFilters = (): TFiltersAction => ({
  type: FiltersActionTypes.CLEAR_SELECTED_PFU_FILTERS,
})

export const addPfuTagFilter = (name: string): TFiltersAction => ({
  type: FiltersActionTypes.ADD_PFU_TAG_FILTER,
  payload: name,
})

export const removePfuTagFilter = (name: string): TFiltersAction => ({
  type: FiltersActionTypes.REMOVE_PFU_TAG_FILTER,
  payload: name,
})

export const clearPfuTagFilters = (): TFiltersAction => ({
  type: FiltersActionTypes.CLEAR_PFU_TAG_FILTERS,
})

export const addPfuInterestFilter = (data: ISearchPayloadData): TFiltersAction => ({
  type: FiltersActionTypes.ADD_PFU_INTEREST_FILTER,
  payload: { name: data.name, label: data.label },
})

export const removePfuInterestFilter = (data: ISearchPayloadData): TFiltersAction => ({
  type: FiltersActionTypes.REMOVE_PFU_INTEREST_FILTER,
  payload: { name: data.name, label: data.label },
})

export const clearPfuInterestFilters = (): TFiltersAction => ({
  type: FiltersActionTypes.CLEAR_PFU_INTEREST_FILTERS,
})

export const clearQueryAndPfuFilters = (): TFiltersAction => ({
  type: FiltersActionTypes.CLEAR_QUERY_AND_PFU_FILTERS,
})

export const toggleExpanded = (): TFiltersAction => ({
  type: FiltersActionTypes.TOGGLE_EXPANDED,
})

export const setExpanded = (isExpanded: boolean): TFiltersAction => ({
  type: FiltersActionTypes.SET_EXPANDED,
  payload: isExpanded,
})

export const addNewTab = (): TFiltersAction => ({
  type: FiltersActionTypes.ADD_NEW_TAB,
})

export const addNewTabWithContext = (context: ETabContext): TFiltersAction => ({
  type: FiltersActionTypes.ADD_NEW_TAB_WITH_CONTEXT,
  payload: context,
})

export const removeTab = (index: number): TFiltersAction => ({
  type: FiltersActionTypes.REMOVE_TAB,
  payload: index,
})

export const switchTab = (index: number): TFiltersAction => ({
  type: FiltersActionTypes.SWITCH_TAB,
  payload: index,
})
