import { ISlot, TBaseSlot } from 'models/container/container'

import { TRequestsAction } from './requests'
import { RequestsActionTypes } from './requests.action.types'

export const initSlots = (slots: ISlot[]): TRequestsAction => ({
  type: RequestsActionTypes.REQUESTS_INIT_SLOTS,
  payload: slots,
})

export const addEmptyPosition = (): TRequestsAction => ({
  type: RequestsActionTypes.REQUESTS_ADD_EMPTY_POSITION,
})

export const emptyPositionAtIndex = (index: number): TRequestsAction => ({
  type: RequestsActionTypes.REQUESTS_EMPTY_POSITION_AT_INDEX,
  payload: index,
})

export const saveSlotAtIndexes = (
  oldSlot: TBaseSlot | null,
  newSlot: TBaseSlot,
  indexes: number[]
): TRequestsAction => ({
  type: RequestsActionTypes.REQUESTS_SAVE_SLOT_AT_INDEXES,
  payload: { oldSlot, newSlot, indexes },
})

export const insertSlotBeforeIndex = (newSlot: TBaseSlot, index: number): TRequestsAction => ({
  type: RequestsActionTypes.REQUESTS_INSERT_SLOT_BEFORE_INDEX,
  payload: { newSlot, index },
})

export const insertSlotsBeforeIndex = (newSlots: TBaseSlot[], index: number): TRequestsAction => ({
  type: RequestsActionTypes.REQUESTS_INSERT_SLOTS_BEFORE_INDEX,
  payload: { newSlots, index },
})

export const updateSlotAtIndex = (newSlot: TBaseSlot, index: number): TRequestsAction => ({
  type: RequestsActionTypes.REQUESTS_UPDATE_SLOT_AT_INDEX,
  payload: { newSlot, index },
})

export const moveSlotFromIndexTo = (fromIndex: number, toIndex: number): TRequestsAction => ({
  type: RequestsActionTypes.REQUESTS_MOVE_SLOT_FROM_INDEX_TO,
  payload: { fromIndex, toIndex },
})

export const removeSlotByName = (name: TBaseSlot['name']): TRequestsAction => ({
  type: RequestsActionTypes.REQUESTS_REMOVE_SLOT_BY_NAME,
  payload: name,
})

export const removePositionByIndex = (index: number): TRequestsAction => ({
  type: RequestsActionTypes.REQUESTS_REMOVE_POSITION_BY_INDEX,
  payload: index,
})

export const shufflePositions = (): TRequestsAction => ({
  type: RequestsActionTypes.REQUESTS_SHUFFLE_POSITIONS,
})

export const toggleSelectedCard = (index: number): TRequestsAction => ({
  type: RequestsActionTypes.REQUESTS_TOGGLE_SELECTED_CARD,
  payload: index,
})

export const clearSelectedCards = (): TRequestsAction => ({
  type: RequestsActionTypes.REQUESTS_CLEAR_SELECTED_CARD,
})
